import {StyleSheet} from 'react-native';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';

export const subscribeVariantStyles = StyleSheet.create({
  active: {
    backgroundColor: defaultTheme.option.valideBackground,
    borderWidth: 1,
    borderColor: defaultTheme.subscribe.primary,
  },
  default: {
    backgroundColor: defaultTheme.primaryNavBar,
    borderWidth: 1,
    borderColor: defaultTheme.primaryNavBar,
  },
});

export const subscribeStyles = StyleSheet.create({
  container: {
    paddingVertical: Metrics.verticalScale(16),
    paddingRight: Metrics.horizontalScale(12),
    paddingLeft: Metrics.horizontalScale(16),
    borderRadius: Metrics.moderateScale(4),
  },
  trailing: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  price: {
    color: defaultTheme.primaryTitle,
    fontSize: Metrics.fontScale(18),
  },
  duration: {
    color: defaultTheme.primaryTitle,
    fontSize: Metrics.fontScale(12),
  },
  tag: {
    paddingHorizontal: Metrics.horizontalScale(8),
    paddingVertical: Metrics.verticalScale(2),
    borderRadius: Metrics.moderateScale(16),
    marginTop: Metrics.verticalScale(7),
  },
  tagText: {
    fontSize: Metrics.fontScale(12),
    color: defaultTheme.primaryTitle,
  },
  iconCheck: {
    position: 'absolute',
    left: Metrics.horizontalScale(4),
    top: Metrics.verticalScale(4),
  },
});
