import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {useAppDispatch} from '@src/Store/hooks';
import Button from 'design-system/src/Components/Button/Button';
import TextInputController from 'design-system/src/Components/Form/TextInputController/TextInputController';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {FC, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {toast} from 'react-toastify';
import {Pressable} from 'react-native';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useResetPwdAcademia} from './useResetPwdAcademia';

interface IForgotPasswordForm {
  email: string;
}

interface IForgotPasswordProps {
  isFromActivateAccount?: boolean;
}

const ForgotPassword: FC<IForgotPasswordProps> = ({isFromActivateAccount}) => {
  const {t} = useTranslation('auth');
  const {isMobile} = useBreakpoint();
  const {control, handleSubmit} = useForm<IForgotPasswordForm>();
  const {mention, title} = useResetPwdAcademia();
  const [loading, setLoading] = useState(false);
  const {traceForgotPassword: forgotPassword} = useFireBaseAuthentication();
  const dispatch = useAppDispatch();

  const handleClick = async (data: IForgotPasswordForm) => {
    setLoading(true);
    const response = await forgotPassword(data.email);

    if (response.status === 'error') {
      toast.error(response.errorMessage);
      setLoading(false);
      return;
    } else if (response.status === 'success') {
      toast.success(t('Un email vous a été envoyé'));
      dispatch(appAuthSlice.actions.setAuthType('login'));
    }
    setLoading(false);
  };

  const handleError = (error: any) => {
    console.log(error);
  };

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 418,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        padding: isMobile ? '0 16px' : undefined,
        position: 'relative',
      }}>
      <Typography
        variant={isMobile ? 'body2' : 'formTitle'}
        style={{
          textTransform: 'uppercase',
          // fontSize: 48,
          marginBottom: 25,
          textAlign: 'center',
        }}>
        {isFromActivateAccount ? title : t('Forgot password')}
      </Typography>

      {isFromActivateAccount && (
        <Typography
          style={{
            textAlign: 'center',
          }}
          variant="body3">
          {mention}
        </Typography>
      )}
      <TextInputController
        control={control}
        name="email"
        label={t('Email') + ''}
        rules={{
          required: t('Email is required') + '',
        }}
        placeholder={t('your@email.com') || ''}
        // placeholderTextColor={'#fff'}
        style={{
          marginBottom: Metrics.verticalScale(30),
          justifyContent: 'center',
        }}
        variant="transparent"
      />
      <Button
        variant="contained"
        size="small"
        title={isFromActivateAccount ? t('send') : t('reset password')}
        fullWidth
        loading={loading}
        onPress={handleSubmit(handleClick, handleError)}
      />
      <div style={{marginTop: 14}}>
        <Pressable
          onPress={() => dispatch(appAuthSlice.actions.setAuthType('login'))}>
          <Typography variant="body3">{t('Se connecter')}</Typography>
        </Pressable>
      </div>
    </div>
  );
};

export default ForgotPassword;
