import {IFaqItemFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {FC} from 'react';
import {View} from 'react-native';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Button from 'design-system/src/Components/Button/Button';
import {AppRoutesEnum} from '@src/Constant/routes';
import useAppNavigation from '@src/utils/useAppNavigation';

const ProfileHelpdeskAnswer: FC<IFaqItemFragmentFragment> = ({
  title,
  htmlContent,
  showForm,
}) => {
  const tracker = useTracker();
  const {t} = useTranslation('helpdesk');
  const {navigate} = useAppNavigation();

  const handleShowForm = () => {
    tracker.general.cTAClicked({
      type: 'subscription',
      name: t('Contact'),
      section: 'profile',
      page: 'helpdesk',
      blockType: 'button',
      blockName: 'contact',
    });
    navigate(AppRoutesEnum.PROFILE_ZENDESK_FORM, {
      state: {
        params: {
          title: encodeURIComponent(title ?? ''),
        },
      },
    });
  };

  return (
    <View
      style={{
        flex: 1,
      }}>
      <Typography
        variant="h3"
        style={{
          marginTop: Metrics.verticalScale(36),
          marginBottom: Metrics.horizontalScale(37),
        }}>
        {title}
      </Typography>
      {htmlContent && (
        <div
          style={{
            color: '#737373',
            fontFamily: 'SuisseIntl-Regular',
            fontWeight: 400,
            fontSize: 16,
          }}
          dangerouslySetInnerHTML={{__html: htmlContent}}></div>
      )}
      {showForm && (
        <View
          style={{
            marginTop: Metrics.verticalScale(48),
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Button
            onPress={handleShowForm}
            variant="outlined"
            size={'small'}
            title={t('Contact')}
          />
        </View>
      )}
    </View>
  );
};

export default ProfileHelpdeskAnswer;
