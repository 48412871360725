// import useMember from '~Store/selector/useMember';
import {useEffect, useRef} from 'react';
import {setUserId} from '@amplitude/analytics-browser';
import useMember from '@src/Store/selectors/useMember';
import useBraze, {
  generateAnonymousUserId,
} from '@src/Providers/BrazeProvider/useBraze';

const useInitAmplitudeData = () => {
  const member = useMember();
  const isFetching = useRef(false);
  const anonymousUserId = useRef(generateAnonymousUserId());

  const {openBrazeSession} = useBraze();

  useEffect(() => {
    if (member && member._id) {
      console.log('member', member._id);
      if (!isFetching.current) {
        isFetching.current = true;
        if ('Notification' in window) {
          Notification.requestPermission(status => {
            console.log('Notification permission status:', status);
          });
        }
        setUserId(member._id);
        openBrazeSession(member._id).finally(() => {
          isFetching.current = false;
        });
      }
    } else {
      // openBrazeSession(anonymousUserId.current);
    }
  }, [member]);
};

export default useInitAmplitudeData;
