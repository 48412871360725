import React, {FC} from 'react';
import {Pressable} from 'react-native';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Typography from '../../../../Components/Typography/Typography.web';
import Tags from '../../../../Components/Tags/Tags.web';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {IVideoSource} from '../VideoPlayer';
import Icon from '../../../../Components/Icon/Icon';

interface PlayerSliderProps {
  onResetPlayer: () => void;
  onNext?: () => void;
  played: number;
  playedSeconds: number;
  onSliderChange: (value: number | number[]) => void;
  duration: number;
  loaded: number;
  isLive?: boolean;
  isVerticale?: boolean;
  isWithNext: boolean;
  sourceList?: Array<IVideoSource>;
  toggleFullscreen?: () => void;
}

const PlayerSlider: FC<PlayerSliderProps> = ({
  onResetPlayer,
  onNext,
  played,
  playedSeconds,
  onSliderChange,
  duration,
  loaded,
  isLive,
  isVerticale,
  sourceList,
  isWithNext,
  toggleFullscreen,
}) => {
  const formatTime = (seconds: number): string => {
    const date = new Date(0);
    date.setSeconds(seconds);
    const timeString = date.toISOString().substr(11, 8);
    return timeString.startsWith('00:') ? timeString.substr(3) : timeString;
  };
  const {t} = useTranslation(['videoPlayer']);
  return (
    <div
      style={{
        marginBottom: 32,
        width: '100%',
      }}>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}>
        {!isLive && !isVerticale && (
          <>
            <Pressable
              onPress={onResetPlayer}
              style={{
                position: 'absolute',
                left: 0,
                top: '-30px',
              }}>
              <Typography variant="button3">{t('Play from beginning')}</Typography>
            </Pressable>
            {onNext && isWithNext && sourceList && sourceList.length > 1 && (
              <Pressable
                onPress={() => {
                  onNext();
                }}
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '-30px',
                }}>
                <Typography variant="button3">{t('Next Episode')}</Typography>
              </Pressable>
            )}
          </>
        )}
        <div
          style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={loaded}
            styles={{
              rail: {
                height: 4,
                backgroundColor: '#FFFFFF',
                opacity: 0.2,
              },
              track: {
                backgroundColor: '#FFFFFF',
                height: 4,
                opacity: 0.6,
              },
              handle: {
                display: 'none',
              },
            }}
            style={{position: 'absolute', zIndex: 1}}
          />
          <Slider
            min={0}
            max={1}
            step={0.01}
            value={played}
            onChange={onSliderChange}
            onChangeComplete={onSliderChange}
            style={{position: 'absolute', zIndex: 2}}
            styles={{
              rail: {
                height: 4,
                backgroundColor: 'transparent',
              },
              track: {
                backgroundColor: '#EA6C13',
                height: 4,
              },
              handle: {
                borderColor: 'transparent',
                height: 16,
                width: 16,
                marginLeft: -2,
                marginTop: -6,
                backgroundColor: '#EA6C13',
                display: isLive || isVerticale ? 'none' : undefined,
              },
            }}
          />
        </div>

        <div
          style={{
            marginLeft: 12,
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}>
          {isLive ? (
            <Tags text={'Live'} variant="live" />
          ) : (
            !isVerticale && (
              <Typography variant="button3">
                {formatTime(duration - playedSeconds)}
              </Typography>
            )
          )}
          {toggleFullscreen && (
            <Pressable onPress={toggleFullscreen}>
              <Icon name={'fullScreen'} size="24" color="white" />
            </Pressable>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerSlider;
