import ActionGroup, {
  ActionGroupItem,
} from 'design-system/src/Components/ActionGroup/ActionGroup';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {StyleSheet, View} from 'react-native';
import {ItemOverviewProps} from 'design-system/src/Components/ItemOverview/ItemOverview';
import {LearnHomeCard} from 'design-system/src/Components/Card';
import {
  ICourseFragmentFragment,
  ICourseUnitFragmentFragment,
  IJobTrainingFragmentFragment,
  IPartCourseFragmentFragment,
  useJobTrainingByIdLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import {ILearnHomeCardProps} from 'design-system/src/Components/Card/LearnCard/LearnHomeCard/LearnHomeCard';
import {HomeContentLoader} from 'design-system/src/Components/ContentLoader';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {RouteTypename} from '~Navigation/type';
import useUpdateBookmarks from '~Hooks/useUpdateBookmarks';
import FavorisIcon from 'design-system/src/Components/CheckBox/FavoirsIcon/FavorisIcon';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {formatDuration} from '~Hooks/useStringExtension';
import AutoScaleImage from 'design-system/src/Components/AutoScaleImage/AutoScaleImage';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import {useGetJobTrainingOverview} from '../../Hooks/useGetJobTrainingOverview';
import {useMapCourseCateg} from '~Umbraco/Utils/content/mapCourseCategItems';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import useParams from '@src/Hooks/useParams';
import {useAppSelector} from '@src/Store/hooks';
import {
  useSelectCoursesStatus,
  useSelectObtainedJob,
} from '@src/Store/selectors/useMember';
import {AppRoutesEnum} from '@src/Constant/routes';
import {useHandleUpdateLearnContent} from '../../Hooks/useHandleUpdateLearnContent';
import useAppNavigation from '@src/utils/useAppNavigation';
import LearnOverview from '../../Components/LearnOverview/LearnOverview';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import AppGrid from '@src/App/components/Common/AppGrid/AppGrid';
import useSingleJobFetch from './useSingleJobFetch';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

export default function SingleJob() {
  const params = useParams();

  const navigation = useAppNavigation();

  const {endUrl} = params || {};
  const {t} = useTranslation('learn');
  const {isMobile} = useBreakpoint();
  const {loading, jobTraining: jobTrainingData} = useSingleJobFetch(
    endUrl || '',
  );

  const {
    loading: overviewLoading,
    overview,
    courses: jobCourses,
  } = useGetJobTrainingOverview(endUrl || '');

  useEffect(() => {
    navigation.setOptions(undefined);
  }, [navigation]);

  const {groupedBookmarks} = useAppSelector(({member: memberState}) => ({
    groupedBookmarks: memberState.bookmarks,
  }));

  const {isBookmarked, updateBookMark} = useUpdateBookmarks({
    screenSource: 'detail',
  });
  const obtainedJob = useSelectObtainedJob();
  const coursesStatus = useSelectCoursesStatus();
  const openTrailer = (item: IJobTrainingFragmentFragment) => {
    if (item.trailer) {
      navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
        state: {
          params: {
            liveEventId: item.trailer,
            videoUrl:
              typeof item.trailer === 'string' ? item.trailer : undefined,
            title: item.name || '',
          },
        },
      });
    }
  };

  const handleClickCourse = useCallback(
    (item: {id: string; url: string; __typename: RouteTypename}) => {
      const {id: itemId, url, __typename} = item;
      navigation.navigate(AppRoutesEnum.COURSE_SINGLE, {
        state: {
          params: {
            id: itemId,
            url: url || '',
            type: 'url',
            __typename,
          },
        },
      });
    },
    [],
  );

  const {countProgression} = useMapCourseCateg();

  const {
    pageContent,
  }: {
    pageContent: ReactNode | undefined;
    jobTraining: IJobTrainingFragmentFragment | undefined;
  } = useMemo(() => {
    let jobTraining: IJobTrainingFragmentFragment;

    if (jobTrainingData) {
      const blocks: ReactNode[] = [];
      jobTraining = jobTrainingData as IJobTrainingFragmentFragment;
      const {title, description, courses, trailer, cover, partners} =
        jobTraining;
      let jobPartners: ReactNode[] = [];
      let property: ILearnPropertyName = {
        partnerName:
          partners && partners[0] && partners[0].__typename === 'Partner'
            ? partners[0].partnerName
            : '',
        certificateName: title,
        certificateId: jobTrainingData.id,
      };
      if (partners) {
        partners.forEach((partner, index) => {
          if (
            partner &&
            partner.__typename === 'Partner' &&
            partner.logo &&
            partner.logo.url
          ) {
            jobPartners.push(
              <AutoScaleImage
                key={`pt-${index}`}
                uri={partner.logo.url}
                initWidth={60}
                initHeight={16}
                backgroundFallbackColor="transparent"
                resizeMode="contain"
              />,
            );
          }
        });
      }
      let buttonTitle = t('Start Job training');
      let currentCourse: IPartCourseFragmentFragment | undefined;
      const isCompleted = obtainedJob.find(
        _item => _item.certificateId === jobTrainingData.id,
      )?.finished;
      if (isCompleted) {
        buttonTitle = t('COMPLETED');
      } else if (courses) {
        for (let course of courses) {
          const findCourse = coursesStatus.find(
            _c =>
              course &&
              course.__typename === 'Course' &&
              _c.courseId === course.id,
          );
          if (findCourse) {
            buttonTitle = t('Continue');
          }
          if (
            (!findCourse || (findCourse && !findCourse.finished)) &&
            !currentCourse &&
            course?.__typename === 'Course'
          ) {
            currentCourse = course;
          }
        }
      }
      blocks.push(
        <ServiceHeader
          key={'header'}
          gradiantVariant="bottom"
          image={{
            uri:
              cover &&
              cover[0] &&
              cover[0].content &&
              cover[0].content.__typename === 'HeroVideoCover'
                ? cover[0].content.image?.cropUrl || cover[0].content.image?.url
                : undefined,
          }}
          title={title || ''}
          description=""
          buttonProps={{
            icon: isCompleted ? undefined : 'play',
          }}
          buttonTitle={buttonTitle}
          onPressButton={() => {
            if (
              currentCourse &&
              currentCourse.__typename === 'Course' &&
              !isCompleted
            ) {
              tracker.learn.certificateStarted(property);
              const {__typename, id: courseId, url} = currentCourse;
              handleClickCourse({__typename, id: courseId, url: url || ''});
            }
          }}
          partner={{
            label: t('Avec'),
            logo: jobPartners.length ? jobPartners : undefined,
            displayCol: jobPartners.length > 1,
          }}
        />,
      );

      let actionGroups: Array<ActionGroupItem> = [
        {
          // icon: isBookmarked(jobTraining.id) ? 'favoris-fill' : 'favoris',4s
          icon: (
            <FavorisIcon
              style={{padding: 13, margin: -13}}
              isLiked={isBookmarked(jobTraining.id)}
              onPressFav={(value: boolean) => {
                tracker.learn.certificate.liked(property);
                jobTraining &&
                  jobTraining.url &&
                  updateBookMark(jobTraining.id, jobTraining.url!, value, {
                    id: jobTraining.id,
                    url: jobTraining.url,
                    __typename: jobTraining.__typename,
                    raw: jobTraining,
                  });
              }}
              iconSize={'24'}
            />
          ),
          label: t('Favoris'),
        },
      ];
      if (trailer) {
        actionGroups.push({
          icon: 'play',
          label: t('Teaser'),
          onPress: () => {
            openTrailer(jobTraining);
          },
        });
      }
      blocks.push(
        <View
          key={'action-group'}
          style={{
            marginTop: Metrics.verticalScale(36),
          }}>
          <ActionGroup data={actionGroups} />
        </View>,
      );
      let itemOverview = overview;
      let _jobTrainingOverview: ItemOverviewProps | undefined = undefined;
      // if (isDownload && jobTraining) {
      //   itemOverview =  OverviewService.getJobTrainingOverView(
      //     jobTraining as IJobTrainingOverviewFragmentFragment,
      //   );
      // }
      if (itemOverview) {
        const {multimediaUnits, videosCount, duration, coursesCertificates} =
          itemOverview;

        _jobTrainingOverview = {
          title: t('Job training description'),
          data: {
            multimedia: `${multimediaUnits || 0} ${t('Multimedia Units')}`,
            badges: `${coursesCertificates || 0} ${t('Course Certificate(s)')}`,
            videos: `${videosCount || 0} ${t('Videos')}`,
            hours: duration,
          },
          loading: overviewLoading,
        };
      }
      blocks.push(
        <LearnOverview
          key={'overview'}
          title={t('Job training description') || undefined}
          overviewData={_jobTrainingOverview}
          description={description || ''}
        />,
      );
      let listCourses: Array<ILearnHomeCardProps<ICourseFragmentFragment>> = [];
      if (courses) {
        courses.forEach(item => {
          if (item && item.__typename === 'Course') {
            const {
              title: itemTitle,
              id: itemId,
              url,
              linkedPartners,
              __typename,
              length,
            } = item;
            let _counter = 0;
            let _total: number | undefined;
            let _isDone: boolean = false;
            const findCourseStatus = coursesStatus.find(
              _c => _c.courseId === jobTrainingData.id,
            );
            if (findCourseStatus && findCourseStatus.finished) {
              _isDone = true;
            } else if (jobCourses) {
              const findCourse = jobCourses.find(
                course =>
                  course &&
                  course.__typename === 'Course' &&
                  course.id === itemId,
              );
              if (
                findCourse &&
                findCourse.__typename === 'Course' &&
                findCourse.children &&
                findCourse.children.items
              ) {
                const {counter, total} = countProgression(
                  findCourse.children.items as ICourseUnitFragmentFragment[],
                );
                _counter = counter;
                _total = total;
              }
            }
            const duration = length
              ? formatDuration(length, length < 3600)
              : undefined;
            listCourses.push({
              cover: {
                uri: item.thumbnail?.cropUrl || item.thumbnail?.url,
              },
              title: itemTitle || '',
              duration,
              tag: {
                text: 'Course',
                variant: 'course',
              },
              id: itemId,
              url: url || '',
              partenerLogo:
                linkedPartners &&
                linkedPartners[0] &&
                linkedPartners[0].__typename === 'Partner'
                  ? linkedPartners[0].logo?.url
                  : undefined,
              size: 'small',
              onPress: () => {
                handleClickCourse({__typename, id: itemId, url: url || ''});
              },
              progress: _total ? _counter / _total : undefined,
              isDone: _isDone,
              textDone: t('Done') as string,
            });
          }
        });
        if (listCourses.length) {
          blocks.push(
            <div
              key={'list-course'}
              style={{
                padding: isMobile ? '0 16px' : '0 60px',
              }}>
              <AppGrid
                nbColumns={isMobile ? 2 : 4}
                columnGap={12}
                rowGap={isMobile ? 32 : 64}>
                {listCourses.map(item => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    {/* <TouchableOpacity onPress={item.onPress}> */}
                    <>
                      <LearnHomeCard
                        {...item}
                        isWithProgressBar
                        fullWidth={true}
                        aspectRatio={isMobile ? 168 / 288 : 247 / 376}
                      />
                    </>
                    {/* </TouchableOpacity> */}
                  </div>
                ))}
              </AppGrid>
            </div>,
          );
        }
      }
      return {
        pageContent: blocks,
        jobTraining,
      };
    }
    return {
      pageContent: undefined,
      jobTraining: undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobTrainingData,
    groupedBookmarks,
    overviewLoading,
    overview,
    jobCourses,
    obtainedJob,
    coursesStatus,
  ]);

  const {courseLoading, jobLoading} = useHandleUpdateLearnContent();

  return (
    <div
      style={{
        paddingBottom: isMobile ? 0 : 20,
      }}>
      <DebugUmbracoId umbracoId={jobTrainingData?.id} position="page" />
      {endUrl ? (
        loading ? (
          <HomeContentLoader />
        ) : (
          <>
            {pageContent}
            {(courseLoading || jobLoading) && (
              <View style={[StyleSheet.absoluteFill]}>
                <CustomActivityIndicator style={{flex: 1}} />
              </View>
            )}
          </>
        )
      ) : (
        <View style={{flex: 1}}>
          <ContentEmpty title={t('Section not found')} />
        </View>
      )}
    </div>
  );
}
