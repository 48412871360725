import {Pressable} from 'react-native';
import React, {useCallback, useMemo} from 'react';
import {IChannelFragmentFragment, IPaywallFragmentFragment} from '~Api/Graphql/gql/types.generated';
import Metrics from 'design-system/src/Theme/Metrics';
import CategoryLiveCardWeb from 'design-system/src/Components/Card/LiveCard/CategoryLiveCard/CategoryLiveCard.web';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import {ITvGuideItem} from '~Models/TvModel';
import dayjs from 'dayjs';
import usePaywal from '~Hooks/usePaywal';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
interface ILiveTvItemProps {
  channel: IChannelFragmentFragment;
}

export const getCurrentProgram = (
  programme: ITvGuideItem[],
): (ITvGuideItem & {start: Date; stop: Date}) | undefined => {
  for (let index = 0; index < programme.length; index++) {
    const item = programme[index];
    const {start, stop} = item;
    if (dayjs().isAfter(start) && dayjs().isBefore(stop)) {
      return item;
    }
  }

  return;
};

const LiveTvItem = (props: ILiveTvItemProps) => {
  const {channel} = props;
  const {thumbnail, logo, shortDescription} = channel;

  const navigation = useAppNavigation();
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();
  const {t} = useTranslation('translation');
  const canSee = useMemo(() => {
    return canSeeContent(channel.accessType);
  }, [canSeeContent, channel]);
  const {isMobile} = useBreakpoint();

  const handleWatch = useCallback(() => {
    const _canSee = canSeeContent(channel.accessType);
    const canPreview = getPremiumPreviewSettings(
      channel.paywall as IPaywallFragmentFragment,
    )?.canPreview;
    if (_canSee || canPreview) {
      navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
        state: {
          params: {
            type: 'player',
            source: 'livestream',
            url: channel.url || '',
            title: channel.title || 'Channel',
            liveEventId: channel.liveEventID || '',
            paywallData: {
              accessType: channel.accessType,
              paywall: channel.paywall,
            },
            id: channel.id,
            previousScreen: 'live tv',
          },
        },
      });
    } else {
      navigation.navigate(AppRoutesEnum.PAYWALL, {
        state: {
          params: {
            headerTitle: channel.title || 'Channel',
            paywall: channel.paywall,
            id: 'default',
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSeeContent, getPremiumPreviewSettings, navigation]);

  return (
    <Pressable
      onPress={handleWatch}
      style={{marginBottom: Metrics.verticalScale(18)}}>
      <CategoryLiveCardWeb
        onPress={handleWatch}
        subtitle={shortDescription}
        cover={{uri: thumbnail?.url}}
        hasTrailingIc={false}
        renderLogo={
          <CustomFastImage
            source={{uri: logo?.url || ''}}
            width={Metrics.horizontalScale(isMobile ? 80 : 120)}
            height={Metrics.verticalScale(isMobile ? 55 : 90)}
            backgroundFallbackColor="transparent"
          />
        }
        premiumText={t('premium')}
        isFreemium={canSee}
        type={isMobile ? 'small' : 'small_w'}
      />
    </Pressable>
  );
};

export default React.memo(LiveTvItem);
