import {ILiveEventFragmentFragment, IPaywallFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {useCallback, useMemo} from 'react';
import apiConfig from '~Configs/apiConfig';
import {useSelecteSubscription} from '@src/Store/selectors/useMember';
import useDefaultPaywall from '@src/Store/selectors/useDefaultPaywall';
import DateFactory from '@src/tools/DateFactory';
import {useAppSelector} from '@src/Store/hooks';

export interface IPaywallSetting {
  text: string;
  canPreview: boolean;
  previewDurationTv: number;
  previewDurationVod: number;
  currentPaywall?: IPaywallFragmentFragment;
}
const usePaywal = () => {
  const memberSubscription = useSelecteSubscription();
  const defaultPaywall = useDefaultPaywall();
  const memberPromoCode = useAppSelector(({member}) => member.memberPromoCode);

  const isValidPromocode = useMemo(() => {
    if (
      memberPromoCode &&
      (!memberPromoCode.subscriptionEnd ||
        new DateFactory(memberPromoCode.subscriptionEnd).diffDate() < 0)
    ) {
      return true;
    }
    return false;
  }, [memberPromoCode]);

  const isMemberFreemium = (): boolean => {
    if (isValidPromocode) {
      return false;
    } else if (memberSubscription && memberSubscription.productId) {
      return memberSubscription.productId === apiConfig.freemiumProductId;
    }
    return true;
  };

  const canSeeContent = useCallback(
    (accessType?: ILiveEventFragmentFragment['accessType']) => {
      if (isValidPromocode) {
        return true;
      } else if (memberSubscription) {
        if (accessType && accessType.length > 0) {
          const hasFreemium = !!accessType.find(e => {
            return (
              e &&
              e.__typename === 'Subscription' &&
              e.productID &&
              e.productID === apiConfig.freemiumProductId
            );
          });

          if (!hasFreemium) {
            return memberSubscription.productId !== apiConfig.freemiumProductId;
          }
        }
      }
      return true;
    },
    [memberSubscription, isValidPromocode],
  );

  const getPremiumPreviewSettings = useCallback(
    (
      paywall: IPaywallFragmentFragment | undefined = defaultPaywall,
    ): IPaywallSetting => {
      const currentPaywall = paywall || defaultPaywall;
      return {
        text: currentPaywall?.playerPremiumText || '',
        canPreview: !currentPaywall?.disabledPreview,
        previewDurationTv: (currentPaywall?.previewDurationTv || 0) * 60,
        previewDurationVod: (currentPaywall?.previewDurationVod || 0) * 60,
        currentPaywall: currentPaywall,
      };
    },
    [defaultPaywall],
  );

  const canPreview = (
    paywall: IPaywallFragmentFragment | undefined = defaultPaywall,
  ): boolean => {
    return !paywall?.disabledPreview;
  };
  return {
    canSeeContent,
    defaultPaywall,
    isMemberFreemium,
    memberSubscription,
    memberPromoCode,
    isValidPromocode,
    getPremiumPreviewSettings,
    canPreview,
  };
};

export default usePaywal;
