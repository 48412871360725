export enum AppRoutesEnum {
  DynamicPage = '#',
  HOME = '/',

  ARTIST_SERVICE_HOME = '/artist-services',
  ARTIST_SERVICE = '/artist-services/:id',
  FORMS = '/forms/:endUrl',
  SEARCH = '/search',

  // watch
  HOME_WATCH = '/tv-and-vod',
  LIVE_TV = '/tv-and-vod/live-tv',
  LIVE_EVENTS_ITEM = '/tv-and-vod/live-events/:id',
  LIVE_EVENTS = '/tv-and-vod/live-events',
  NEWS_HOME = '/news',
  NEWS_SINGLE = '/news/:endUrl1/:endUrl2',
  VOD_HOME = '/on-demand',
  VOD_CATEGORY_HOME = '/on-demand/category',
  VOD_CATEGORY = '/on-demand/category/:endUrl',
  VOD_SINGLE = '/on-demand/single/:endUrl1/:endUrl2', // standard
  VOD_COLLECTION = '/on-demand/collection/:endUrl1/:endUrl2', // serie - collection

  // listen
  HOME_LISTEN = '/audio',

  RADIO_HOME = '/radio',
  RADIO_CATEGORY_HOME = '/radio/category',
  RADIO_CATEGORY = RADIO_CATEGORY_HOME + '/:endUrl',
  RADIO_PLAYER = '/radio/player',

  PODCAST_CATEGORY_HOME = '/podcast/category',
  PODCAST_CATEGORY = PODCAST_CATEGORY_HOME + '/:endUrl',
  PODCAST_SINGLE = '/podcast/single/:id',
  PODCAST_HOME = '/podcast',
  PODCAST_PLAYER = '/podcast/player',
  LISTEN_REDIRECTION = '/audio-player',

  // learn
  COURSE_SINGLE = '/course/:endUrl1/:endUrl2',
  COURSE_UNIT = '/course-unit/:endUrl1/:endUrl2',
  EXAM = '/exam/:endUrl1/:endUrl2',
  LINKED_JOB = '/linked-job/:endUrl',
  EXAM_RESULT_OVERVIEW = '/exam-result-overview/:id',
  HOME_LEARN = '/academia',
  PARTNERS_LANDING = '/partner-landing',
  PARTNER_SINGLE = '/partner/single/:endUrl',
  OPPORTUNITY_SINGLE = '/opportunity/single/:endUrl',
  HOME_QUIZ = '/quiz',
  QUIZ_QUESTION = '/quiz/question/:endUrl',
  QUIZ_PERSONALITY = '/quiz/personality/:endUrl',
  QUIZ_METHODOLOGY = '/quiz/methodology/:endUrl',
  QUIZ_DETAIL = '/quiz/detail',
  QUIZ_RESULT = '/quiz/result/:endUrl',
  PERSONALITY = '/personality/:endUrl',
  SINGLE_JOB = '/single-job/:endUrl',
  JOB_TRAINING = '/job-training-certificate',
  JOB_HUB = '/job-hub',
  JOB_BOARD_PAGE = '/jb/:endUrl',
  ENTREPRENEURIAL_HUB = '/entrepreneurial-hub/:endUrl',
  OPPORTUNITY_CENTER = '/oc/:endUrl',
  COURSE_CATEGORY_HOME = '/course-category',
  COURSE_CATEGORY = COURSE_CATEGORY_HOME + '/:endUrl',

  // my-profilex
  PROFILE_HOME = '/my-profile',
  PROFILE_MY_PROFILE = '/my-profile/info',
  PROFILE_SUBSCRIPTION = '/my-profile/subscription',
  PROFILE_SETTINGS = '/my-profile/settings',
  PROFILE_LEGAL_INFORMATION = '/my-profile/cgv',
  PROFILE_HELPDESK = '/my-profile/helpdesk',
  PROFILE_ZENDESK_FORM = '/my-profile/zendesk-form/:title',
  PROFILE_ACHIEVEMENT = '/my-profile/achievement',
  PROFILE_NOTIFICATION = '/my-profile/notification',
  PROFILE_OPPORTUNITIES = '/my-profile/opportunities',

  //
  MORE = '/more',

  // PAYWALL
  PAYWALL = '/paywall/:id',
  CHOOSE_PLAN = '/checkout',
  SUCCESS_PLAN = '/checkout/confirmation',

  VIDEO_PLAYER = '/video-player',

  TERMS_AND_CONDITION = '/terms-and-conditions',

  COMING_SOON = '/coming-soon/:id',

  BOOKMARKS = '/bookmarks',

  // Promocode
  PROMOCODE = '/promoCode',

  //
  THANK_YOU_MODAL = '/thank-you-modal',

  THANK_YOU = '/thank-you/:id',

  // quizz survey
  QUIZZ_SURVEY_COLLECTION = '/quizz-survey-collection',
  QUIZZ_SURVEY = '/quizz-survey',
  QUIZZ_SURVEY_CATEGORY = '/quizz-survey-category',
  PLAYER_QUIZ_SURVEY = '/player-quizz-survey',

  // tutoriel
  TUTORIAL = '/tutorial',
}

export enum AppHomeWatchType {
  TV_GUIDE = 'tv-guide',
  LIVE_TV = 'live-tv',
}
