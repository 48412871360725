import {
  Pressable,
  View,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
} from 'react-native';
import React, {useEffect, useRef, useState} from 'react';
import {
  roundedTextInputstyles,
  RoundedTextInputVariant,
} from './RoundedTextInput.styles';
import {IconName} from '../../../assets/Icones';
import defaultTheme from '../../../Theme/defaultTheme';
import Metrics from '../../../Theme/Metrics';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';
import FloatingLabel from '../../Form/FloatingLabel/FloatingLabel';
import {typographyVariantStyled} from '../../Typography/Typography.styles';
import {useThemeContext} from '../../../Provider/ThemeProvider/ThemeContext';

export interface RoundedTextInputProps extends RNTextInputProps {
  onPressSuffixIcon?: () => void;
  prefixIcon?: IconName;
  suffixIcon?: IconName | React.ReactNode;
  // theme?: any;
  height?: number;
  placeholderTextColor?: string;
  iconColor?: string;
  variant?: keyof typeof RoundedTextInputVariant;
  label?: string;
  clearable?: boolean;
  onClear?: () => void;
  helperText?: string;
  activeOutlineColor?: string;
  disableLabel?: boolean;
  disabled?: boolean;
  innerRef?: React.RefCallback<RNTextInput | undefined>;
  backgroundColor?: string;
  textAlign?: 'left' | 'center';
  alwaysShowPlaceholder?: boolean;
  fontSize?: number;
}

const RoundedTextInput = (props: RoundedTextInputProps) => {
  const {
    prefixIcon,
    suffixIcon,
    onPressSuffixIcon,
    // theme = roundedTextInputTheme,
    placeholder,
    variant = 'default',
    height,
    placeholderTextColor = defaultTheme.primaryInfo,
    label,
    style,
    clearable,
    onClear,
    value: inValue,
    onChangeText,
    iconColor,
    helperText,
    secureTextEntry,
    onFocus,
    onBlur,
    editable = true,
    multiline,
    disableLabel,
    innerRef,
    backgroundColor,
    textAlign = 'left',
    alwaysShowPlaceholder,
    fontSize,
    ...restProps
  } = props;
  const {component: {textInput: {mapColor = (c: string) => c} = {}} = {}} =
    useThemeContext();
  const inputRef = useRef<RNTextInput | null>(null);
  const [value, setValue] = useState<string | undefined>(inValue);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(
    alwaysShowPlaceholder ? true : !editable,
  );
  const [showPassword, setShowPassword] = useState(false);

  // const theme = RoundedTextInputVariant[variant];
  const handleChangeText = (text: string) => {
    setValue(text);
    onChangeText && onChangeText(text);
  };

  useEffect(() => {
    if (inValue !== value) {
      setValue(inValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inValue]);

  const handleClear = () => {
    handleChangeText('');
    onClear && onClear();
  };
  const handleFocus: RNTextInputProps['onFocus'] = e => {
    if (!editable) {
      return;
    }
    setHasFocus(true);
    onFocus && onFocus(e);
    setTimeout(() => {
      setShowPlaceHolder(true);
    }, 400);
  };
  const handleBlur: RNTextInputProps['onBlur'] = e => {
    if (!editable) {
      return;
    }
    if (!alwaysShowPlaceholder) {
      setShowPlaceHolder(false);
    }
    setHasFocus(false);
    onBlur && onBlur(e);
  };
  const floatinLabelFocus = !!value || hasFocus;
  const securedTextInputResult = secureTextEntry && !showPassword;
  return (
    <View
      style={[
        // eslint-disable-next-line react-native/no-inline-styles
        {
          flexDirection: 'column',
          width: '100%',
          flex: 1,
        },
        style,
      ]}>
      <View style={[roundedTextInputstyles.inputContainer]}>
        <FloatingLabel
          hasFocus={hasFocus}
          hasValue={!!value}
          disableLabel={disableLabel && !value}
          style={[
            {flex: 1, height},
            multiline && {
              paddingVertical: Metrics.horizontalScale(16),
              borderRadius: Metrics.horizontalScale(26),
            },
          ]}
          variant={variant}
          multiline={multiline}
          label={label}
          onPress={() => inputRef.current?.focus()}>
          <RNTextInput
            ref={_ref => {
              inputRef.current = _ref;
              innerRef?.(_ref);
            }}
            activeOutlineColor={mapColor(defaultTheme.input.activeBorderColor)}
            selectionColor={mapColor(defaultTheme.input.activeBorderColor)}
            textAlignVertical={multiline ? 'top' : 'center'}
            textBreakStrategy="highQuality"
            style={[
              roundedTextInputstyles.input,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                flex: 1,
                paddingBottom: 0,
                paddingTop: 0,
                margin: 0,
                backgroundColor: backgroundColor,
                paddingLeft: Metrics.horizontalScale(prefixIcon ? 48 : 24),
                paddingRight: Metrics.horizontalScale(suffixIcon ? 25 : 24),
                height: height,
                minHeight: Metrics.verticalScale(48),
                color: defaultTheme.white,
                textAlignVertical: multiline ? 'top' : 'center',
                fontSize: fontSize || typographyVariantStyled.body2.fontSize,
                fontFamily: securedTextInputResult
                  ? undefined
                  : typographyVariantStyled.body2.fontFamily,
                letterSpacing: typographyVariantStyled.body2.letterSpacing,
                textAlign: textAlign,
              },
            ]}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholderTextColor={
              editable
                ? !floatinLabelFocus && !disableLabel && !showPlaceHolder
                  ? 'trasparent'
                  : placeholderTextColor
                : placeholderTextColor
            }
            placeholder={
              editable
                ? !floatinLabelFocus && !disableLabel
                  ? ''
                  : showPlaceHolder
                    ? placeholder
                    : ''
                : label || placeholder
            }
            value={value}
            onChangeText={handleChangeText}
            editable={editable}
            multiline={multiline}
            secureTextEntry={securedTextInputResult}
            {...restProps}
          />
        </FloatingLabel>

        {prefixIcon && (
          <Icon
            color={mapColor(iconColor || placeholderTextColor)}
            name={prefixIcon}
            size="24"
            style={roundedTextInputstyles.prefixIcon}
          />
        )}
        {suffixIcon ? (
          <Pressable
            style={roundedTextInputstyles.suffixIcon}
            onPress={onPressSuffixIcon}>
            {!React.isValidElement(suffixIcon) ? (
              <Icon
                color={mapColor(iconColor || placeholderTextColor)}
                name={suffixIcon as IconName}
              />
            ) : (
              suffixIcon
            )}
          </Pressable>
        ) : secureTextEntry ? (
          <Pressable
            style={[roundedTextInputstyles.suffixIcon]}
            onPress={() => setShowPassword(v => !v)}>
            <Icon
              color={mapColor(placeholderTextColor)}
              name={showPassword ? 'eye' : 'crossed-eye'}
            />
          </Pressable>
        ) : (
          clearable &&
          !!value && (
            <Pressable
              style={[roundedTextInputstyles.suffixIcon]}
              onPress={handleClear}>
              <Icon
                color={mapColor(placeholderTextColor)}
                size="16"
                name="radio-off"
              />
            </Pressable>
          )
        )}
      </View>
      {!!helperText && (
        <View style={roundedTextInputstyles.helperContainer}>
          <Typography variant="ui3" color="gray">
            {helperText}
          </Typography>
        </View>
      )}
    </View>
  );
};

export default RoundedTextInput;
