import apiConfig from '@src/Configs/apiConfig';

export const isTrackingEnabled = (): boolean => {
  console.log('isTrackingEnabled-called');
  if (typeof window !== 'undefined' && typeof window.Didomi !== 'undefined') {
    const {purposes} = window.Didomi.getUserConsentStatusForAll();
    console.log('purposes', apiConfig.amplitudePurpId, purposes);
    return (
      !!purposes.enabled.length &&
      purposes.enabled.includes(apiConfig.amplitudePurpId)
    );
  }
  return false;
};
