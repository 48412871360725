import ActionGroup, {
  ActionGroupItem,
} from 'design-system/src/Components/ActionGroup/ActionGroup';
import AutoScaleImage from 'design-system/src/Components/AutoScaleImage/AutoScaleImage';
import Button from 'design-system/src/Components/Button/Button';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ServiceHeader from 'design-system/src/Components/Header/ServiceHeader/ServiceHeader.web';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View} from 'react-native';
import {useOpportunityByUrlEndQuery} from '~Api/Graphql/gql/types.generated';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import Toast from 'design-system/src/Components/Toast/Toast';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import {useAppDispatch} from '@src/Store/hooks';
import {useUpdateOpportunity} from '@src/Hooks/useUpdateOpportunity';
import HtmlRender from '@src/Umbraco/Components/HtmlRender/HtmlRender';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

const SingleOpportunity = () => {
  const params = useParams();

  const navigation = useAppNavigation();
  const {endUrl} = params || {};
  const {t} = useTranslation(['learn']);
  const response = useOpportunityByUrlEndQuery({
    variables: {
      endUrl,
    },
    skip: !endUrl,
  });
  const opportunity = response.data?.allOpportunity?.items[0];
  const {isMobile} = useBreakpoint();

  const dispatch = useAppDispatch();

  const {handleAcceptOpportunity, createLoading, findOpportunity, findJob} =
    useUpdateOpportunity();

  const loading = response.loading;

  const {
    pageContent,
  }: {
    pageContent?: React.ReactNode[];
  } = useMemo(() => {
    const {data} = response;
    let _job: string | undefined;
    if (opportunity) {
      let pageBlocks: ReactNode[] = [];
      const {
        title,
        location,
        certificate,
        pageCover,
        pageContent: content,
        partners,
        shortDescription,
        contractType,
        id,
      } = opportunity;
      const certificateId =
        certificate &&
        certificate[0] &&
        certificate[0].__typename === 'JobTraining'
          ? certificate[0].id
          : '';
      const isExistJob = findJob(certificateId);
      const findData = findOpportunity(id);
      let headerButton: ReactNode;
      let partner: string[] = [];
      partners?.forEach(_partner => {
        if (
          _partner &&
          _partner.__typename === 'Partner' &&
          _partner.partnerName
        ) {
          partner.push(_partner.partnerName);
        }
      });
      if (!findData) {
        if (certificate) {
          if (!isExistJob) {
            headerButton = (
              <Button
                title={t('discover certificate')}
                variant="outlined"
                size="small"
                onPress={() => {
                  if (
                    certificate &&
                    certificate[0] &&
                    certificate[0].__typename === 'JobTraining'
                  ) {
                    const {id, __typename, url} = certificate[0];
                    _job = id;
                    new RouteFactory(
                      {url: url || '', id, __typename},
                      navigation,
                      dispatch,
                    ).navigate();
                  }
                }}
              />
            );
          } else {
            headerButton = (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                <Button
                  title={t('Accept')}
                  variant="outlined"
                  loading={createLoading}
                  size="small"
                  style={{marginRight: Metrics.horizontalScale(8)}}
                  onPress={() => {
                    handleAcceptOpportunity(id, true, partner.join(''));
                  }}
                />
                <Button
                  title={t('Decline')}
                  variant="outlined"
                  loading={createLoading}
                  size="small"
                  onPress={() => {
                    handleAcceptOpportunity(id, false, partner.join(''));
                  }}
                />
              </View>
            );
          }
        }
      } else {
        const toastTitle = findData.accept ? t('Accepted') : t('Declined');
        headerButton = <Toast valid={findData.accept} title={toastTitle} />;
      }

      pageBlocks.push(
        <ServiceHeader
          key={'header'}
          gradiantVariant="bottom"
          image={
            pageCover &&
            pageCover[0] &&
            pageCover[0].content &&
            pageCover[0].content.__typename === 'HeroVideoCover'
              ? {
                  uri: pageCover[0].content.image?.url,
                }
              : undefined
          }
          title={title || ''}
          description={shortDescription || ''}
          bottomExtra={
            headerButton && (
              <View style={{alignItems: 'center'}}>{headerButton}</View>
            )
          }
          upperExtra={
            <AutoScaleImage
              uri={
                partners &&
                partners[0] &&
                partners[0].__typename === 'Partner' &&
                partners[0].logo &&
                partners[0].logo.url
                  ? partners[0].logo?.url
                  : ''
              }
              resizeMode="contain"
              initWidth={106}
              initHeight={18}
              backgroundFallbackColor="transparent"
            />
          }
        />,
      );
      let actionGroupItems: ActionGroupItem[] = [];
      if (contractType) {
        actionGroupItems.push({
          icon: 'message',
          title: t('Contrat type') as string,
          isActive: true,
          label: contractType,
          labelColor: 'white',
        });
      }
      if (location) {
        actionGroupItems.push({
          icon: 'location',
          title: t('Location') as string,
          isActive: true,
          label: location,
          labelColor: 'white',
        });
      }
      if (actionGroupItems.length) {
        pageBlocks.push(
          <View
            key={'action-gp'}
            style={{marginTop: Metrics.verticalScale(42)}}>
            <ActionGroup data={actionGroupItems} withBorder={true} />
          </View>,
        );
      }

      pageBlocks.push(
        content && (
          <View
            key={'content'}
            style={{
              paddingHorizontal: Metrics.horizontalScale(isMobile ? 16 : 60),
              marginTop: Metrics.verticalScale(42),
            }}>
            <HtmlRender variant={'body3'} html={content} />
          </View>
        ),
      );
      return {pageContent: pageBlocks, jobTraining: _job};
    }
    return {pageContent: undefined, jobTraining: _job};
  }, [
    response,
    findOpportunity,
    handleAcceptOpportunity,
    createLoading,
    findJob,
  ]);

  return (
    <div>
      <DebugUmbracoId umbracoId={opportunity?.id} position="page" />
      {loading ? (
        <CustomActivityIndicator
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      ) : endUrl ? (
        pageContent
      ) : (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ContentEmpty title={t('Section not found')} />
        </View>
      )}
    </div>
  );
};

export default SingleOpportunity;
