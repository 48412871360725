import Modal from 'react-modal';
import {Pressable} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';
import React, {FC, useMemo} from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from 'react-share';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import defaultTheme from '../../../Theme/defaultTheme';
import Button from '../../Button/Button';

interface IShareDialogProps {
  isOpen: boolean;
  onClose: () => void;
  link?: string | null;
}

const ShareDialog: FC<IShareDialogProps> = ({isOpen, onClose, link}) => {
  const url = useMemo(() => {
    if (isOpen) {
      return link || window.location.href || '';
    }
    return '';
  }, [isOpen, link]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('vod-collection-page') as HTMLElement}
      overlayElement={(d, contentElement) => (
        <div
          {...d}
          style={{
            ...d.style,
            background: 'rgba(17, 18, 22, 0.60)',
            backdropFilter: 'blur(10px)',
            zIndex: 11000,
          }}>
          {contentElement}
        </div>
      )}
      style={{
        content: {
          border: 'none',
          background: 'transparent',
          padding: 0,
          margin: 'auto',
          width: 'fit-content',
          height: 'fit-content',
          inset: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        overlay: {
          background: 'rgba(17, 18, 22, 0.60)',
          backdropFilter: 'blur(10px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: defaultTheme.page.background,
          borderRadius: 8,
          padding: 20,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h3" style={{color: 'white'}}>
            Share
          </Typography>
          <Pressable onPress={onClose}>
            <Icon name="close" color="white" />
          </Pressable>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 40,
              padding: 20,
            }}>
            <EmailShareButton url={url}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton url={url}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <XIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Button
            variant="contained"
            onPress={() => {
              navigator.clipboard.writeText(url);
              onClose();
            }}
            title="Copy Link"
            size="small"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ShareDialog;
