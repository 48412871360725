/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {Text, View, Pressable} from 'react-native';
import Typography from '../../Typography/Typography';
import {radioPlayerCardStyles} from './RadioPlayerCard.styles.web';
import Metrics from '../../../Theme/Metrics';
import CustomFastImage from '../../CustomFastImage/CustomFastImage';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import Icon from '../../Icon/Icon';

export type RadioPlayerCardProps = {
  title?: string;
  subtitle?: string;
  radioName?: string;
  poster?: CustomFastImageProps['source'];
  currentTime?: number;
  isRadio?: boolean;
  sliderComponent?: React.ReactNode;
  controlComponent: React.ReactNode;
  onPressExtend: () => void;
};

const RadioPlayerCard = (props: RadioPlayerCardProps) => {
  const {
    title,
    subtitle,
    radioName,
    poster,
    sliderComponent,
    controlComponent,
    onPressExtend,
  } = props;

  const {isMobile} = useBreakpoint();

  return (
    <View style={radioPlayerCardStyles.container}>
      <View style={radioPlayerCardStyles.cardContainer}>
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
          }}>
          <View style={{marginRight: Metrics.horizontalScale(9)}}>
            <CustomFastImage
              style={radioPlayerCardStyles.card}
              radius={radioPlayerCardStyles.card.borderRadius}
              width={radioPlayerCardStyles.card.width}
              height={radioPlayerCardStyles.card.height}
              source={poster}
            />
          </View>
          <View style={radioPlayerCardStyles.aside}>
            <View style={radioPlayerCardStyles.body}>
              <Text numberOfLines={1} style={radioPlayerCardStyles.title}>
                {radioName && (
                  <Typography
                    children={`${radioName}`}
                    variant="ui2"
                    color="primary"
                    style={{marginRight: Metrics.horizontalScale(8)}}
                  />
                )}
                <Typography children={title} variant="ui2" />
              </Text>
              <Typography
                numberOfLines={1}
                children={subtitle}
                variant="body3"
                color={() => 'rgba(255,255,255,0.5)'}
              />
            </View>
          </View>
        </View>
        <View
          style={{
            alignItems: 'center',
            flex: 1,
          }}>
          {controlComponent && controlComponent}
          {sliderComponent && sliderComponent}
        </View>
        <View
          style={{
            justifyContent: 'center',
            flex: 1,
            alignItems: 'flex-end',
          }}>
          <Pressable onPress={onPressExtend}>
            <Icon name="expand" size="24" />
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default RadioPlayerCard;
