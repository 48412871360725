import React, {PropsWithChildren} from 'react';
import {StyleSheet, View, Animated} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Metrics, {isWeb} from '../../../Theme/Metrics';
import Button from '../../Button/Button';
import CustomBlurView from '../../CustomBlurView/CustomBlurView';
import CustomFastImage from '../../CustomFastImage/CustomFastImage.web';
import Typography from '../../Typography/Typography';
import {customHeaderSize, customHeaderStyles} from './CustomHeader.styles';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import {CustomHeaderProps} from './CustomHeader.props';

const CustomHeader = (props: PropsWithChildren<CustomHeaderProps>) => {
  const {
    image,
    title,
    buttonTitle,
    children,
    size = 'medium',
    footer,
    contentBottom,
    variant,
    isBgBlur,
    buttonProps = {
      variant: 'outlined',
      icon: 'play',
      size: 'medium',
    },
    onPressButton,
    imageHeight,
    hideButtonIcon,
    buttonContainerStyle,
    hideButton,
    extraContent,
    animatedScroll,
    style,
    aspectRatio: fixedAspectRatio,
    contentProgress,
  } = props;

  buttonProps.icon = hideButtonIcon ? undefined : buttonProps.icon ?? 'play';
  const sizeStyles = customHeaderSize[size];
  const {isMobile} = useBreakpoint();
  const isVodVariant = variant === 'vod';
  const isCenteredVariant = variant === 'centered';
  const h = imageHeight || sizeStyles.minHeight;
  const topGap = Metrics.verticalScale(241);
  // const paddingTopInterpolation = animatedScroll
  //   ? animatedScroll.interpolate({
  //       inputRange: [0, topGap],
  //       outputRange: [topGap, 0],
  //       extrapolate: 'clamp',
  //     })
  //   : 0;
  const gradiantTopInterpolation = animatedScroll
    ? animatedScroll.interpolate({
        inputRange: [0, topGap],
        outputRange: [0, -topGap],
        extrapolate: 'clamp',
      })
    : 0;
  const mainInterpolation = animatedScroll
    ? animatedScroll.interpolate({
        inputRange: [0, h + topGap],
        outputRange: [h + topGap, 0],
        extrapolate: 'clamp',
      })
    : h + topGap;

  const aspectRatio = fixedAspectRatio || (!isMobile ? 16 / 9 : 3 / 4);

  const content = (
    <View
      style={[
        customHeaderStyles.content,
        {
          alignItems: 'center',
          marginBottom: Metrics.verticalScale(contentBottom ?? 0),
          maxWidth: isMobile ? '100%' : isCenteredVariant ? '473px' : '50%',
          paddingHorizontal: isMobile ? 24 : undefined,
        },
      ]}>
      {isCenteredVariant && (
        <View
          style={{
            width: '100%',
            alignItems: 'center',
            borderRadius: Metrics.moderateScale(4),
            marginBottom: Metrics.verticalScale(34),
            position: 'relative',
          }}>
          <CustomFastImage
            source={image}
            width={Metrics.horizontalScale(isMobile ? 226 : 473)}
            height={Metrics.verticalScale(isMobile ? 126 : 264)}
            radius={Metrics.moderateScale(4)}
          />
          <View
            style={{
              position: 'absolute',
              bottom: 0,
            }}>
            {contentProgress && contentProgress}
          </View>
        </View>
      )}
      <Typography
        style={{
          textAlign: 'center',
          maxWidth: isWeb ? 924 : Metrics.horizontalScale(308),
        }}
        children={title}
        variant={isMobile ? 'big3' : 'big2'}
      />
      <View style={customHeaderStyles.children}>{children}</View>
      {!hideButton && (
        <Button
          {...buttonProps}
          title={buttonTitle || ''}
          onPress={onPressButton}
          style={{
            paddingHorizontal: Metrics.horizontalScale(27),
          }}
          containerStyle={buttonContainerStyle}
        />
      )}
      {extraContent}
    </View>
  );

  return (
    <Animated.View
      style={[
        style,
        {
          position: 'relative',
          minHeight: variant === 'animated' ? mainInterpolation : undefined,
          aspectRatio: isVodVariant && !isMobile ? undefined : aspectRatio,
          borderRadius:
            isVodVariant && !isMobile
              ? 0
              : isCenteredVariant
                ? 0
                : isMobile
                  ? 0
                  : 12,
          overflow: 'hidden',
          justifyContent: isCenteredVariant ? 'center' : 'flex-end',
        },
      ]}>
      {/* background image */}
      <View
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
        }}>
        <CustomFastImage
          source={image}
          style={{aspectRatio}}
          // height={isVodVariant ? Metrics.verticalScale(464) : '100%'}
          backgroundFallbackColor="transparent"
          resizeMode="cover">
          {isBgBlur && (
            <CustomBlurView
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
              }}
              blurType="dark"
              blurAmount={100}
            />
          )}
        </CustomFastImage>
        {isVodVariant && (
          <CustomBlurView
            blurType="dark"
            blurAmount={20}
            style={[
              StyleSheet.absoluteFill,
              {backgroundColor: 'rgba(17, 18, 22, 0.5)'},
            ]}
          />
        )}
      </View>

      {/* Gradient */}
      {isVodVariant || isCenteredVariant ? (
        <LinearGradient
          useAngle
          angle={180}
          locations={[0.1554, 0.5745, 0.7432]}
          colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
          style={{
            opacity: 1,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
          }}
        />
      ) : (
        <>
          {/* <LinearGradient
            useAngle
            angle={360}
            locations={[0.4486, 0.7163, 1]}
            colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
            style={{
              opacity: 0.5,
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: Metrics.verticalScale(632),
            }}
          /> */}
          {
            <Animated.View
              style={[
                {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  transform: [
                    {
                      translateY: gradiantTopInterpolation,
                    },
                  ],
                },
              ]}>
              {/* background: linear-gradient(180deg, rgba(17, 18, 22, 0.00) 0%, rgba(17, 18, 22, 0.60) 57.14%, #111216 93.75%); */}
              <LinearGradient
                colors={[
                  'rgba(17, 18, 22, 0)',
                  'rgba(17, 18, 22, 0.6)',
                  '#111216',
                ]}
                locations={[0, 0.5714, 0.9375]}
                useAngle={true}
                angle={180}
                angleCenter={{x: 0.5, y: 0.5}}
                style={{
                  opacity: 1,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: -1,
                  height: '100%',
                }}
              />
            </Animated.View>
          }
        </>
      )}

      {/* Content */}
      <Animated.View
        style={[
          customHeaderStyles.container,
          sizeStyles,
          isVodVariant
            ? {
                paddingTop: isMobile ? Metrics.verticalScale(96) : undefined,
              }
            : variant === 'slider'
              ? {paddingTop: Metrics.verticalScale(241)}
              : variant === 'animated'
                ? {
                    paddingTop: 0,
                    position: 'absolute',
                    bottom: 0,
                  }
                : isCenteredVariant
                  ? {
                      paddingTop: 0,
                    }
                  : {paddingTop: Metrics.verticalScale(261)},
          {
            justifyContent: isCenteredVariant ? 'center' : 'flex-start',
          },
        ]}>
        {isVodVariant && (
          <CustomFastImage
            source={image}
            width={'100%'}
            // height={width / (16 / 9)}
            style={{
              aspectRatio: 2 / 1,
            }}
            backgroundFallbackColor="transparent"
            resizeMode="cover">
            {!isMobile && (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  background:
                    'linear-gradient(rgba(17, 18, 22, 0) 15.54%, rgb(17 18 22 / 30%) 49.45%, rgb(17, 18, 22) 100%)',
                }}>
                {content}
              </div>
            )}
          </CustomFastImage>
        )}
        {(isMobile || !isVodVariant) && content}
        {footer && (
          <View
            style={{
              backgroundColor: 'transparent',
              flex: 1,
              width: '100%',
            }}>
            {footer}
          </View>
        )}
      </Animated.View>
    </Animated.View>
  );
};

export default React.memo(CustomHeader);
