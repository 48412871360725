import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import Metrics from 'design-system/src/Theme/Metrics';
import UnitPlayer from 'design-system/src/Widget/Player/UnitPlayer/UnitPlayer.web';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View, StyleSheet} from 'react-native';
import {IUcAudioFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {useLazyGetVimeoVideoQuery} from '~Api/TraceApi/traceApi';

interface IUcAudioProps {
  content: IUcAudioFragmentFragment;
}

const UcAudio = (props: IUcAudioProps) => {
  const {
    content: {liveEventID, thumbnail},
  } = props;
  const [pause, setPause] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<string>();
  const [fetchVimeoVideo, {data: videoInfo, isLoading}] =
    useLazyGetVimeoVideoQuery();
  const {t} = useTranslation(['learn']);

  useEffect(() => {
    if (typeof liveEventID === 'string') {
      setCurrentVideo(liveEventID);
    } else if (liveEventID) {
      fetchVimeoVideo(liveEventID + '').then(value => {
        const {data} = value;
        if (data) {
          setCurrentVideo(data.hls);
        }
      });
    }
  }, [fetchVimeoVideo, liveEventID]);

  return (
    <View
      style={{
        position: 'relative',
        marginTop: Metrics.verticalScale(24),
      }}>
      {isLoading ? (
        <CustomActivityIndicator style={styles.container} />
      ) : currentVideo ? (
        <UnitPlayer
          audioOnly
          paused={pause}
          // forcePause={!isFocused}
          width={Metrics.horizontalScale(327)}
          height={Metrics.verticalScale(392)}
          poster={thumbnail?.url || videoInfo?.picture || ''}
          source={
            currentVideo
              ? {
                  uri: currentVideo,
                }
              : undefined
          }
          onPlay={v => {
            setPause(!v);
          }}
          messageError={t('unable to load video') as string}
        />
      ) : (
        <ContentEmpty title={t('unable to load video')} />
      )}
    </View>
  );
};

export default UcAudio;

const styles = StyleSheet.create({
  container: {
    width: Metrics.horizontalScale(327),
    height: Metrics.verticalScale(392),
  },
});
