/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import ListenPlayer, {
  IAudioSource,
  IEpgData,
} from 'design-system/src/Widget/Player/ListenPlayer/ListenPlayer.web';
import {View, StyleSheet} from 'react-native';
import Metrics from 'design-system/src/Theme/Metrics';
import TopListenCard from 'design-system/src/Components/Card/ListenCard/TopListenCard/TopListenCard';
import {
  IHeroVideoCoverFragmentFragment,
  ITopTenFragmentFragment,
  useRadioByIdLazyQuery,
  usePlayListByIdLazyQuery,
  IPlaylistFragmentFragment,
  IRadioFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import useUpdateBookmarks from '~Hooks/useUpdateBookmarks';
import {RouteSource, RouteTypename} from '~Navigation/type';
import PaywallChecker from '~Umbraco/Components/PaywallChecker/PaywallChecker';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import {UseFloatingListen} from '@src/Providers/FloatingListenProvider/FloatingListenProvider';
import {
  useLazyExtendUrlQuery,
  useLazyProxyQuery,
} from '@src/Api/TraceApi/traceApi';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import appListenSlice from '@src/Store/Slices/AppSlice/listen.slice';
import SuspenseAfterRequired from 'design-system/src/library/AfterRequired/SuspenseAfterRequired';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';
import useShareContent from '@src/Hooks/useShareContent';
import useGetLinkToShare from '@src/utils/useGetLinkToShare';
import {AppRoutesEnum} from '@src/Constant/routes';
import useViewPort from 'design-system/src/Hooks/useViewPort';
import useRouteActive from '@src/layout/AppLayout/AppHeader/useRouteActive';

const ListenPlayList = React.lazy(
  () => import('design-system/src/Widget/Listen/ListenPlayList/ListenPlayList'),
);

interface ITopMusic {
  title: string;
  description: string;
  topMusic: Array<{
    title: string;
    subtitle: string;
    cover: string;
  }>;
}

const parseToHttps = (url: string) => {
  return url.replace('http://', 'https://');
};

const RadioPlayerPage: FC = () => {
  const {t} = useTranslation('translation');
  const {isMobile} = useBreakpoint();
  const dispatch = useAppDispatch();
  const {params, isMini} = useAppSelector(state => state.appListen);
  const [isOpenDialogShare, setIsOpenDialogShare] = useState(false);
  const [epgData, setEpgData] = useState<IEpgData>();

  const shareContent = useShareContent();

  const {
    id,
    __typename,
    isPaused,
  }: {
    id: string;
    __typename: RouteTypename;
    isPaused: boolean;
  } = (params ?? {}) as any;

  const tracker = useTracker();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {state, setFloatingState} = UseFloatingListen()!;
  const [currentAudio, setCurrentAudio] = useState<IAudioSource | undefined>();
  const [topMusic, setTopMusic] = useState<ITopMusic>();
  const [currentData, setCurrentData] = useState<{
    radio?: IRadioFragmentFragment;
    playlist?: IPlaylistFragmentFragment;
  }>({});
  const [isFetchingEpg, setIsFetchingEpg] = useState(false);
  const {updateBookMark, isBookmarked, findBookmarkItem} = useUpdateBookmarks();
  const [fetchRadio, radioResponse] = useRadioByIdLazyQuery();
  const [fetchPlaylist, playlistResponse] = usePlayListByIdLazyQuery();

  const [fetchExtendUrl] = useLazyExtendUrlQuery();
  const [fetchProgramFile] = useLazyProxyQuery();

  const [linkToShare, setLinkToShare] = useState<string | null>(null);

  const {getLinkToShare} = useGetLinkToShare();
  const {viewportHeight} = useViewPort();

  // const programFileLoading = useRef<boolean>(true);

  const typeNameRef = useRef<RouteTypename>(__typename);
  const [title, setTitle] = useState('');
  const [loadingFile, setLoadingFile] = useState<boolean>(true);

  const loading =
    radioResponse.loading || playlistResponse.loading || loadingFile;

  const fetchEpg = async (epgUrl?: string) => {
    const epgLink = epgUrl || currentAudio?.epgLink;
    // console.log('fetchEpg', epgLink);
    if (epgLink) {
      setIsFetchingEpg(true);
      const coverContent = currentData.radio?.cover?.[0]
        ?.content as IHeroVideoCoverFragmentFragment;

      const defaultPoster: string =
        coverContent?.desktopImage?.url ||
        coverContent?.image?.url ||
        currentData.radio?.thumbnail?.url ||
        '';

      try {
        const dateNow = Date.now();
        const {data} = await fetchProgramFile({
          url: epgLink + `?cb=${dateNow}`,
        });

        // console.log('--epg-data', {epgLink, data});
        if (data) {
          setEpgData({
            title: data.title,
            subtitle: data.artist,
            customPoster: data.cover
              ? {uri: data.cover['600'] || defaultPoster}
              : {uri: defaultPoster},
          });
          const remaining =
            data.timestamp * 1000 + (data.duration + 35000) - Date.now();
          const timeout = remaining < 0 ? 4000 : remaining;

          if (timeoutRef.current) {
            // console.log('clearTimeout');
            clearTimeout(timeoutRef.current);
          }
          // console.log('--setting timeout', timeout, Date.now());

          timeoutRef.current = setTimeout(() => {
            // console.log('Timeout triggered');
            fetchEpg(epgLink);
          }, timeout);
        } else {
          console.warn('No data received for EPG');
          // setEpgData({
          //   title: currentData.radio?.title,
          //   subtitle: currentData.radio?.subtitle,
          //   customPoster: {uri: defaultPoster || ''},
          // });
        }
      } catch (error) {
        console.error('Error fetching EPG:', error);
        // setEpgData({
        //   title: currentData.radio?.title,
        //   subtitle: currentData.radio?.subtitle,
        //   customPoster: {uri: defaultPoster || ''},
        // });
      } finally {
        setIsFetchingEpg(false);
      }
    } else {
      console.warn('No EPG link available');
    }
  };

  useEffect(() => {
    if (id) {
      if (__typename === 'Radio') {
        fetchRadio({
          variables: {
            id: id,
          },
          errorPolicy: 'all',
        });
      } else if (__typename === 'Playlist') {
        fetchPlaylist({
          variables: {
            id: id,
          },
          errorPolicy: 'all',
        });
      }
    }
  }, [id, __typename, fetchRadio, fetchPlaylist]);

  const createCurrentMedia = async () => {
    setLoadingFile(true);
    // console.log('createCurrentMedia');
    if (
      __typename === 'Playlist' &&
      playlistResponse &&
      playlistResponse.data &&
      playlistResponse.data.playlist &&
      !playlistResponse.loading
    ) {
      const playlist = playlistResponse.data.playlist;
      setCurrentData({playlist: playlist});
      setTitle(playlist.title ?? '');
      const content =
        playlist.cover &&
        (playlist.cover[0]?.content as
          | IHeroVideoCoverFragmentFragment
          | undefined);

      const extendUrlResponse = await fetchExtendUrl({
        url: playlist.hls ?? '',
      });

      let audioData = {
        id: playlist.id,
        uri: parseToHttps(extendUrlResponse.data) ?? '',
        subtitle: playlist.subtitle,
        title: playlist.title,
        poster: content?.image?.url,
        url: playlist.url,
        __typename: playlist.__typename || 'Playlist',
      };
      if (playlist.epg) {
        const programFileRespose = await fetchProgramFile({
          url: playlist.epg + `?cb=${Date.now()}`,
        });

        if (programFileRespose.data) {
          const data = programFileRespose.data;
          audioData.title = data.title ?? playlist.title;
          audioData.subtitle = data.artist ?? playlist.subtitle;
          audioData.poster = data.cover
            ? data.cover['600']
            : content?.image?.url;
        }
      }

      // programFileLoading.current = false;
      setFloatingState(() => ({
        ...state,
        isRadio: true,
        currentMedia: audioData,
      }));
      setCurrentAudio(audioData);
      setLoadingFile(false);

      // track.player.started({
      //   id: audioData.id ?? '',
      //   title: audioData.title ?? '',
      //   typename: audioData.__typename,
      //   category: audioData.subtitle || '',
      //   length: '',
      // });
      if (playlist) {
        tracker.general.contentDetailPageViewed({
          content_id: playlist.id,
          content_title: playlist.title || '',
          content_type: playlist.__typename,
          genre: playlist.parent?.name || '',
          source: (params?.source || 'browse') as RouteSource,
        });
      }
    } else if (
      __typename === 'Radio' &&
      radioResponse &&
      radioResponse.data &&
      radioResponse.data.radio &&
      !radioResponse.loading
    ) {
      const radio = radioResponse.data.radio;
      setTitle(radio.title ?? '');
      setCurrentData({radio: radio});
      if (radio.top10 && radio.top10[0] && radio.top10[0].content) {
        let topTen = radio.top10.map(item => {
          let content = item?.content as ITopTenFragmentFragment | undefined;
          return {
            title: content?.title ?? '-',
            subtitle: content?.subtitle ?? '-',
            cover: content?.thumbnail?.url ?? '-',
          };
        });
        setTopMusic({
          title: radio.top10Title ?? '-',
          description: radio.description ?? '-',
          topMusic: topTen,
        });
      }
      const content =
        radio.cover &&
        (radio.cover[0]?.content as
          | IHeroVideoCoverFragmentFragment
          | undefined);
      const extendUrlResponse = await fetchExtendUrl({
        url: radio.hls ?? '',
      });

      const audioData: IAudioSource = {
        id: radio.id,
        uri: parseToHttps(extendUrlResponse.data) ?? '',
        subtitle: radio.subtitle,
        title: radio.title,
        poster: content?.image?.url || radio.thumbnail?.url,
        url: radio.url,
        __typename: radio.__typename || 'Radio',
        epgLink: radio.epg,
      };

      // const audioData = await fetchEpg(radio, {
      //   uri: extendUrlResponse.data ?? '',
      //   poster: content?.image?.url ?? '',
      // });
      // programFileLoading.current = false;
      setCurrentAudio(audioData);
      setFloatingState(() => ({
        ...state,
        isRadio: true,
        currentMedia: audioData,
      }));
      setLoadingFile(false);

      await fetchEpg(audioData.epgLink);

      // track.player.started({
      //   id: audioData.id ?? '',
      //   title: audioData.title ?? '',
      //   typename: audioData.__typename || '',
      //   category: audioData.subtitle || '',
      //   length: '',
      // });

      if (radio) {
        tracker.general.contentDetailPageViewed({
          content_id: radio.id,
          content_title: radio.title || '',
          content_type: radio.__typename,
          genre: radio.parent?.name || '',
          source: (params?.source || 'browse') as RouteSource,
        });
      }
    }
  };

  useEffect(() => {
    createCurrentMedia();
  }, [__typename, radioResponse, playlistResponse]);

  // useEffect(() => {
  //   if (
  //     !isFetchingEpg &&
  //     currentAudio?.epgLink &&
  //     !epgData &&
  //     !timeoutRef.current
  //   ) {
  //     fetchEpg(currentAudio.epgLink);
  //   }
  // }, [isFetchingEpg, currentAudio?.epgLink, epgData]);

  const {isRouteActive} = useRouteActive();
  const isListenPage = isRouteActive([AppRoutesEnum.HOME_LISTEN]);

  useEffect(() => {
    if (!isListenPage && !isMini) {
      if (isMobile) {
        dispatch(appListenSlice.actions.closePlayer());
        dispatch(appListenSlice.actions.setMiniPlayer(false));
      } else {
        dispatch(appListenSlice.actions.setMiniPlayer(true));
      }
    }
  }, [isListenPage]);

  useEffect(() => {
    if (isMobile && isMini) {
      dispatch(appListenSlice.actions.setMiniPlayer(false));
    }
  }, [isMobile, isMini]);

  const setShowMiniPlayer = (show: boolean) => {
    if (isMobile) {
      dispatch(appListenSlice.actions.closePlayer());
      dispatch(appListenSlice.actions.setMiniPlayer(false));
      return;
    }
    dispatch(appListenSlice.actions.setMiniPlayer(show));
  };

  return (
    <div
      style={{
        position: 'fixed',
        height: !isMini ? viewportHeight : undefined,
        width: '100vw',
        zIndex: 10000,
        left: 0,
        top: isMini ? undefined : 0,
        bottom: isMini ? 0 : undefined,
        backgroundColor: 'black',
      }}>
      <PaywallChecker
        accessType={
          currentData?.radio?.accessType || currentData?.playlist?.accessType
        }
        paywall={currentData?.radio?.paywall || currentData?.playlist?.paywall}
        render={children => <div>{children}</div>}>
        {loading ? (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              },
            ]}>
            <CustomActivityIndicator />
          </View>
        ) : (
          <View
            style={{
              height: '100%',
              width: '100%',
            }}>
            {currentAudio ? (
              <>
                {/* @ts-ignore */}
                <ListenPlayer
                  onBuffering={isBuffering => {
                    if (isBuffering) {
                      console.log('clearTimeout');
                      timeoutRef.current && clearTimeout(timeoutRef.current);
                    } else {
                      fetchEpg();
                    }
                  }}
                  onPressMiniPlayer={() => {
                    setShowMiniPlayer(!isMini);
                  }}
                  showMiniPlayer={isMini}
                  radioTitle={title}
                  onAudioLoad={() => {
                    // setPaused(false);
                  }}
                  onClose={() => {
                    dispatch(appListenSlice.actions.closePlayer());
                  }}
                  onShare={async () => {
                    if (currentAudio.url) {
                      shareContent.share({
                        __typename: currentAudio.__typename as RouteTypename,
                        url: currentAudio.url,
                        message: currentAudio.title,
                        id: currentAudio.id,
                        category:
                          currentData.playlist?.parent?.name ||
                          currentData.radio?.parent?.name,
                        cover: currentAudio.poster || '',
                        description: currentAudio.description || '',
                      });
                      setIsOpenDialogShare(true);

                      const link = getLinkToShare({
                        param: {
                          id: currentAudio.id,
                          __typename: currentAudio.__typename,
                        } as any,
                        path: AppRoutesEnum.LISTEN_REDIRECTION,
                      });
                      setLinkToShare(link);
                      setIsOpenDialogShare(true);
                    }
                  }}
                  onFavoris={() => {
                    if (currentAudio.url && typeNameRef.current) {
                      const bookMarkData = findBookmarkItem(
                        currentAudio.id || '',
                      );
                      updateBookMark(
                        currentAudio.id,
                        currentAudio.url ?? '',
                        !bookMarkData,
                      );
                    }
                  }}
                  epgData={
                    epgData || {
                      title: currentAudio.title,
                      subtitle: currentAudio.subtitle,
                      customPoster: {
                        uri:
                          currentAudio.poster ||
                          // @ts-ignore
                          currentData?.radio?.cover?.[0]?.content?.image?.url ||
                          currentData?.radio?.thumbnail?.url ||
                          // @ts-ignore
                          currentData?.playlist?.cover?.[0]?.content?.image
                            ?.url,
                      },
                    }
                  }
                  sourceList={[currentAudio]}
                  onAudioPlay={(item, _isPaused) => {
                    if (item) {
                      setFloatingState(() => ({
                        ...state,
                        isRadio: true,
                        currentMedia: item,
                      }));
                    }
                    if (_isPaused) {
                      // console.log('clearTimeout');
                      timeoutRef.current && clearTimeout(timeoutRef.current);
                      // track.player.stopped({
                      //   id: item.id ?? '',
                      //   title: item.title ?? '',
                      //   typename: item.__typename || '',
                      //   category: item.subtitle || '',
                      //   length: '',
                      // });
                    } else {
                      fetchEpg();
                      // track.player.started({
                      //   id: item.id ?? '',
                      //   title: item.title ?? '',
                      //   typename: item.__typename || '',
                      //   category: item.subtitle || '',
                      //   length: '',
                      // });
                    }
                  }}
                  // paused={paused}
                  isCurrentChannelLiked={isBookmarked(currentAudio?.id || '')}
                  sideContent={
                    <SuspenseAfterRequired required={!!topMusic}>
                      {!!topMusic && (
                        <View
                          style={{
                            paddingHorizontal: Metrics.horizontalScale(16),
                            marginBottom: Metrics.verticalScale(20),
                          }}>
                          <ListenPlayList
                            title={topMusic.title}
                            description={
                              !isMobile ? undefined : topMusic.description
                            }
                            data={topMusic.topMusic}
                            variant={isMobile ? 'primary' : 'transparent'}
                            renderItem={(item: any, index) => {
                              return (
                                <View
                                  style={{
                                    marginVertical: Metrics.verticalScale(16),
                                  }}>
                                  <TopListenCard
                                    title={item.title ?? '-'}
                                    cover={{
                                      uri: `${item.cover}`,
                                    }}
                                    description={item.subtitle}
                                    number={`${index + 1}`}
                                    flexDirection="row"
                                  />
                                </View>
                              );
                            }}
                          />
                        </View>
                      )}
                    </SuspenseAfterRequired>
                  }
                />
              </>
            ) : (
              <ContentEmpty
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                title={t('Error')}
              />
            )}
          </View>
        )}
      </PaywallChecker>
      {!isMini && (
        <style
          dangerouslySetInnerHTML={{
            __html: 'body {overflow: hidden;}',
          }}></style>
      )}

      <ShareDialog
        isOpen={isOpenDialogShare}
        onClose={() => setIsOpenDialogShare(false)}
        link={linkToShare}
      />
    </div>
  );
};

export default RadioPlayerPage;
