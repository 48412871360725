import {AppRoutesEnum} from '@src/Constant/routes';
import {pathToRegexp} from 'path-to-regexp';

const drawerRoutes: string[] = [
  AppRoutesEnum.PROFILE_HOME,
  AppRoutesEnum.PROFILE_MY_PROFILE,
  AppRoutesEnum.PROFILE_SUBSCRIPTION,
  AppRoutesEnum.PROFILE_SETTINGS,
  AppRoutesEnum.PROFILE_LEGAL_INFORMATION,
  AppRoutesEnum.PROFILE_HELPDESK,
  AppRoutesEnum.PROFILE_ZENDESK_FORM,
  AppRoutesEnum.MORE,
  AppRoutesEnum.CHOOSE_PLAN,
  AppRoutesEnum.PROMOCODE,
];

export const isDrawerRoute = (path: string): boolean => {
  if (!drawerRoutes.length) {
    return false;
  }
  const drawerRouteRegex = pathToRegexp(drawerRoutes, []);
  return drawerRouteRegex.test(path);
};
