/* eslint-disable react-native/no-inline-styles */
import Button from 'design-system/src/Components/Button/Button';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode} from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';

export interface TestScreenContainerProps {
  children: React.ReactNode;
  buttonTitle?: string;
  onPressButton?(): void;
  loading?: boolean;
  noPadding?: boolean;
  buttonDisabled?: boolean;
  customFooter?: () => ReactNode;
  isNugget?: boolean;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}

export default function TestScreenContainer(props: TestScreenContainerProps) {
  const {
    buttonTitle,
    children,
    onPressButton,
    loading,
    noPadding,
    buttonDisabled,
    customFooter,
    isNugget,
    style,
    containerStyle,
  } = props;
  const {t} = useTranslation('translation');
  const {isMobile} = useBreakpoint();

  const buttonHeight = isNugget && !isMobile ? 73 : 140;

  return (
    <View
      style={[
        {
          height: '100%',
          flex: 1,
        },
        style,
      ]}>
      {isNugget ? (
        <View
          style={[
            {
              paddingTop: isMobile ? 108 : 88,
              paddingHorizontal: isMobile ? 24 : 60,
              flex: 1,
            },
            containerStyle,
          ]}>
          {children}
        </View>
      ) : (
        <View style={containerStyle}>
          {noPadding ? (
            children
          ) : (
            <View
              style={{
                paddingTop: isMobile ? 108 : 88,
                paddingHorizontal: isMobile ? 24 : 60,
              }}>
              {children}
            </View>
          )}
          {/* <View style={{height: buttonHeight}} /> */}
        </View>
      )}

      <View
        style={[
          // @ts-ignore
          {
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            backgroundColor: isNugget && !isMobile ? '#1B1C21' : undefined,
            height: buttonHeight,
            zIndex: 2,
            paddingHorizontal: Metrics.horizontalScale(24),
            alignItems: isMobile ? undefined : 'center',
          },
        ]}>
        {!isNugget ? (
          <LinearGradient
            useAngle={true}
            angle={178.4}
            locations={[0, 0.2673]}
            colors={['rgba(17, 18, 22, 0)', '#111216']}
            style={{
              position: 'absolute',
              width: '100%',
              backgroundColor: 'transparent',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        ) : null}
        {buttonTitle && (
          <View
            style={{
              minWidth: isMobile ? undefined : 327,
            }}>
            <Button
            /* i18next-extract-disable-next-line */
              title={t(buttonTitle)}
              style={{
                marginVertical: isNugget && !isMobile ? 16 : 36,
              }}
              fullWidth={true}
              variant={buttonDisabled ? 'disabled' : 'contained'}
              loading={loading}
              size="medium"
              onPress={() => {
                if (!buttonDisabled) {
                  onPressButton && onPressButton();
                }
              }}
            />
          </View>
        )}
        {customFooter && customFooter()}
      </View>
    </View>
  );
}
