import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {IUmbracoRouteParams} from '~Umbraco/Interface/Umbraco.interface';
import {getAppRoutePrefix} from './navigationUtils';
import {compile} from 'path-to-regexp';
import {AppRoutesEnum} from '@src/Constant/routes';
import queryString from 'query-string';

const useGetLinkToShare = () => {
  const getLinkToShare = ({
    param,
    path,
  }: {
    path?: AppRoutesEnum;
    param: IUmbracoRouteParams;
  }) => {
    const screen = path ?? new RouteFactory(param, {}).screen;

    const to = screen.startsWith('/app/')
      ? screen.replace('/app/', '/')
      : screen;

    const appRoutePrefix = getAppRoutePrefix();

    const pathTo = compile(to + '')(param || {});

    const finalRoute = queryString.stringifyUrl({
      url: `${appRoutePrefix}${pathTo}`,
      query: {
        ...param,
        id: pathTo.indexOf(param.id) >= 0 ? undefined : param.id,
      },
    });

    const formattedLink = finalRoute.replace('/app/app/', '/app/');

    const baseUrl = `${window.location.protocol}//${window.location.host}`;

    const fullUrl = `${baseUrl}${formattedLink}`;

    return fullUrl;
  };

  return {getLinkToShare};
};

export default useGetLinkToShare;
