/* eslint-disable react-native/no-inline-styles */
import React, {CSSProperties, useMemo, useState} from 'react';
import {TraceLogo} from 'design-system/src/assets/ImageSvg';
import {_DefaultHeaderRoot} from './DefaultHeader.styled';
import {Pressable} from 'react-native';
import Typography from 'design-system/src/Components/Typography/Typography';
import {useLanguages} from '@src/Store/Slices/LanguageSlice/Language.selector';
import Icon from 'design-system/src/Components/Icon/Icon';
import DropDown from 'design-system/src/WebComponent/Dropdown/Dropdown';
import useChangeLanguage from '@src/Hooks/useChangeLanguage';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useDefaultTheme from 'design-system/src/Theme/useTheme';
import Button from 'design-system/src/Components/Button/Button';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import PressableCustom from '@src/components/general/PressableCustom/PressableCustom';
import SuspenseAfterRequired from 'design-system/src/library/AfterRequired/SuspenseAfterRequired';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import MenuActionHeader from '@src/layout/AppLayout/AppHeader/MenuActionHeader';
import useIsLoggedIn from '@src/Providers/AuthProvider/useIsLoggedIn';
import useNavigation from '@src/Navigation/useNavigation';

const ModalAuthentication = React.lazy(
  () => import('@src/App/components/ModalAuthentication/ModalAuthentication'),
);
const DefaultMenuDrawer = React.lazy(
  () => import('./DefaultMenuDrawer/DefaultMenuDrawer'),
);

interface IDefaultHeaderProps {
  hideLogo?: boolean;
  isLangueSwitcher?: boolean;
  style?: CSSProperties;
}

const DefaultHeader = (props: IDefaultHeaderProps) => {
  const {hideLogo, style} = props;

  const openAuthenticationModal = useAppSelector(
    state => state.appAuth.openAuthenticationModal,
  );
  const {isLoggedIn, isLoading} = useIsLoggedIn();
  const rootNavigation = useNavigation();

  const dispatch = useAppDispatch();

  const {isMobile} = useBreakpoint();

  const languages = useLanguages();
  const defaultTheme = useDefaultTheme();
  const {currentLanguage, updateStoreLang} = useChangeLanguage();
  const [loadingLanguage, setLoadingLanguage] = useState<string>('');
  const {t} = useTranslation(['language', 'auth']);
  const selectedLanguage = currentLanguage;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const data: Array<{
    value: string;
    label: string;
  }> = useMemo(
    () =>
      languages.map(lang => ({value: lang.isoCode, label: lang.cultureName})),
    [languages],
  );

  const selectedItem =
    data.find(el => el.value === selectedLanguage) || data[0];
  const handleChooseLanguage = (lang: string) => async () => {
    setLoadingLanguage(lang);
    await updateStoreLang(lang);
    setLoadingLanguage('');
  };

  return (
    <>
      <_DefaultHeaderRoot style={style}>
        {!hideLogo && (
          <div className="logo">
            <TraceLogo width={136.6} height={24} />
          </div>
        )}
        <div
          className="lang-switcher"
          style={{
            display: 'inline-flex',
            gap: 24,
            alignItems: 'center',
            // gap: 24
          }}>
          {!isMobile ? (
            <>
              <PressableCustom
                onPress={() => {
                  dispatch(appAuthSlice.actions.setAuthType('register'));
                  dispatch(
                    appAuthSlice.actions.setAuthStep({
                      page: 'first',
                      step: 0,
                    }),
                  );
                  dispatch(
                    appAuthSlice.actions.openAuthenticationModal({
                      open: true,
                      redirect: `/app`,
                    }),
                  );
                }}>
                {({hover}) => (
                  <Typography
                    color={hover ? 'orange' : 'white'}
                    variant="button3">
                    {t('Sign up', {ns: 'auth'})}
                  </Typography>
                )}
              </PressableCustom>
              <Button
                title={t('Sign in', {ns: 'auth'})}
                variant="contained"
                disabled={isLoading}
                size="small"
                onPress={() => {
                  if (isLoggedIn) {
                    rootNavigation.navigate('/app');
                  } else {
                    dispatch(appAuthSlice.actions.setAuthType('login'));
                    dispatch(
                      appAuthSlice.actions.openAuthenticationModal({
                        open: true,
                        redirect: `/app`,
                      }),
                    );
                  }
                }}
              />
            </>
          ) : (
            <MenuActionHeader
              iconName="profil"
              onClick={() => {
                dispatch(appAuthSlice.actions.setAuthType('login'));
                dispatch(
                  appAuthSlice.actions.openAuthenticationModal({
                    open: true,
                    redirect: `/app`,
                  }),
                );
              }}
            />
          )}
          <PressableCustom onPress={toggleDrawer}>
            {({hover}) => (
              <Icon
                name="menu"
                color={hover ? defaultTheme.primary : undefined}
              />
            )}
          </PressableCustom>
        </div>

        <SuspenseAfterRequired required={openAuthenticationModal?.open}>
          <ModalAuthentication
            isOpen={!!openAuthenticationModal?.open}
            onClose={() => {
              // openAuthenticationModal?.onClose?.();
              dispatch(
                appAuthSlice.actions.openAuthenticationModal({open: false}),
              );
            }}
          />
        </SuspenseAfterRequired>
      </_DefaultHeaderRoot>
      <SuspenseAfterRequired required={isOpen}>
        <DefaultMenuDrawer
          open={isOpen}
          onClose={toggleDrawer}
          leftHeader={
            <DropDown
              // trigger="hover"
              placement="bottomLeft"
              content={
                <>
                  {data.map(item => {
                    const isActive = selectedItem?.value === item.value;
                    return (
                      <Pressable
                        key={item.value}
                        disabled={isActive}
                        onPress={handleChooseLanguage(item.value)}
                        style={{
                          flexWrap: 'nowrap',
                          flexDirection: 'row',
                          // @ts-ignore
                          gap: 8,
                        }}>
                        <Typography
                          variant="body3"
                          color={
                            (isActive && (() => defaultTheme.primary)) ||
                            undefined
                          }>
                          {/* i18next-extract-disable-next-line */}
                          {t(item.value, '') || item.label}
                        </Typography>
                        {loadingLanguage === item.value ? (
                          <CustomActivityIndicator />
                        ) : (
                          isActive && (
                            <Icon name="check" color={defaultTheme.primary} />
                          )
                        )}
                      </Pressable>
                    );
                  })}
                </>
              }>
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  cursor: 'pointer',
                  gap: 8,
                }}>
                <Typography
                  variant="body3"
                  style={{
                    height: 'fit-content',
                  }}>
                  {/* i18next-extract-disable-next-line */}
                  {t(selectedLanguage, '') || selectedItem?.label}
                </Typography>
                <Icon name={'arrow-down'} size="16" color="white" />
              </div>
            </DropDown>
          }
        />
      </SuspenseAfterRequired>

      <style
        dangerouslySetInnerHTML={{
          __html: `body {overflow: ${openAuthenticationModal?.open ? 'hidden' : 'auto!important'};}`,
        }}
      />
    </>
  );
};

export default DefaultHeader;
