import {useCallback, useEffect, useState} from 'react';
import {VideoProperties} from 'react-native-video';

export function useChannel<
  T extends {
    uri: string | (() => Promise<string>);
    castSource?: (
      cb: (isMp4: boolean, source: string) => void,
    ) => Promise<void>;
  },
>(sourceList: Array<T>, initialMedia: number, cb?: Function) {
  const [currentMedia, setCurrentMedia] = useState(initialMedia);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSource, setCurrentSource] = useState<
    Record<string, VideoProperties['source'] & {description: string}>
  >({});

  useEffect(() => {
    if (initialMedia !== currentMedia) {
      setCurrentMedia(initialMedia);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMedia]);

  useEffect(() => {
    if (!currentSource[currentMedia]) {
      loadResource(currentMedia);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMedia]);

  const goToChannel = useCallback(
    async (channel: number) => {
      if (sourceList[channel]) {
        setCurrentMedia(channel);
        cb && cb();
      }
    },
    [cb, sourceList],
  );
  const channelUp = useCallback(
    (_cb?: (newChannel: number) => void) => {
      if (currentMedia < sourceList.length) {
        const updatedIndex = (currentMedia + 1) % sourceList.length;
        if (!currentSource[updatedIndex]) {
          setIsLoading(true);
        }
        goToChannel(updatedIndex);
        _cb && _cb(updatedIndex);
      }
    },
    [currentMedia, goToChannel, sourceList.length],
  );

  const channelDown = useCallback(
    (_cb?: (newChannel: number) => void) => {
      if (currentMedia > 0) {
        const updatedIndex =
          (currentMedia + sourceList.length - 1) % sourceList.length;
        if (!currentSource[updatedIndex]) {
          setIsLoading(true);
        }
        goToChannel(updatedIndex);
        _cb && _cb(updatedIndex);
      }
    },
    [currentMedia, goToChannel, sourceList.length],
  );

  const loadResource = useCallback(
    async (index: number) => {
      try {
        if (sourceList[index]) {
          const uri = sourceList[index].uri;
          if (typeof uri === 'function') {
            const response = await uri();

            if (response) {
              setCurrentSource(r => ({
                ...r,
                [index]: {
                  ...sourceList[index],
                  uri: response,
                },
              }));
            }
          } else if (typeof uri === 'string') {
            setCurrentSource(r => ({
              ...r,
              [index]: {
                ...sourceList[index],
                uri,
              },
            }));
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [sourceList],
  );
  return {
    currentMedia,
    isLoading,
    currentSource: currentSource[currentMedia],
    channelUp,
    channelDown,
    goToChannel,
    setCurrentMedia,
  };
}
