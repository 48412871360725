import {StyleSheet} from 'react-native';
import Metrics from '../../../Theme/Metrics';

export const choosePlanStyles = StyleSheet.create({
  container: {
    paddingHorizontal: Metrics.horizontalScale(16),
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: Metrics.verticalScale(21),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: Metrics.verticalScale(21),
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textButton: {
    height: Metrics.verticalScale(48),
    alignItems: 'center',
    flexDirection: 'row',
  },
  paywallButtonContainer: {
    flexDirection: 'row',
    marginTop: Metrics.verticalScale(21),
    alignItems: 'center',
    justifyContent: 'center',
  },
});
