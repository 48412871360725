import React, {useMemo} from 'react';
import GenericCard from '../../GenericCard/GenericCard';
import RowCard from '../../RowCard/RowCard.web';
import ListenCardAside from '../ListenCardAside/ListenCardAside';
import {ListenCardProps} from '../ListenCardProps';
import useBreakpoint from '../../../../WebComponent/Grid/hooks/useBreakpoint';
import {CardSize} from '../../../../tools/CardSizeTools';

const ListenRowCard = (
  props: ListenCardProps & {onPress?(): void; fullWidth?: boolean},
) => {
  const {isMobile} = useBreakpoint();

  const type = useMemo(() => {
    if (props.type) {
      return props.type;
    } else {
      let typeTemp: CardSize = props.isCategoryCard ? 'small' : 'xsmall';
      if (!isMobile) {
        typeTemp = `${type}_w` as CardSize;
      }
      return typeTemp;
    }
  }, [props.type, isMobile, props.isCategoryCard]);

  const aspectRatio = isMobile ? 109 / 61 : 247 / 137.5;

  return (
    <RowCard
      flexDirection={props.flexDirection}
      left={
        <GenericCard
          style={
            isMobile
              ? {
                  maxWidth: '30%',
                }
              : {}
          }
          onPress={props.onPress}
          coverImage={props.cover}
          type={type}
          fullWidth={props.fullWidth}
          aspectRatio={aspectRatio}
        />
      }
      right={
        <ListenCardAside asideProps={props} isTrailingIconCentered={false} />
      }
    />
  );
};

export default ListenRowCard;
