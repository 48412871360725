import apiConfig from '@src/Configs/apiConfig';

export const generateAnonymousUserId = () => {
  return `anon_${Math.random().toString(36).substring(2, 11)}`;
};

const useBraze = () => {
  const initBraze = () => {
    if (typeof window !== 'undefined') {
      import('./braze-exports').then(
        ({initialize, automaticallyShowInAppMessages}) => {
          initialize(apiConfig.brazeApiKey, {
            baseUrl: apiConfig.brazeBaseUrl,
            serviceWorkerLocation: '/sw.js',
          });

          automaticallyShowInAppMessages();
        },
      );
    }
  };

  const openBrazeSession = (userId?: string): Promise<void> => {
    if (typeof window !== 'undefined') {
      return new Promise((resolve, reject) => {
        // Initialize session for anonymous user
        const sessionUserId = userId || generateAnonymousUserId();

        import('./braze-exports')
          .then(
            ({
              changeUser,
              openSession,
              isPushSupported,
              requestPushPermission,
            }) => {
              changeUser(sessionUserId);
              openSession();
              setTimeout(() => {
                if (isPushSupported()) {
                  console.log('isPushSupported---true');
                  requestPushPermission(
                    (endpoint, publicKey, userAuth) => {
                      console.log('requestPushPermission---endpoint', endpoint);
                      console.log(
                        'requestPushPermission---publicKey',
                        publicKey,
                      );
                      console.log('requestPushPermission---userAuth', userAuth);
                      resolve();
                    },
                    () => {
                      console.log('requestPushPermission---denied');
                      reject();
                    },
                  );
                } else {
                  console.log('isPushSupported---false');
                  reject();
                }
              }, 300);
            },
          )
          .catch(error => {
            console.error('Error initializing Braze session', error);
            reject(error);
          });
      });
    }
    return Promise.resolve();
  };
  return {
    initBraze,
    openBrazeSession,
  };
};

export default useBraze;
