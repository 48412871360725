// import useMember, {useSelecteSubscription} from '~Store/selector/useMember';
import {useDispatch} from 'react-redux';
import memberSlice from '~Store/Slices/Member.slice';
import {useLazyUpdateUserSubscriptionQuery} from '~Api/TraceApi/traceApi';
import apiConfig from '~Configs/apiConfig';
import {useSelectAllSubscriptions} from '~Store/Slices/Subscription.slice';
import {SubscriptionBody} from '~Api/TraceApi/interface/umbraco.interface';
import useMember, {
  useSelecteSubscription,
} from '@src/Store/selectors/useMember';

export type DeviceType = 'iOS' | 'Android' | 'Huawei' | 'Web';

export const useHandleSubscription = () => {
  const member = useMember();
  const dispatch = useDispatch();
  const [updateUserSubscription] = useLazyUpdateUserSubscriptionQuery();
  const allSubscription = useSelectAllSubscriptions();
  const memberSubscription = useSelecteSubscription();

  const updateSubscription = async (
    subscriptionId: string,
    subscriptionStart: string,
    subscriptionEnd: string,
    productID: string,
    transactionId: string,
    purchaseToken: string,
  ) => {
    if (member && memberSubscription) {
      //  NetInfo.fetch().then(async state => {

      // if (state.isInternetReachable) {

      let deviceType: DeviceType = 'Web';

      let variables: SubscriptionBody = {
        subscriptionTypeId: subscriptionId,
        subscriptionStart,
        subscriptionEnd,
        subscriptionPrice: '',
        subscriptionFrequency:
          productID.indexOf('month') >= 0
            ? 'monthly'
            : productID.indexOf('year') >= 0
              ? 'yearly'
              : '',
        externalID: productID,
        deviceType,
        transactionId: transactionId,
        purchaseToken: purchaseToken,
      };
      let response = await updateUserSubscription(variables);
      if (response.error) {
        console.log('error update subscription', response.error);
      }
      let data = response.data;
      if (data) {
        dispatch(
          memberSlice.actions.setMemberSubscription({
            memberId: data.memberId,
            subscriptionStart: data.subscriptionStart.toString(),
            id: data._id,
            deviceType: data.deviceType,
            subscriptionType: subscriptionId,
            productId: data.externalID,
          }),
        );
      }
      // } else {
      //   console.log('internet not available');
      // }
      // }
      //);
    } else {
      console.log(' user member is null');
    }
  };

  const updateToFreemiumSubscription = async (deviceType?: DeviceType) => {
    const device: DeviceType = 'Web';

    if (memberSubscription) {
      let free = allSubscription.find(
        subscription => subscription.productID === apiConfig.freemiumProductId,
      );
      if (free) {
        let response = await updateUserSubscription({
          subscriptionTypeId: free.id,
          subscriptionStart: new Date().toISOString(),
          subscriptionEnd: new Date().toISOString(),
          subscriptionPrice: '',
          subscriptionFrequency: 'Free',
          externalID: apiConfig.freemiumProductId,
          deviceType: device,
          transactionId: '',
          purchaseToken: '',
        });
        let data = response.data;
        if (data) {
          dispatch(
            memberSlice.actions.setMemberSubscription({
              memberId: data.memberId,
              subscriptionStart: data.subscriptionStart.toString(),
              id: data._id,
              deviceType: data.deviceType,
              subscriptionType: free.id,
              productId: data.externalID,
            }),
          );
        }
        if (response.error) {
          console.log('error update freemium', response.error);
          dispatch(memberSlice.actions.setMemberSubscription(undefined));
        }
      }
    }
  };

  return {updateSubscription, updateToFreemiumSubscription};
};
