// import {NavigationProp} from '@react-navigation/native';
import {AnyAction, Dispatch} from '@reduxjs/toolkit';
import {compile, pathToRegexp} from 'path-to-regexp';
import {RouteTypename} from '~Navigation/type';
import {IUmbracoRouteParams} from '~Umbraco/Interface/Umbraco.interface';
import {navigate as gatsbyNavigate} from 'gatsby';
import {AppRoutesEnum} from '@src/Constant/routes';
import {getActiveRegionPage} from '@src/Store/selectors/useRegionUrl';
import {getContentEndUrl, getEndUrls} from './getContentEndUrl';

const routes: Record<string, AppRoutesEnum> = {
  '/:region/:homepage/': AppRoutesEnum.HOME,

  '/:region/:homepage/watch-home/': AppRoutesEnum.HOME_WATCH,

  '/:region/:homepage/watch-home/section-on-demand/': AppRoutesEnum.VOD_HOME,

  '/:region/:homepage/watch-home/section-on-demand/:category/':
    AppRoutesEnum.VOD_CATEGORY,
  '/contents/watch/:category/': AppRoutesEnum.VOD_SINGLE,

  '/:region/:homepage/watch-home/section-live-tv/': AppRoutesEnum.LIVE_TV,

  '/:region/:homepage/watch-home/section-live-events/':
    AppRoutesEnum.LIVE_EVENTS,
  // listen
  '/:region/:homepage/listen-home/': AppRoutesEnum.HOME_LISTEN,

  '/:region/:homepage/listen-home/section-radio': AppRoutesEnum.RADIO_HOME,
  '/:region/:homepage/listen-home/radio': AppRoutesEnum.RADIO_HOME,

  '/:region/:homepage/listen-home/section-radio/:category/':
    AppRoutesEnum.RADIO_CATEGORY,
  '/:region/:homepage/listen-home/radio/:category/':
    AppRoutesEnum.RADIO_CATEGORY,

  '/:region/:homepage/listen-home/section-podcasts/:category/':
    AppRoutesEnum.PODCAST_CATEGORY,

  '/:region/:homepage/listen-home/section-podcasts/':
    AppRoutesEnum.PODCAST_HOME,
  '/:region/:homepage/listen-home/podcast/': AppRoutesEnum.PODCAST_HOME,

  '/:region/:homepage/pressroom/news/': AppRoutesEnum.NEWS_HOME,

  '/:region/:homepage/pressroom/news/:name/': AppRoutesEnum.NEWS_SINGLE,

  //   Helpdesk
  '/:region/:homepage/helpdesk/': AppRoutesEnum.DynamicPage,

  '/:region/:homepage/helpdesk/language-and-regions/':
    AppRoutesEnum.DynamicPage,
  '/:region/:homepage/helpdesk/certificates-and-courses/':
    AppRoutesEnum.DynamicPage,
  '/:region/:homepage/helpdesk/job-board/': AppRoutesEnum.DynamicPage,
  '/:region/:homepage/helpdesk/artists-services/': AppRoutesEnum.DynamicPage,
  '/:region/:homepage/helpdesk/settings-and-preferences/':
    AppRoutesEnum.DynamicPage,

  //
  '/:region/:homepage/artist-services/:service/': AppRoutesEnum.ARTIST_SERVICE,
  '/:region/:homepage/artist-services/': AppRoutesEnum.ARTIST_SERVICE_HOME,

  // content
  '/contents/': AppRoutesEnum.DynamicPage,
  '/contents/watch/': AppRoutesEnum.DynamicPage,
  '/contents/watch/collection-test/': AppRoutesEnum.DynamicPage,
  '/contents/channels/': AppRoutesEnum.DynamicPage,
  '/contents/sub-content/partners/': AppRoutesEnum.DynamicPage,
};

const findRouteName = (url: string, id: string): AppRoutesEnum | null => {
  let routeName: AppRoutesEnum | null = null;
  let finalUrl = url;
  if (id) {
    const regionPages = getActiveRegionPage();
    const {home, watch, listen, learn} = regionPages;
    if (home && home.id === id) {
      return AppRoutesEnum.HOME;
    }
    if (watch) {
      if (watch.id === id) {
        return AppRoutesEnum.HOME_WATCH;
      } else {
        const {vod, liveEvent, liveTv} = watch.children;
        if (vod && vod.id === id) {
          return AppRoutesEnum.VOD_HOME;
        } else if (liveEvent && liveEvent.id === id) {
          return AppRoutesEnum.LIVE_EVENTS;
        } else if (liveTv && liveTv.id === id) {
          return AppRoutesEnum.LIVE_TV;
        }
      }
    }
    if (listen) {
      if (listen.id === id) {
        return AppRoutesEnum.HOME_LISTEN;
      } else {
        const {podcast, radio} = listen.children;
        if (radio && radio.id === id) {
          return AppRoutesEnum.RADIO_HOME;
        } else if (podcast && podcast.id === id) {
          return AppRoutesEnum.PODCAST_HOME;
        }
      }
    }

    if (learn && learn.id === id) {
      return AppRoutesEnum.HOME_LEARN;
    }
  }

  for (const route of Object.keys(routes)) {
    const regex = pathToRegexp(route, []);
    if (regex.test(finalUrl)) {
      routeName = routes[route];
      break;
    }
  }
  return routeName;
};

export const routesOfType: Record<
  RouteTypename,
  ((params: IUmbracoRouteParams) => any) | any
> = {
  Channel: AppRoutesEnum.VIDEO_PLAYER,
  ChannelFolder: AppRoutesEnum.DynamicPage,
  CategoryPage: AppRoutesEnum.VOD_CATEGORY,
  Collection: AppRoutesEnum.VOD_COLLECTION,
  VODItem: AppRoutesEnum.VOD_SINGLE,
  WatchCateg: AppRoutesEnum.VOD_CATEGORY,
  ContentEditorFolder: AppRoutesEnum.DynamicPage,
  HelpdeskPage: AppRoutesEnum.DynamicPage,
  HomePage: AppRoutesEnum.HOME,
  ListenCateg: AppRoutesEnum.HOME_LISTEN,
  LiveEvent: AppRoutesEnum.LIVE_EVENTS_ITEM,
  LiveEventsPage: AppRoutesEnum.LIVE_EVENTS,
  LiveTVPage: AppRoutesEnum.LIVE_TV,
  News: AppRoutesEnum.NEWS_SINGLE,
  NewsFolder: AppRoutesEnum.NEWS_HOME,
  NewsPage: AppRoutesEnum.NEWS_HOME,
  Partner: AppRoutesEnum.PARTNER_SINGLE,
  Partners: AppRoutesEnum.DynamicPage,
  Playlist: AppRoutesEnum.RADIO_PLAYER,
  Podcast: AppRoutesEnum.PODCAST_SINGLE,
  PodcastItem: AppRoutesEnum.PODCAST_PLAYER, // player
  PodcastCateg: AppRoutesEnum.PODCAST_CATEGORY,
  PodcastMenu: AppRoutesEnum.PODCAST_HOME,
  Radio: AppRoutesEnum.RADIO_PLAYER,
  RadioCateg: AppRoutesEnum.RADIO_CATEGORY,
  SectionPage: (params: {url: any; id: any}) => {
    return params && (params.url || params.id)
      ? findRouteName(params.url, params.id) || 'DynamicPage'
      : 'DynamicPage';
  },
  Unknown: undefined,
  Region: undefined,
  Settings: undefined,
  CMSPage: undefined,
  FormPage: AppRoutesEnum.FORMS,
  ArtistService: AppRoutesEnum.ARTIST_SERVICE,
  ArtistServicesPage: AppRoutesEnum.ARTIST_SERVICE_HOME,
  Paywall: AppRoutesEnum.PAYWALL,
  PaywallFolder: AppRoutesEnum.PAYWALL,
  MemberContentTypesLink: undefined,
  MemberDatas: undefined,
  MembersDatas: undefined,
  MemberDownloaded: undefined,
  MemberMessage: undefined,
  MemberNotify: undefined,
  MemberSaved: undefined,
  MemberWatched: undefined,
  MemberOpportunity: AppRoutesEnum.COURSE_UNIT,
  Opportunity: AppRoutesEnum.OPPORTUNITY_SINGLE,
  ExternalLink: undefined,
  LogoLink: undefined,
  Subscription: undefined,
  SubscriptionsFolder: undefined,
  MemberSearch: undefined,
  HelpdeskFolder: AppRoutesEnum.PROFILE_HELPDESK,
  NewsCateg: AppRoutesEnum.NEWS_HOME,
  TranslationContext: undefined,
  TranslationFolder: undefined,
  TranslationItem: undefined,
  ArtistServiceFolder: AppRoutesEnum.ARTIST_SERVICE_HOME,
  CategBgColor: undefined,
  ContentTag: undefined,
  ContentTags: undefined,
  ContentTagsCateg: undefined,
  LiveEventsMenu: AppRoutesEnum.LIVE_EVENTS,
  PlaylistRadioMenu: AppRoutesEnum.RADIO_HOME,
  Space: undefined,
  VODMenu: AppRoutesEnum.VOD_HOME,
  SubscriptionData: undefined,
  SubscriptionDataFolder: undefined,
  FormFolder: undefined,
  ThankYouPage: '/thank-you/:id',
  ChoiceNugget: undefined,
  Course: AppRoutesEnum.COURSE_SINGLE,
  CourseCategoriesFolder: undefined,
  CourseCategory: AppRoutesEnum.COURSE_CATEGORY,
  CourseExam: undefined,
  CourseTestProgression: undefined,
  CourseUnit: undefined,
  Discovery: undefined,
  DiscoverySlide1: undefined,
  GapfillNugget: undefined,
  JobBoardPage: AppRoutesEnum.JOB_BOARD_PAGE,
  JobHub: AppRoutesEnum.JOB_HUB,
  JobHubsFolder: undefined,
  JobTraining: AppRoutesEnum.SINGLE_JOB,
  JobTrainingFolder: undefined,
  JobVacanciesFolder: undefined,
  JobVacancy: undefined,
  LearnMenu: undefined,
  OpportunityFolder: undefined,
  PartnersPage: AppRoutesEnum.PARTNERS_LANDING,
  PersonalityPages: AppRoutesEnum.PERSONALITY,
  PersonalityQuiz: undefined,
  UnitNuggetAnswer: undefined,
  UnitNuggetGapFillAnswer: undefined,
  UnitProgression: undefined,
  AwardCategoryPage: '/awards/category/:id',
  AwardsArtist: undefined,
  AwardsHomepage: undefined,
  CourseStatus: undefined,
  MiniSitesFolder: undefined,
  AwardVoting: '/awards/voting/:id',
  AwardsCategoryMenu: undefined,
  AwardsCategoryMenuDev: undefined,
  AwardsCategoryMenuFolder: undefined,
  AwardsCategoryMenuFolderDev: undefined,
  CourseShare: undefined,
  EntrepreunarialHub: AppRoutesEnum.ENTREPRENEURIAL_HUB,
  JobProgress: undefined,
  JobShare: undefined,
  LandingPage: undefined,
  LegalsFolder: undefined,
  NavMessage: undefined,
  NavigationMessagesFolder: undefined,
  NetlifyDeploy: undefined,
  ObtainedJob: undefined,
  OpportunityCenter: AppRoutesEnum.OPPORTUNITY_CENTER,
  PersonalityQuiz1: undefined,
  PersonalityType: undefined,
  QuizPage: AppRoutesEnum.HOME_QUIZ,
  SumaryNugget: undefined,
  UmbracoTest: undefined,
  VotingFolder: undefined,
  VotingRecord: undefined,
  PromoCode: undefined,
  PromoCodeFolder: undefined,
  QuizzSurveyCollection: AppRoutesEnum.QUIZZ_SURVEY_COLLECTION,
  QuizzSurvey: AppRoutesEnum.PLAYER_QUIZ_SURVEY,
  QuizzSurveyCategory: AppRoutesEnum.QUIZZ_SURVEY_CATEGORY,
  TextPage: '/text-page/:endUrl',
  HelpdeskCateg: '/faq/:endUrl',
  SurveyQuizzFolder: undefined,
};

export const getTabRoute = (route: string): any | undefined => {
  return;
};

export class RouteFactory<T extends IUmbracoRouteParams> {
  screen: any | null = null;
  routeParams: T;
  constructor(
    private initialParams: T,
    private navigation: any,
    private dispatch?: Dispatch<AnyAction>,
    private headerTitle?: string,
  ) {
    const {url, endUrl, __typename} = this.initialParams;
    const {endUrl1, endUrl2, endUrl: endUrlFromUrl} = getEndUrls(url);
    this.routeParams = {
      ...this.initialParams,
      endUrl:
        endUrl || getContentEndUrl(url || '', __typename) || endUrlFromUrl,
      endUrl1,
      endUrl2,
    };
    this.screen = this.findRouteScreeName();
  }

  private findRouteScreeName(): any {
    const {__typename} = this.routeParams;
    let pageName = routesOfType[__typename];

    const pagePath =
      typeof pageName === 'function' ? pageName(this.routeParams) : pageName;

    if (pagePath) {
      const toPath = compile(pagePath, {encode: encodeURIComponent});
      return toPath({
        ...this.routeParams,
      });
    }
    return;
  }

  async navigate(params?: T & any, forceParentNavigation?: boolean) {
    const screenFinal = this.screen || this.findRouteScreeName();

    const {url, __typename, liveEventID, id, ...restParams} = this.routeParams;

    const locationState: any = {
      ...params,
      params: this.routeParams,
    };

    if (__typename === 'FormPage' || __typename === 'AwardVoting') {
      //
      locationState.modal = true;
    }

    if (__typename == 'LandingPage') {
      gatsbyNavigate(url);
    }

    console.log('🚀 ~ navigate-factory', __typename, url, screenFinal);
    if (screenFinal) {
      this.navigation.navigate(screenFinal, {state: locationState});
    } else if (__typename === 'ExternalLink' && url) {
      window.open(url, '_blank');
    }
  }
}
