import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {useMemo} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Pressable, ScrollView, View} from 'react-native';
import {
  IJobHubFragmentFragment,
  useAllJobHubQuery,
} from '~Api/Graphql/gql/types.generated';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import useAppNavigation from '@src/utils/useAppNavigation';
import {useAppDispatch} from '@src/Store/hooks';
import PaddingContainer from '@src/App/components/Containers/PaddingContainer/PaddingContainer';

const JobHubScreen = () => {
  const response = useAllJobHubQuery();
  const loading = response.loading;

  const {t} = useTranslation(['learn']);
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();

  const list: IJobHubFragmentFragment[] = useMemo(() => {
    const {data} = response;
    if (
      data &&
      data.allJobHub &&
      data.allJobHub.items &&
      data.allJobHub.items[0] &&
      data.allJobHub.items[0].__typename === 'JobHub'
    ) {
      let _list: IJobHubFragmentFragment[] = [];
      data.allJobHub.items.forEach(item => {
        if (item && item.__typename === 'JobHub') {
          _list.push(item);
        }
      });
      return _list;
    }
    return [];
  }, [response]);

  return (
    <View style={{flex: 1}}>
      <div>
        {loading ? (
          <CustomActivityIndicator style={{flex: 1}} />
        ) : (
          <>
            <PaddingContainer top={10} bottom={10}>
              <Typography children={t('The Job Hub')} variant={'h2'} />
            </PaddingContainer>
            <ScrollView
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                paddingHorizontal: Metrics.horizontalScale(16),
              }}>
              {list.length !== 0 &&
                list.map((item, index) => {
                  const {longDescription, thumbnail, title} = item;
                  return (
                    <Pressable
                      onPress={() => {
                        const {__typename, id, link} = item;
                        if (link) {
                          new RouteFactory(
                            {
                              url: link || '',
                              id,
                              __typename,
                            },
                            navigation,
                            dispatch,
                          ).navigate();
                        }
                      }}
                      key={`${index}-hub`}
                      style={{
                        paddingHorizontal: Metrics.horizontalScale(16),
                        paddingTop: Metrics.verticalScale(16),
                        paddingBottom: Metrics.verticalScale(34),
                        backgroundColor: '#1B1C21',
                        borderRadius: Metrics.moderateScale(8),
                        marginVertical: Metrics.verticalScale(8),
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                        }}>
                        <CustomFastImage
                          width={Metrics.horizontalScale(80)}
                          height={Metrics.horizontalScale(80)}
                          source={{
                            uri: thumbnail?.url,
                          }}
                          style={{
                            borderRadius: Metrics.horizontalScale(4),
                          }}
                        />
                        <Typography
                          children={title || ''}
                          variant="h3"
                          style={{
                            marginLeft: Metrics.horizontalScale(24),
                            flexShrink: 1,
                          }}
                        />
                      </View>
                      <Typography
                        children={longDescription || ''}
                        variant="body3"
                        color={() => '#A0A0A0'}
                        style={{
                          marginTop: Metrics.verticalScale(18),
                        }}
                      />
                    </Pressable>
                  );
                })}
            </ScrollView>
          </>
        )}
      </div>
    </View>
  );
};

export default JobHubScreen;
