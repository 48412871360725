import React from 'react';
import {View} from 'react-native';
import Metrics from '../../Theme/Metrics';
import Typography from '../Typography/Typography';

export type ServiceFormDescProps = {
  title: string;
  description?: string;
};

export default function ServiceFormDesc(props: ServiceFormDescProps) {
  const {title, description} = props;

  return (
    <View>
      <Typography style={{textAlign: 'center'}} variant="body2">
        {title}
      </Typography>
      <Typography
        style={{textAlign: 'center', marginTop: Metrics.verticalScale(14)}}
        variant="body3"
        color="gray">
        {description}
      </Typography>
    </View>
  );
}
