/* eslint-disable react-native/no-inline-styles */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Alert, Pressable, View} from 'react-native';
import Typography from '../../../Components/Typography/Typography';
import ControlPlayer, {
  ControlPlayerProps,
} from '../../../Components/Player/ControlPlayer/ControlPlayer.web';
import {listenBgOpacityVariant} from './ListenPlayer.styles';
import {OnLoadData} from 'react-native-video';
import Metrics from '../../../Theme/Metrics';
import {useChannel} from '../Hooks/useChannel.web';
import {ListenPlayListProps} from '../../Listen/ListenPlayList/ListenPlayList';
import RadioPlayerCard from '../../../Components/Player/RadioPlayerCard/RadioPlayerCard.web';
import {CustomActivityIndicator} from '../../../Components/CustomActivityIndicator';
import {CustomFastImageProps} from '../../../Components/CustomFastImage/CustomFastImageProps';
import {parseHlsResponse} from '../Hooks/useVideoPlayer';
import PodcastSlider from '../VideoPlayer/PlayerSlider/PodcastSlider';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import Icon from '../../../Components/Icon/Icon';
import {useAudioPlayer} from '../Hooks/useAudioPlayer.web';

export interface IAudioSource<T extends any = any> {
  id: string;
  description?: string;
  uri: string | (() => Promise<string>);
  type?: string;
  noView?: boolean;
  title?: string;
  subtitle?: string;
  poster?: string;
  length?: number;
  url?: string;
  __typename: string;
  name?: string;
  raw?: T;
  epgLink?: string;
}

export interface IEpgData {
  customPoster?: CustomFastImageProps['source'];
  title?: string;
  subtitle?: string;
}

export interface ListenPlayerProps<T extends any, A extends any = object>
  extends Omit<ControlPlayerProps, 'onNext' | 'onPrevious'>,
    Omit<ListenPlayListProps<T>, 'title' | 'subtitle' | 'variant'> {
  opacityVariant?: keyof typeof listenBgOpacityVariant;
  sourceList: Array<IAudioSource<A>>;
  currentChannel?: number;
  variant?: 'radio' | 'podcast';
  onAudioPlay?: (item: IAudioSource | undefined, isPaused: boolean) => void;
  onNext?: (index: number) => void;
  onPrevious?: (index: number) => void;
  onClose?: (currentTime?: number) => void;
  onTimeProgress?: (currentTime: number, seekableDuration: number) => void;
  initialTime?: number;
  onPlayEnd?: () => void;
  isCurrentChannelLiked?: boolean;
  // onSeek?: (currentTime: number, seekTime: number) => void;
  onGetDuration?: (duration: number) => void;
  playlistTitle?: string;
  playlistSubtitle?: string;
  playlistVariant?: ListenPlayListProps<T>['variant'];
  showMiniPlayer?: boolean;
  onPressMiniPlayer: () => void;
  loadSourceErrorMessage: string;
  epgData?: IEpgData;
  onAudioLoad?: (data: OnLoadData, loadTime?: number) => void;
  sideContent?: React.ReactNode;
  radioTitle?: string;
  onBuffering?: (isBuffering: boolean) => void;
}

const ListenPlayer = <T extends any, A extends any = object>(
  props: ListenPlayerProps<T, A>,
) => {
  const {
    onShare,
    onFavoris,
    onAudioPlay,
    onNext,
    onPrevious,
    // opacityVariant = 'primary',
    sourceList,
    currentChannel = 0,
    variant = 'radio',
    onTimeProgress,
    initialTime,
    onPlayEnd,
    isCurrentChannelLiked,
    onGetDuration,
    showMiniPlayer,
    onPressMiniPlayer,
    loadSourceErrorMessage,
    epgData,
    onAudioLoad,
    sideContent,
    radioTitle,
    onClose,
    onBuffering,
  } = props;

  const isRadio = variant === 'radio';

  const {isMobile} = useBreakpoint();

  const {
    currentMedia,
    currentSource,
    channelUp,
    channelDown,
    setCurrentMedia,
    isLoading: isLoadingSource,
  } = useChannel<IAudioSource>(sourceList, currentChannel);

  const {
    videoRef,
    handlePlayPause,
    loaded,
    played,
    playedSeconds,
    playing,
    duration,
    setPlaying,
    setLoaded,
    handleTimeUpdate,
    handleSliderChange,
    isLoadedMetadata,
    handleLoadedMetadata,
  } = useAudioPlayer({
    currentSource,
    initialTime,
  });

  const isLoading: boolean = isLoadingSource;
  const onEnd = useCallback(channelUp, [channelUp]);
  const timeLoadStart = useRef<number>();

  const [currentPoster, setCurrentPoster] = useState<
    CustomFastImageProps['source']
  >(epgData?.customPoster);

  useEffect(() => {
    if (currentChannel !== currentMedia) {
      // resetState();
      setCurrentMedia(currentChannel);
    }
  }, [currentChannel, currentMedia, setCurrentMedia]);

  useEffect(() => {
    if (epgData && epgData.customPoster) {
      setCurrentPoster(epgData.customPoster);
    }
  }, [epgData]);

  useEffect(() => {
    if (!isLoadingSource && !currentSource) {
      Alert.alert(loadSourceErrorMessage);
    }
  }, [isLoadingSource, currentSource, loadSourceErrorMessage]);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleWaiting = () => {
      onBuffering?.(true);
    };

    const handlePlaying = () => {
      onBuffering?.(false);
    };

    if (videoElement) {
      videoElement.addEventListener('waiting', handleWaiting);
      videoElement.addEventListener('playing', handlePlaying);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('waiting', handleWaiting);
        videoElement.removeEventListener('playing', handlePlaying);
      }
    };
  }, []);

  const handleProgress = () => {
    const video = videoRef.current;
    if (video && video.buffered.length > 0) {
      const end = video.buffered.end(video.buffered.length - 1);
      onTimeProgress && onTimeProgress(played, loaded);
      const percentage = (end / video.duration) * 100;
      setLoaded(percentage);
    }
    // handleOnCustomProgress
  };

  const handleLoad = useCallback(
    (data: any) => {
      const timeStart = timeLoadStart.current || 0;
      const loadTime = Math.round((Date.now() - timeStart) / 1000);
      // onLoad(data);
      if (typeof currentSource === 'object' && currentSource.uri) {
        fetch(currentSource.uri)
          .then(async response => {
            let value = await response.text();
            let dataParsed = parseHlsResponse(value);
            if (dataParsed[0]) {
              onAudioLoad && onAudioLoad(data, loadTime);
            } else {
              onAudioLoad && onAudioLoad(data, loadTime);
            }
          })
          .catch(error => {
            console.log('error loadedData', error);
          });
      } else {
        onAudioLoad && onAudioLoad(data, loadTime);
      }
      onGetDuration && onGetDuration(data.duration);
    },
    [onGetDuration, onAudioLoad, currentSource],
  );

  const poster =
    typeof currentPoster === 'object' && 'uri' in currentPoster
      ? currentPoster.uri
      : sourceList[currentMedia]?.poster;

  return (
    <div
      style={{
        width: '100%',
        height: showMiniPlayer ? 'fit-content' : '100%',
        backgroundImage: isMobile
          ? undefined
          : showMiniPlayer
            ? 'none'
            : `url(${poster})`,
        backgroundColor: isMobile ? '#373737' : undefined,
        backgroundSize: 'cover',
      }}>
      {!showMiniPlayer && (
        <div
          style={{
            backdropFilter: 'blur(12px)',
            width: '100%',
            height: '100%',
            backgroundColor: '#0006',
            padding: isMobile ? '0 16px' : '0 60px',
            overflow: 'auto',
            boxSizing: 'border-box',
          }}>
          <View style={{height: '100%', width: '100%'}}>
            {!isLoadingSource ? (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: 34,
                }}>
                {/* header */}
                <div
                  style={{
                    width: '100%',
                    paddingTop: 32,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Pressable onPress={onPressMiniPlayer}>
                    <Icon name="retour" size="76" />
                  </Pressable>
                  <Typography variant="body2">{radioTitle}</Typography>

                  {onClose ? (
                    <Pressable
                      onPress={() => {
                        onClose && onClose();
                      }}>
                      <Icon name="close" size="76" />
                    </Pressable>
                  ) : (
                    <div />
                  )}
                </div>
                {/* body */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent:
                      sideContent && !isMobile ? 'space-between' : 'flex-start',
                    flex: 1,
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: 38,
                      alignSelf: isMobile ? undefined : 'flex-end',
                      flexDirection: isMobile ? 'column' : 'row',
                      width: isMobile ? '100%' : undefined,
                    }}>
                    <div
                      style={{
                        backgroundImage: `url(${poster})`,
                        backgroundSize: 'cover',
                        width: isMobile ? '100%' : Metrics.horizontalScale(343),
                        height: Metrics.horizontalScale(343),
                        borderRadius: 4,
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                      }}>
                      <Typography
                        children={
                          epgData?.title ||
                          sourceList[currentMedia]?.title?.toUpperCase()
                        }
                        variant="h2"
                      />
                      <Typography
                        children={
                          epgData?.subtitle ||
                          sourceList[currentMedia]?.subtitle
                        }
                        variant="body2"
                      />
                    </div>
                  </div>
                  {sideContent && !isMobile && sideContent}
                </div>
                {/* footer */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: 60,
                    alignItems: 'center',
                  }}>
                  {!isRadio && (
                    <PodcastSlider
                      duration={duration}
                      loaded={loaded}
                      played={played}
                      onSliderChange={handleSliderChange}
                      playedSeconds={playedSeconds}
                    />
                  )}
                  <ControlPlayer
                    isLoading={
                      (isLoading && currentSource !== undefined) ||
                      !isLoadedMetadata
                    }
                    isPlaying={playing}
                    onShare={onShare}
                    onPrevious={
                      !isRadio
                        ? () => {
                            channelDown(onPrevious);
                          }
                        : undefined
                    }
                    onPlay={() => {
                      handlePlayPause();
                      onAudioPlay &&
                        onAudioPlay(sourceList[currentMedia], playing);
                    }}
                    onNext={
                      !isRadio
                        ? () => {
                            channelUp(onNext);
                          }
                        : undefined
                    }
                    onFavoris={onFavoris}
                    isLiked={isCurrentChannelLiked}
                  />
                </div>
                {sideContent && isMobile && sideContent}
              </div>
            ) : (
              <View
                style={{
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CustomActivityIndicator />
              </View>
            )}
          </View>
        </div>
      )}
      {showMiniPlayer && (
        <RadioPlayerCard
          onPressExtend={onPressMiniPlayer}
          isRadio={isRadio}
          title={epgData?.title || sourceList[currentMedia]?.title}
          subtitle={epgData?.subtitle || sourceList[currentMedia]?.subtitle}
          poster={{
            uri: poster,
          }}
          radioName={radioTitle}
          controlComponent={
            <ControlPlayer
              isLoading={
                (isLoading && currentSource !== undefined) || !isLoadedMetadata
              }
              isPlaying={playing}
              onShare={onShare}
              isMini={true}
              onPrevious={
                !isRadio
                  ? () => {
                      channelDown(onPrevious);
                    }
                  : undefined
              }
              onPlay={() => {
                handlePlayPause();
                onAudioPlay && onAudioPlay(sourceList[currentMedia], playing);
              }}
              onNext={
                !isRadio
                  ? () => {
                      channelUp(onNext);
                    }
                  : undefined
              }
              onFavoris={onFavoris}
              isLiked={isCurrentChannelLiked}
            />
          }
          sliderComponent={
            !isRadio && (
              <div
                style={{
                  width: 550,
                  margin: 'auto',
                }}>
                <PodcastSlider
                  duration={duration}
                  loaded={loaded}
                  played={played}
                  onSliderChange={handleSliderChange}
                  playedSeconds={playedSeconds}
                  marginBottom={0}
                />
              </div>
            )
          }
        />
      )}
      {currentSource && (
        <video
          ref={videoRef}
          poster={sourceList[currentMedia]?.poster}
          src={(currentSource as any)?.uri ?? ''}
          onPause={() => {
            onAudioPlay && onAudioPlay(sourceList[currentMedia], true);
            setPlaying(false);
          }}
          onTimeUpdate={handleTimeUpdate}
          onPlay={() => {
            onAudioPlay && onAudioPlay(sourceList[currentMedia], false);
            setPlaying(true);
          }}
          onLoadedMetadata={handleLoadedMetadata}
          onProgress={handleProgress}
          onLoadStart={() => {
            timeLoadStart.current = Date.now();
          }}
          onLoad={handleLoad}
          onEnded={() => {
            onPlayEnd && onPlayEnd();
            onEnd(onNext);
          }}
          style={{
            display: 'none',
          }}
        />
      )}
    </div>
  );
};

export default ListenPlayer;
