import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {useSingleLearnPage} from '../../Hooks/useSingleLearnPage';
import {
  useCourseByEndUrlLazyQuery,
  useJobTrainingByCourseLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {View} from 'react-native';
import {HomeContentLoader} from 'design-system/src/Components/ContentLoader';
import {ILearnPropertyName} from '~Services/Amplitude/hooks/useTracker';
import {tracker} from '~Services/Amplitude/hooks/tracker';
import {useLazyGetMemberCourseTestProgressionByIdQuery} from '~Api/TraceApi/traceApi';
import useParams from '@src/Hooks/useParams';
import IntermediateLoaderContainer from '@src/App/components/Common/IntermediateLoaderContainer/IntermediateLoaderContainer';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

export default function SingleCourse() {
  const {endUrl1, endUrl2} = useParams();

  const endUrl =
    endUrl1 && endUrl2
      ? `/${endUrl1}/${endUrl2}/`.replaceAll('//', '/')
      : endUrl1;

  const [fetchJobTrainingByCourseId, response] =
    useJobTrainingByCourseLazyQuery();
  // const certificateLoading = !id || response.loading;
  const {t} = useTranslation('translation');
  const [fetchCourse, {data, loading}] = useCourseByEndUrlLazyQuery();
  const course = data?.allCourse?.items?.[0];
  const id = course?.id;
  const {renderSingleLearnPage} = useSingleLearnPage(id || '');
  const [
    fetchCourseTestProgression,
    {data: examProgress, isLoading: progressLoading},
  ] = useLazyGetMemberCourseTestProgressionByIdQuery();
  const [examLoading, setExamLoading] = useState<boolean>();

  useEffect(() => {
    if (endUrl) {
      fetchCourse({
        variables: {
          endUrl,
        },
      });
    }
  }, [endUrl]);

  useEffect(() => {
    if (id) {
      fetchJobTrainingByCourseId({
        variables: {
          id,
        },
      });
    }
  }, [id, fetchJobTrainingByCourseId]);

  const {
    pageContent,
    property,
    courseExam,
  }: {
    pageContent: ReactNode | undefined;
    property?: ILearnPropertyName;
    courseExam?: string;
  } = useMemo(() => {
    let certificateName: string | undefined;
    let certificateId: string | undefined;
    const {data: jobData} = response;
    if (
      jobData &&
      jobData.allJobTraining &&
      jobData.allJobTraining.items &&
      jobData.allJobTraining.items[0] &&
      jobData.allJobTraining.items[0].__typename === 'JobTraining'
    ) {
      certificateName = jobData.allJobTraining.items[0].name;
      certificateId = jobData.allJobTraining.items[0].id;
    }
    let _property: ILearnPropertyName | undefined = undefined;
    let _pageContent: ReactNode | undefined = undefined;
    let _courseExam: string | undefined = undefined;

    if (course) {
      const {interests, name, id, linkedPartners, children} = course;
      children?.items?.forEach(item => {
        if (item && item.__typename === 'CourseExam') {
          _courseExam = item.id;
        }
      });
      _property = {
        courseName: name,
        courseId: id,
        partnerName:
          linkedPartners &&
          linkedPartners[0] &&
          linkedPartners[0].__typename === 'Partner'
            ? linkedPartners[0].partnerName
            : '',
        certificateName,
        certificateId,
        interestName:
          interests && interests[0] && interests[0].__typename === 'ContentTag'
            ? interests[0].name
            : '',
      };
      _pageContent = renderSingleLearnPage(
        course,
        false,
        _property,
        certificateId !== undefined,
        examProgress,
      );
    }
    return {
      pageContent: _pageContent,
      property: _property,
      courseExam: _courseExam,
    };
  }, [renderSingleLearnPage, data, response, examProgress, course]);

  const handleFetchExamProgress = useCallback(async () => {
    setExamLoading(true);
    if (courseExam) {
      await fetchCourseTestProgression({
        id: courseExam,
      });
    }
    setExamLoading(false);
  }, [setExamLoading, courseExam, fetchCourseTestProgression]);

  useEffect(() => {
    handleFetchExamProgress();
  }, [courseExam]);

  useEffect(() => {
    if (property) {
      tracker.learn.courseStarted(property);
    }
  }, [property]);

  return (
    <div>
      <DebugUmbracoId umbracoId={id} position="page" />
      {endUrl ? (
        loading ? (
          <HomeContentLoader />
        ) : course ? (
          <IntermediateLoaderContainer loading={progressLoading || examLoading}>
            {pageContent}
          </IntermediateLoaderContainer>
        ) : (
          <View style={{flex: 1}}>
            <ContentEmpty title={t('Section not found')} />
          </View>
        )
      ) : (
        <View style={{flex: 1}}>
          <ContentEmpty title={t('Section not found')} />
        </View>
      )}
    </div>
  );
}
