/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import {View} from 'react-native';
import {rowCardStyles} from './RowCard.styles';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import styled from 'styled-components';
import Metrics from '../../../Theme/Metrics';

type RowCardProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  flexDirection?: 'row' | 'column';
  rightMarginTop?: number;
  rightFlex?: number;
};

const RightContent = styled('div')<{
  $flexDirection?: RowCardProps['flexDirection'];
}>(({$flexDirection}) => {
  return {
    marginLeft:
      $flexDirection === 'row' ? Metrics.horizontalScale(16) : undefined,
    marginTop:
      $flexDirection === 'column' ? Metrics.verticalScale(19) : undefined,
  };
});
const RowCard = ({
  left,
  right,
  flexDirection,
  rightMarginTop,
  rightFlex,
}: RowCardProps) => {
  const {isMobile} = useBreakpoint();
  const finalFlexDirection = flexDirection || (isMobile ? 'row' : 'column');
  return (
    <View
      style={{
        ...rowCardStyles.container,
        flexDirection: finalFlexDirection,
      }}
      testID="row-card">
      {left}
      <RightContent
        $flexDirection={finalFlexDirection}
        data-testid="row-card-right"
        style={{
          marginTop: rightMarginTop,
          flex: rightFlex,
        }}>
        {right}
      </RightContent>
    </View>
  );
};

export default RowCard;
