import {CarouselData} from 'design-system/src/Components/Header/CarouselHeader/CarouselHeader';
import {
  IHeroSlideFragmentFragment,
  ILearnHeroSildeFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {IUmbracoRouteParams} from '~Umbraco/Interface/Umbraco.interface';
import mapButton from '../element/mapButton';
import {TFunction} from 'i18next';

export type HeroSliderData = CarouselData<{
  externalLink?: string;
  linkTo?: IUmbracoRouteParams;
}>;

export const mapHeroSlide = (
  content: IHeroSlideFragmentFragment,
): HeroSliderData => {
  const {title = '', cover, highlights, button} = content;
  const buttonMapped =
    button && button[0] && button[0].content.__typename === 'Button'
      ? mapButton(button[0].content)
      : undefined;
  const {linkTo, ...buttonProps} = buttonMapped || {};

  return {
    categorie: highlights?.map<string>(item => item || '') || [],
    image: {
      uri:
        cover && cover[0] && cover[0].content.__typename === 'HeroVideoCover'
          ? cover[0].content.image?.url || ''
          : '',
    },
    desktopImage: {
      uri:
        cover && cover[0] && cover[0].content.__typename === 'HeroVideoCover'
          ? cover[0].content.desktopImage?.url || ''
          : '',
    },
    title: title || '',
    button:
      Object.keys(buttonProps).length > 0
        ? {title: '', ...buttonProps, size: 'small', variant: 'outlined'}
        : undefined,
    hideButton: !linkTo || !buttonMapped,
    data: {
      // externalLink:
      //   button && button[0] && button[0].content.__typename === 'Button'
      //     ? button[0].content.externalLink
      //     : '',
      linkTo: linkTo,
    },
  };
};

export const mapLearnHeroSlide = (
  content: ILearnHeroSildeFragmentFragment,
  t: TFunction<'translation', undefined, 'translation'>,
): HeroSliderData => {
  const {title = '', cover, button, partners} = content;
  const buttonMapped =
    button && button[0] && button[0].content.__typename === 'Button'
      ? mapButton(button[0].content)
      : undefined;
  const {linkTo, ...buttonProps} = buttonMapped || {};
  let allLogo: Array<string> = [];
  if (partners) {
    partners.map(partner => {
      if (partner && partner.__typename === 'Partner') {
        allLogo.push(partner.logo?.url || '');
      }
    });
  }

  return {
    image: {
      uri:
        cover && cover[0] && cover[0].content.__typename === 'HeroVideoCover'
          ? cover[0].content.image?.url || ''
          : '',
    },
    desktopImage: {
      uri:
        cover && cover[0] && cover[0].content.__typename === 'HeroVideoCover'
          ? cover[0].content.desktopImage?.url || ''
          : '',
    },
    title: title || '',
    button:
      Object.keys(buttonProps).length > 0
        ? {title: '', ...buttonProps, size: 'small', variant: 'outlined'}
        : undefined,
    hideButton: !linkTo || !buttonMapped,
    data: {
      // externalLink:
      //   button && button[0] && button[0].content.__typename === 'Button'
      //     ? button[0].content.externalLink
      //     : '',
      linkTo: linkTo,
    },
    partner: allLogo.length
      ? {
          logo: allLogo,
          label: t('Avec'),
        }
      : undefined,
  };
};
