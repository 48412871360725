import React, {useContext, useEffect, useMemo} from 'react';
import {graphql, navigate, PageProps} from 'gatsby';
import {
  useContentUrlByIdLazyQuery,
  useFormPageByUrlEndQuery,
} from '@src/Api/Graphql/gql/types.generated';
import useNavigation from '@src/Navigation/useNavigation';
import FormPage from '@src/Umbraco/Pages/FormPage/FormPage';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Form} from '@src/Api/Umbraco/interface/UmbracoTypes';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import ModalRoutingContext from '../../../plugins/layout-wrapper/ModalRoutingContext';

const FormsPage = (props: PageProps<any, {id: string}>) => {
  // @ts-ignore
  const endUrl = `/${props.params.endUrl || props.id}/`.replace(/\/\//g, '/');
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {t} = useTranslation(['service']);
  const [fetchContent, {data: contentData}] = useContentUrlByIdLazyQuery();
  const {closeTo} = useContext(ModalRoutingContext);

  const {data, loading} = useFormPageByUrlEndQuery({
    variables: {
      endUrl: endUrl,
    },
    errorPolicy: 'all',
    skip: !endUrl,
  });
  const formPageData:
    | undefined
    | {
        form: Form;
        cover?: string;
        coverDesktop?: string;
        gotoPageOnSubmit?: string;
        title?: string;
        subtitle?: string;
      } = useMemo(() => {
    const formPage = data?.allFormPage?.items?.[0];
    if (formPage) {
      const {form: _form, cover: _cover} = formPage;
      return {
        id: formPage.id,
        form: _form,
        cover:
          _cover &&
          _cover[0] &&
          _cover[0]?.content.__typename === 'HeroVideoCover'
            ? _cover[0]?.content.image?.url
            : undefined,
        coverDesktop:
          _cover &&
          _cover[0] &&
          _cover[0]?.content.__typename === 'HeroVideoCover'
            ? _cover[0]?.content.desktopImage?.url
            : undefined,
        gotoPageOnSubmit: (_form as Form | undefined)?.gotoPageOnSubmit,
        title: formPage.title,
        subtitle: formPage.subtitle,
      };
    }
  }, [data]);

  useEffect(() => {
    if (formPageData && formPageData.gotoPageOnSubmit) {
      fetchContent({
        variables: {
          id: formPageData.gotoPageOnSubmit,
        },
      });
    }
  }, [formPageData]);

  const handleSuccess = () => {
    if (contentData && contentData.content) {
      const {id: _id, url, __typename} = contentData.content;
      new RouteFactory(
        {
          id: _id,
          url: url || '',
          __typename,
        },
        navigation,
        dispatch,
      ).navigate();
    } else {
      navigation.navigate('/');
    }
  };

  const handleClose = () => {
    if (closeTo) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  if (loading) {
    return (
      <div style={{flex: 1}}>
        <CustomActivityIndicator
          style={{
            flex: 1,
          }}
        />
      </div>
    );
  }

  if (!formPageData) {
    return <ContentEmpty title={t('Form not found')} />;
  }
  const {form, cover, coverDesktop, title, subtitle} = formPageData;
  return (
    <div
      style={{
        paddingBottom: '20px',
      }}>
      {form && (
        <FormPage
          form={form}
          cover={cover}
          coverDesktop={coverDesktop}
          title={title}
          subtitle={subtitle}
          onClose={handleClose}
          onSuccess={handleSuccess}
        />
      )}
    </div>
  );
};

FormsPage.isModal = true;
// FormsPage.layoutComponent = 'DefaultLayout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default FormsPage;
