import {TFunction} from 'i18next';
import {
  IAllCourseBySomeIdsQuery,
  IAllJobTrainingBySomeCourseQuery,
  ICourseCategoryFragmentFragment,
  ICourseFragmentFragment,
  ICourseItemsSliderFragmentFragment,
  ICourseUnitFragmentFragment,
  IJobTrainingFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {useCallback} from 'react';
import {formatDuration} from '@src/Hooks/useStringExtension';
import {useSelecteUnitsProgression} from '@src/Store/selectors/useMember';
import {ICourseSliderItem} from '@src/Umbraco/Components/CourseSlider/CourseSlider';

export const useMapCourseCateg = () => {
  const {t} = useTranslation(['learn']);
  const unitsProgression = useSelecteUnitsProgression();
  const countProgression = useCallback(
    (
      items: ICourseFragmentFragment[] | ICourseUnitFragmentFragment[],
    ): {
      counter: number;
      total: number;
    } => {
      let counter: number = 0;
      let total: number = 0;
      items.forEach(item => {
        if (item && item.__typename === 'CourseUnit') {
          total++;
        }
        const findItem = unitsProgression.find(unitProgression => {
          if (item.__typename === 'Course') {
            return unitProgression.courseId === item.id;
          }
          return unitProgression.name === item.id;
        });
        if (findItem) {
          counter++;
        }
      });
      return {counter, total};
    },
    [unitsProgression],
  );
  const mapLearnItem = useCallback(
    (
      item:
        | ICourseCategoryFragmentFragment['children']['items'][number]
        | ICourseFragmentFragment,
      checkUrl?: boolean,
      removeFinished?: boolean,
    ): ICourseSliderItem | undefined => {
      if (item && item.__typename === 'Course') {
        const {title, id, url, linkedPartners, __typename, length, children} =
          item;
        let isValide = true;
        if (checkUrl && !url) {
          isValide = false;
        }
        let _total: number | undefined;
        let _counter = 0;
        if (children && children.items) {
          const {counter, total} = countProgression(
            children.items as ICourseUnitFragmentFragment[],
          );
          _counter = counter;
          _total = total;
        }
        if (removeFinished && _counter === _total) {
          isValide = false;
        }
        if (isValide) {
          return {
            cover: {
              uri: item.thumbnail?.cropUrl || item.thumbnail?.url,
            },
            title: title || '',
            duration: length
              ? formatDuration(length, length < 3600)
              : undefined,
            tag: {
              text: 'Course',
              variant: 'course',
            },
            id,
            url: url || '',
            partenerLogo:
              linkedPartners &&
              linkedPartners[0] &&
              linkedPartners[0].__typename === 'Partner'
                ? linkedPartners?.map(
                    // @ts-ignore
                    partner => partner?.logo?.url,
                  )
                : undefined,
            size: 'small',
            __typename,
            progress: _total ? _counter / _total : undefined,
            isDone: _total !== 0 && _total === _counter,
            textDone: t('Done') as string,
          };
        }
      } else if (item && item.__typename === 'JobTraining') {
        const {title, id, url, cover, partners, __typename, length, courses} =
          item as IJobTrainingFragmentFragment;
        let _total: number | undefined = courses?.length;
        let _counter = 0;
        let isValide = true;
        if (courses) {
          _counter = countProgression(
            courses as ICourseFragmentFragment[],
          ).counter;
        }
        if (removeFinished && _total === _counter) {
          isValide = false;
        }
        if (isValide) {
          return {
            cover: {
              uri:
                cover &&
                cover[0] &&
                cover[0].content &&
                cover[0].content.__typename === 'HeroVideoCover'
                  ? cover[0].content.image?.cropUrl ||
                    cover[0].content.image?.url
                  : undefined,
            },
            title: title || '',
            duration: `${length} ${t('hours')}`,
            tag: {
              text: t('Job Training').toUpperCase(),
              variant: 'job',
            },
            id,
            url: url || '',
            partenerLogo:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners?.map(
                    // @ts-ignore
                    partner => partner?.logo?.url,
                  )
                : undefined,
            size: 'small',
            __typename,
            progress: _total ? _counter / _total : undefined,
          };
        }
      }
      return undefined;
    },
    [countProgression],
  );
  const mapCourseCategItems = useCallback(
    (
      items:
        | ICourseCategoryFragmentFragment['children']['items']
        | ICourseItemsSliderFragmentFragment['contentItems']
        | IAllJobTrainingBySomeCourseQuery['jobs']['items']
        | IAllCourseBySomeIdsQuery['courses']['items'],
      checkUrl?: boolean,
      removeFinished?: boolean,
    ): Array<ICourseSliderItem> => {
      let list: ICourseSliderItem[] = [];
      if (items) {
        items.forEach(item => {
          const _content = mapLearnItem(item as any, checkUrl, removeFinished);
          if (_content) {
            list.push(_content);
          }
        });
      }

      return list;
    },
    [t, countProgression, mapLearnItem],
  );

  return {mapCourseCategItems, countProgression, mapLearnItem};
};

export const mapCourseCategItems = (
  items:
    | ICourseCategoryFragmentFragment['children']['items']
    | ICourseItemsSliderFragmentFragment['contentItems'],
  t: TFunction<'translation', undefined, 'translation'>,
  checkUrl?: boolean,
): Array<ICourseSliderItem> => {
  let list: ICourseSliderItem[] = [];
  if (items) {
    items.forEach(item => {
      if (
        item &&
        item.__typename === 'Course' &&
        item.url &&
        item.url !== '#'
      ) {
        const {title, id, url, linkedPartners, __typename, length} = item;
        let isValide = true;
        if (checkUrl && !url) {
          isValide = false;
        }
        if (isValide) {
          list.push({
            cover: {
              uri: item.thumbnail?.cropUrl,
            },
            title: title || '',
            duration: length
              ? formatDuration(length, length < 3600)
              : undefined,
            tag: {
              text: 'Course',
              variant: 'course',
            },
            id,
            url: url || '',
            partenerLogo:
              linkedPartners &&
              linkedPartners[0] &&
              linkedPartners[0].__typename === 'Partner'
                ? linkedPartners[0].logo?.url
                : undefined,
            size: 'small',
            __typename,
          });
        }
      } else if (
        item &&
        item.__typename === 'JobTraining' &&
        (item as IJobTrainingFragmentFragment).url &&
        (item as IJobTrainingFragmentFragment).url !== '#'
      ) {
        const {title, id, url, cover, partners, __typename, length} =
          item as IJobTrainingFragmentFragment;

        list.push({
          cover: {
            uri:
              cover &&
              cover[0] &&
              cover[0].content &&
              cover[0].content.__typename === 'HeroVideoCover'
                ? cover[0].content.image?.url
                : undefined,
          },
          title: title || '',
          duration: `${length} ${t('hours')}`,
          tag: {
            text: 'JOB TRAINING',
            variant: 'job',
          },
          id,
          url: url || '',
          partenerLogo:
            partners && partners[0] && partners[0].__typename === 'Partner'
              ? partners?.map(
                  // @ts-ignore
                  partner => partner?.logo?.url,
                )
              : undefined,
          size: 'small',
          __typename,
        });
      }
    });
  }

  return list;
};
