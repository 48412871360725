import React, {ReactNode} from 'react';
import {
  StyleSheet,
  Text,
  Pressable,
  View,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Icon from '../../Icon/Icon';
import Typography from '../../Typography/Typography';

import GenericPremiumBody from '../GenericPremiumBody/GenericPremiumBody';
import GradientIconCard from '../GradientCard/GradientIconCard/GradientIconCard';
import {subscribeStyles, subscribeVariantStyles} from './Subscribe.styles';
import defaultTheme from '../../../Theme/defaultTheme';

export type SubscribeCardrops = {
  title: string;
  description: string;
  initialValue?: boolean;
  onChange?: (value: boolean) => void;
  onPress?: () => void;
  price: string;
  percentageSave?: string;
  tagColor?: string;
  period: 'm' | 'y' | 'w' | 'free' | 'more';
  icon: IconName | (() => ReactNode);
  isActive?: boolean;
  style?: StyleProp<ViewStyle>;
};

const SubscribeCard = (props: SubscribeCardrops) => {
  const {
    title,
    description,
    onPress,
    price,
    percentageSave,
    icon,
    period,
    isActive,
    tagColor,
    style,
  } = props;
  const isFree = period === 'free';
  const variant = isActive ? 'active' : 'default';
  const variantStyles = subscribeVariantStyles[variant];

  return (
    <Pressable
      style={StyleSheet.flatten([
        subscribeStyles.container,
        variantStyles,
        style,
      ])}
      onPress={onPress}>
      <GenericPremiumBody
        title={title}
        description={description}
        leadingCard={
          <View>
            <GradientIconCard icon={icon} variant={variant} />
            {isActive && (
              <Icon name="checkfilled" style={subscribeStyles.iconCheck} />
            )}
          </View>
        }
        trailing={
          !isFree && (
            <View style={subscribeStyles.trailing}>
              {price !== '' && (
                <Text
                  style={
                    Platform.OS === 'web'
                      ? {
                          alignItems: 'baseline',
                        }
                      : {}
                  }>
                  <Typography children={`${price}`} variant="body1" />
                  <Typography children={` /${period}`} variant="ui3" />
                </Text>
              )}
              {percentageSave && (
                <View
                  style={StyleSheet.flatten([
                    subscribeStyles.tag,
                    {
                      backgroundColor: tagColor || defaultTheme.tag.secondary,
                    },
                  ])}>
                  <Typography children={percentageSave} variant="ui3" />
                </View>
              )}
            </View>
          )
        }
      />
    </Pressable>
  );
};

export default SubscribeCard;
