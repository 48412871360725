import React, {useMemo, useState} from 'react';
import {
  Platform,
  Pressable,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {IconName} from '../../../assets/Icones';
import Button from '../../../Components/Button/Button';
import SubscribeCard from '../../../Components/Card/SubscribeCard/SubscribeCard';
import Typography from '../../../Components/Typography/Typography';
import Metrics from '../../../Theme/Metrics';
import {choosePlanStyles} from './payWallChoosePlan.styles';
import {IMediaCover} from '../../../Components/Card/CarouselCard/CarouselCard';
import {CustomFastImageProps} from '../../../Components/CustomFastImage/CustomFastImageProps';
import CustomFastImage from '../../../Components/CustomFastImage/CustomFastImage';

export interface IItemPlan {
  subscriptionId: string;
  title: string;
  subtitle: string;
  price: string;
  period: 'm' | 'y' | 'w' | 'free' | 'more';
  icon: CustomFastImageProps['source'];
  percentageSave?: string;
  description: string;
  planId: string;
  offerToken?: string;
  successId: string;
  thankYouTitle: string;
  thankYouBody: string;
  thankYouCover?: IMediaCover;
  tagColor?: string;
  disabled?: boolean;
  isCurrent?: boolean;
  subscriptionLink?: string;
}

export const periodIcon: Record<IItemPlan['period'], IconName> = {
  m: 'months',
  w: 'year',
  y: 'year',
  free: 'free',
  more: 'more',
};

export interface PayWallChoosePlanProps {
  title: string;
  primaryButtonTitle: string;
  secondaryButtonTitle: string;
  thirdButtonTitle: string;
  items: Array<IItemPlan>;
  onItemPress?: (item: IItemPlan) => void;
  onCancel?: () => void;
  onConfirm?: (item: IItemPlan) => void;
  onMore?: () => void;
  variant?: 'default' | 'paywall';
  horizontal?: boolean;
  contentWrapperStyle?: StyleProp<ViewStyle>;
}

const PayWallChoosePlan = (props: PayWallChoosePlanProps) => {
  const {
    title,
    primaryButtonTitle,
    secondaryButtonTitle,
    thirdButtonTitle,
    items,
    onCancel,
    onConfirm,
    onItemPress,
    onMore,
    variant = 'default',
    horizontal,
    contentWrapperStyle,
  } = props;

  const [selectedPlan, setSelectedPlan] = useState<IItemPlan>();
  const handleConfirm = () => {
    selectedPlan &&
      !selectedPlan.disabled &&
      onConfirm &&
      onConfirm(selectedPlan);
  };
  return (
    <View style={[choosePlanStyles.container, contentWrapperStyle]}>
      <View style={choosePlanStyles.title}>
        <Typography children={title} variant="h3" />
        {onMore && (
          <TouchableOpacity onPress={onMore}>
            <Typography
              children={thirdButtonTitle}
              color={'primary'}
              variant="button3"
            />
          </TouchableOpacity>
        )}
      </View>
      <View
        style={
          horizontal && Platform.OS === 'web'
            ? // @ts-ignore
              {flexDirection: 'row', gap: 12}
            : {}
        }>
        {items.map((item, index) => {
          const {icon} = item;
          return (
            <View
              key={index}
              style={
                horizontal
                  ? {flex: 1}
                  : {marginBottom: Metrics.verticalScale(16)}
              }>
              <SubscribeCard
                style={
                  horizontal
                    ? {
                        height: '100%',
                        justifyContent: 'center',
                      }
                    : null
                }
                title={item.title}
                description={item.subtitle}
                price={item.price}
                period={item.period}
                isActive={
                  item.planId === selectedPlan?.planId ||
                  (item.isCurrent && !selectedPlan)
                }
                icon={
                  !icon
                    ? periodIcon[item.period]
                    : () => (
                        <CustomFastImage
                          width={Metrics.horizontalScale(32)}
                          height={Metrics.horizontalScale(32)}
                          source={icon}
                          backgroundFallbackColor="transparent"
                        />
                      )
                }
                percentageSave={item.percentageSave}
                onPress={() => {
                  setSelectedPlan(item);
                  onItemPress && onItemPress(item);
                }}
                tagColor={item.tagColor}
              />
            </View>
          );
        })}
      </View>
      {variant === 'paywall' ? (
        <View style={choosePlanStyles.paywallButtonContainer}>
          <Button
            style={{minWidth: Metrics.horizontalScale(226)}}
            onPress={handleConfirm}
            disabled={!selectedPlan}
            title={primaryButtonTitle}
            variant={
              !selectedPlan || selectedPlan.disabled ? 'disabled' : 'contained'
            }
            size="small"
          />
        </View>
      ) : (
        <View style={choosePlanStyles.buttonContainer}>
          <Pressable onPress={onCancel} style={choosePlanStyles.textButton}>
            <Typography
              children={secondaryButtonTitle}
              variant="ui3"
              color="gray"
            />
          </Pressable>
          <Button
            onPress={handleConfirm}
            disabled={!selectedPlan || selectedPlan.disabled}
            title={primaryButtonTitle}
            variant={
              !selectedPlan || selectedPlan.disabled ? 'disabled' : 'contained'
            }
            size="small"
          />
        </View>
      )}
    </View>
  );
};

export default PayWallChoosePlan;
