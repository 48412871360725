import useAppNavigation from '@src/utils/useAppNavigation';
import Button from 'design-system/src/Components/Button/Button';
import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import TextInputController from 'design-system/src/Components/Form/TextInputController/TextInputController';
import React, {useCallback, useMemo, useState} from 'react';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {View, StyleSheet} from 'react-native';
import {usePromoCodeContentQuery} from '~Api/Graphql/gql/types.generated';
import {mapHeroVideoCover} from '~Umbraco/Utils/mapHeroVideoCover';
import {usePromoCode} from './usePromocode';
import Metrics from 'design-system/src/Theme/Metrics';
import {AppRoutesEnum} from '@src/Constant/routes';
import Typography from 'design-system/src/Components/Typography/Typography.web';

interface ISendPromoCodeForm {
  code: string;
}
const PromoCodePage = () => {
  const navigation = useAppNavigation();
  const {control} = useForm<ISendPromoCodeForm>();
  const {field} = useController<any>({
    control,
    defaultValue: '',
    name: 'search',
  });
  const {handlePostPromocode, postPromocodeLoading: loading} = usePromoCode();
  const response = usePromoCodeContentQuery();
  const {t} = useTranslation('subscription');
  const [value, setValue] = useState<string>('');

  const {
    thankYouBody,
    thankYouTitle,
    mappedCover,
  }: {
    thankYouBody?: string;
    thankYouTitle?: string;
    mappedCover?: IMediaCover;
  } = useMemo(() => {
    const {data} = response;
    if (
      data &&
      data.allSettings &&
      data.allSettings.items &&
      data.allSettings.items[0]
    ) {
      const {children} = data.allSettings.items[0];
      for (const _item of children.items) {
        if (_item && _item.__typename === 'PromoCodeFolder') {
          const {thankYouBody, thankYouCover, thankYouTitle} = _item;
          let mappedCover: IMediaCover | undefined = undefined;
          if (
            thankYouCover &&
            thankYouCover[0] &&
            thankYouCover[0].content &&
            thankYouCover[0].content.__typename === 'HeroVideoCover'
          ) {
            mappedCover = mapHeroVideoCover(thankYouCover[0].content);
          }
          if (thankYouTitle) {
            return {
              thankYouBody,
              thankYouTitle,
              mappedCover,
            };
          }
        }
      }
    }
    return {};
  }, [response]);

  const handleChange = useCallback(
    (text: string) => {
      field.onChange(text);
      setValue(text);
    },
    [field, setValue],
  );

  const handleSubmitSendCode = async (_code: string) => {
    await handlePostPromocode(_code, async () => {
      await navigation.goBack();
      navigation.navigate(AppRoutesEnum.THANK_YOU_MODAL, {
        state: {
          params: {
            title: thankYouTitle,
            body: thankYouBody,
            cover: mappedCover,
          },
        },
      });
    });
  };

  return (
    <View style={{height: '100%'}}>
      <Typography
        variant="h2"
        style={{
          marginBottom: 24,
        }}>
        {t('Redeem your code')}
      </Typography>
      {response.loading ? (
        <CustomActivityIndicator
          style={{
            flex: 1,
          }}
        />
      ) : (
        <View style={[PromoCodePageStyles.content]}>
          <View style={{flex: 1}}>
            <View style={PromoCodePageStyles.formWrapper}>
              <TextInputController
                name="code"
                label={t('Promo code') + ''}
                rules={{required: t('code required') || 'required'}}
                control={control}
                clearable={true}
                variant="transparent"
                value={field.value}
                onChangeText={handleChange}
              />
              {/* {!!isSubmitSuccessful && (
              <Typography variant="body2">{t('Email sent!')}</Typography>
            )} */}
            </View>
            <View
              // @ts-ignore
              style={[
                PromoCodePageStyles.buttonWrapper,
                // @ts-ignore
                {
                  position: 'sticky',
                },
              ]}>
              <Button
                variant="contained"
                size="medium"
                title={t('redeem')}
                fullWidth
                loading={loading}
                onPress={() => {
                  handleSubmitSendCode(value);
                }}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export const PromoCodePageStyles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    paddingBottom: Metrics.verticalScale(50),
    paddingHorizontal: Metrics.horizontalScale(24),
    paddingVertical: Metrics.verticalScale(20),
    height: '100%',
  },
  formWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  buttonWrapper: {
    bottom: 34,
    alignSelf: 'stretch',
  },
});

export default PromoCodePage;
