import Typography from 'design-system/src/Components/Typography/Typography';
import React, {useMemo} from 'react';
import {View} from 'react-native';
import AchievmentContent from 'design-system/src/Components/AchievmentContent/AchievmentContent';
import Metrics from 'design-system/src/Theme/Metrics';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  ICourseFragmentFragment,
  useAllJobTrainingByCourseQuery,
} from '~Api/Graphql/gql/types.generated';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useMapCourseCateg} from '~Umbraco/Utils/content/mapCourseCategItems';
import useAppNavigation from '@src/utils/useAppNavigation';
import useParams from '@src/Hooks/useParams';
import {useSelectObtainedJob} from '@src/Store/selectors/useMember';
import {AppRoutesEnum} from '@src/Constant/routes';
import {Container} from 'design-system/src/WebComponent/Grid';
import {IItemAchievment} from 'design-system/src/Components/AchievmentContent/AchievmentContent.props';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';

export default function LinkedJob() {
  const {t} = useTranslation(['learn']);
  const navigation = useAppNavigation();
  const params = useParams();
  const {id} = params || {};
  const response = useAllJobTrainingByCourseQuery({
    variables: {
      id,
    },
  });
  const {countProgression} = useMapCourseCateg();
  const obtainedJob = useSelectObtainedJob();
  const loading = response.loading;
  const jobTraining: IItemAchievment[] = useMemo(() => {
    let {data} = response;
    let dataAchievments: IItemAchievment[] = [];
    if (data && data.allJobTraining && data.allJobTraining.items) {
      data.allJobTraining.items.map(item => {
        if (item && item.__typename === 'JobTraining') {
          let {title, cover, id, url, partners, length, courses} = item;
          let _total: number | undefined = courses?.length;
          let _counter = 0;
          const _isDone = obtainedJob.find(
            _item => _item.certificateId === id,
          )?.finished;
          if (courses) {
            _counter = countProgression(
              courses as ICourseFragmentFragment[],
            ).counter;
          }
          dataAchievments.push({
            title: title || '',
            duration: length ? `${length} ${t('hours')}` : undefined,
            cover: {
              uri:
                cover &&
                cover[0] &&
                cover[0].content &&
                cover[0].content.__typename === 'HeroVideoCover'
                  ? cover[0].content.image?.cropUrl ||
                    cover[0].content.image?.url
                  : undefined,
            },
            tag: {
              variant: 'job',
              text: t('Job Training').toUpperCase(),
            },
            id,
            url: url || '',
            partenerLogo:
              partners && partners[0] && partners[0].__typename === 'Partner'
                ? partners[0].logo?.url
                : undefined,
            progress: _total ? _counter / _total : undefined,
            isDone: _isDone,
            textDone: t('Done') as string,
          });
        }
      });
    }
    return dataAchievments;
  }, [response, obtainedJob]);

  return (
    <Container>
      <DebugUmbracoId umbracoId={id} position="page" />
      {loading ? (
        <CustomActivityIndicator
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      ) : (
        <View style={{paddingTop: Metrics.verticalScale(121)}}>
          <Typography
            variant="h3"
            style={{paddingHorizontal: Metrics.horizontalScale(16)}}>
            {t('Linked job training')}
          </Typography>
          <View>
            {jobTraining.length ? (
              <AchievmentContent
                noBorder
                data={jobTraining}
                onItemPress={item => {
                  navigation.navigate(AppRoutesEnum.SINGLE_JOB, {
                    state: {params: {id: item.id, url: item.url}},
                  });
                }}
                errorMessage={t('No data') || ''}
              />
            ) : (
              <ContentEmpty title={t('Section not found')} />
            )}
          </View>
        </View>
      )}
    </Container>
  );
}
