import {AppRoutesEnum} from '@src/Constant/routes';
import useAppNavigation from '@src/utils/useAppNavigation';
import Button from 'design-system/src/Components/Button/Button';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {
  IChannelFragmentFragment,
  ILiveEventFragmentFragment,
  IVodItemFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {IPaywallSetting} from '~Hooks/usePaywal';
import {tracker} from '~Services/Amplitude/hooks/tracker';

const PlayerPaywall = (props: {
  setting: IPaywallSetting;
  isLive: boolean;
  content:
    | IVodItemFragmentFragment
    | ILiveEventFragmentFragment
    | IChannelFragmentFragment
    | undefined;
}) => {
  const {setting, isLive, content} = props;
  const {text, currentPaywall} = setting;
  const {t} = useTranslation('paywall');
  const navigation = useAppNavigation();
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: Metrics.verticalScale(24),
        maxWidth: '80%',
      }}>
      <Typography
        variant="body1"
        style={{
          marginBottom: Metrics.verticalScale(24),
          textAlign: 'center',
        }}>
        {text}
      </Typography>
      <Button
        title={t("Je m'abonne maintenant !")}
        variant="contained"
        onPress={() => {
          if (content) {
            const {previewDurationTv, previewDurationVod} = setting;
            tracker.goToPaywall({
              id: content.id,
              title: content.title,
              __typename: content.__typename,
              duration: isLive ? previewDurationTv : previewDurationVod,
            });
          }
          navigation.navigate(AppRoutesEnum.PAYWALL, {
            state: {
              headerTitle: '',
              params: {
                id: currentPaywall?.id,
                paywall: currentPaywall,
              },
            },
          });
        }}
      />
    </View>
  );
};

export default PlayerPaywall;
