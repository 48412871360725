import {TraceApiEndpointBuilder} from '~Api/TraceApi/traceApi';

const endpoint = '/me/promo-code';

export interface IPromocodeResponse {
  _id: string;
  _createDate: string;
  _updateDate: string;
  memberId: string;
  code: string;
  duration: number;
  subscriptionStart: string;
  subscriptionEnd: string;
  stock: number;
}

export interface IPromocodeBody {
  code: string;
}

export const createPromocodeEndpoint = (builder: TraceApiEndpointBuilder) => ({
  getPromocode: builder.query<IPromocodeResponse, void>({
    forceRefetch: () => true,
    query: () => ({
      url: `${endpoint}`,
      method: 'GET',
    }),
  }),
  postPromocode: builder.query<IPromocodeResponse, IPromocodeBody>({
    query: body => ({
      url: endpoint,
      method: 'POST',
      body,
    }),
  }),
});
