import {useLazyGetVimeoPlayVideosQuery} from '@src/Api/TraceApi/traceApi';
import useCast from 'design-system/src/Widget/Player/VideoPlayer/Factory/Player/hooks/useCast';
import {useCallback, useEffect} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useCastPlayer from 'design-system/src/Widget/Player/VideoPlayer/Factory/Player/hooks/useCastPlayer';

const useCastOnPage = ({
  vimeoId,
  title,
  poster,
  currentTime,
}: {
  vimeoId?: string;
  title?: string;
  poster?: string;
  currentTime?: number;
}) => {
  const {connected} = useCast();
  const [fetchUrl] = useLazyGetVimeoPlayVideosQuery();
  const {t} = useTranslation('translation');
  const {thumbnail, loadMedia} = useCastPlayer();

  const onLoadMedia = useCallback(
    async (vimeoId: string) => {
      const response = await fetchUrl(vimeoId);
      if (response.data) {
        const url = response.data.dash.link;
        const mediaInfo = new window.chrome.cast.media.MediaInfo(
          1,
          'application/dash+xml',
        );
        mediaInfo.contentUrl = url;
        mediaInfo.hlsSegmentFormat = 'ts';

        const metadata = new window.chrome.cast.media.MovieMediaMetadata();
        metadata.title = title;
        metadata.images = [new window.chrome.cast.Image(poster ?? thumbnail)];
        mediaInfo.metadata = metadata;

        const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
        request.currentTime = currentTime;
        loadMedia(request);
      } else {
        toast.error(t('Video url not found'), {toastId: 'source-not-found'});
      }
    },
    [fetchUrl, t],
  );

  useEffect(() => {
    if (connected && vimeoId) {
      onLoadMedia(vimeoId);
    }
  }, [connected, loadMedia, vimeoId]);
};

export default useCastOnPage;
