import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppRoutesEnum} from '@src/Constant/routes';

export type AuthentificationType =
  | 'login'
  | 'register'
  | 'forgotPassword'
  | 'activateAccount';
export type SocialNetworkSource = 'google' | 'facebook' | 'apple' | 'email';

interface AuthState {
  authentificationType: AuthentificationType;
  registerStep: {
    page: 'first' | 'form' | 'interest';
    step: number;
  };
  formData: any;
  socialNetworkSource: SocialNetworkSource;
  openAuthenticationModal?: {
    redirect?: string;
    open: boolean;
  };
  openTermsAndConditionModal?: boolean;
}

const initialState: AuthState = {
  authentificationType: 'login',
  registerStep: {
    page: 'first',
    step: 0,
  },
  formData: {},
  socialNetworkSource: 'email',
  openAuthenticationModal: {
    open: false,
  },
  openTermsAndConditionModal: false,
};

const appAuthSlice = createSlice({
  name: 'appAuth',
  initialState,
  reducers: {
    setAuthType(state, action: PayloadAction<AuthentificationType>) {
      state.authentificationType = action.payload;
      return state;
    },
    setAuthStep(state, action: PayloadAction<AuthState['registerStep']>) {
      state.registerStep = action.payload;
      return state;
    },
    setFormData(state, action) {
      state.formData = {...state.formData, ...action.payload};
      return state;
    },
    setSocialNetworkSource(state, action: PayloadAction<SocialNetworkSource>) {
      state.socialNetworkSource = action.payload;
      return state;
    },
    resetAuth(state) {
      return {
        ...initialState,
        openAuthenticationModal: state.openAuthenticationModal,
      };
    },
    openAuthenticationModal(
      state,
      action: PayloadAction<AuthState['openAuthenticationModal']>,
    ) {
      state.openAuthenticationModal = action.payload;
      return state;
    },
    setOpenTermsAndConditionModal(state, action: PayloadAction<boolean>) {
      state.openTermsAndConditionModal = action.payload;
      return state;
    },
  },
});

export default appAuthSlice;
