import React, {FC, useEffect, useMemo, useState} from 'react';
import {RouteComponentProps} from '@reach/router';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useAppNavigation from '@src/utils/useAppNavigation';
import useTracker from '@src/Services/Amplitude/hooks/useTracker';
import useDurationFactory from '@src/Hooks/useDurationFactory';
import useUpdateBookmarks from '@src/Hooks/useUpdateBookmarks';
import {
  IVodItemFragmentFragment,
  useVodItemByUrlEndQuery,
} from '@src/Api/Graphql/gql/types.generated';
import {useFindProgression} from '@src/Hooks/useFindProgression';
import dayjs from 'dayjs';
import {VideoPlayerScreenParams} from '@src/Navigation/type';
import {AppRoutesEnum} from '@src/Constant/routes';
import {SingleContentLoader} from 'design-system/src/Components/ContentLoader';
import SingleHeader from 'design-system/src/Widget/SingleHeader/SingleHeader.web';
import RelatedCategory from '@src/components/RelatedCategory/RelatedCategory';
import {View} from 'react-native';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import ShareDialog from 'design-system/src/Components/Dialog/ShareDialog/ShareDialog';
import PaywallChecker from '@src/Umbraco/Components/PaywallChecker/PaywallChecker';
import {useAppDispatch} from '@src/Store/hooks';
import appSlice from '@src/Store/Slices/AppSlice';
import CustomModalHeader from '@src/components/general/CustomModal/CustomModalHeader';
import useCastOnPage from '../hooks/useCastOnPage';
import useShareContent from '@src/Hooks/useShareContent';
import {ISavedResponse} from '@src/Api/TraceApi/endpoints/memberDataEndpoint/interface/memberDataResponse.interface';
import useParams from '@src/Hooks/useParams';

const VodSinglePage: FC<RouteComponentProps<{id: string}>> = () => {
  const {endUrl1, endUrl2} = useParams();
  const endUrl = `/${endUrl1}/${endUrl2}/`.replaceAll('//', '/');
  const {t} = useTranslation('translation');
  const navigation = useAppNavigation();
  const {isMobile} = useBreakpoint();
  const tracker = useTracker();
  const {parseDuration} = useDurationFactory();
  const {findBookmarkItem, updateBookMark} = useUpdateBookmarks({
    screenSource: 'detail',
  });

  const shareContent = useShareContent({screenSource: 'detail'});

  const response = useVodItemByUrlEndQuery({
    variables: {endUrl},
    errorPolicy: 'all',
    skip: !endUrl,
    fetchPolicy: 'cache-first',
  });

  const [isOpenDialogShare, setIsOpenDialogShare] = useState(false);

  const loading = response.loading;

  const {findCurrentTime, findDuration} = useFindProgression();

  const dispatch = useAppDispatch();

  function calculateWidth() {
    return window.innerWidth * (isMobile ? 1 : 0.8);
  }

  const {
    vodItem,
    listCategory,
    bookmarkItem,
  }: {
    vodItem: IVodItemFragmentFragment | undefined;
    listCategory: string[];
    bookmarkItem: ISavedResponse | undefined;
  } = useMemo(() => {
    const _vodItem = response?.data?.allVODItem?.items[0];
    const _list: string[] = [];
    const _bookmarkItem = findBookmarkItem(_vodItem?.id || '');
    if (_vodItem) {
      if (_vodItem.public) {
        if (typeof _vodItem.public === 'string') {
          _list.push(_vodItem.public);
        }
      }
      const _duration = findDuration(_vodItem.id);
      if (_vodItem.length || _duration) {
        const dur = parseDuration(_duration || _vodItem.length || 0);
        _list.push(dur);
      }

      if (_vodItem.releaseDate) {
        const year = dayjs(_vodItem.releaseDate).format('YYYY');

        if (year !== '1') {
          _list.push(year);
        }
      }
    }

    return {
      vodItem: _vodItem,
      listCategory: _list,
      bookmarkItem: _bookmarkItem,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, t]);

  useEffect(() => {
    dispatch(
      appSlice.actions.setModalHeader(
        <CustomModalHeader
          onGoBack={navigation.goBack}
          isCast={!!vodItem?.liveEventID}
        />,
      ),
    );
    return () => {
      dispatch(appSlice.actions.setModalHeader(null));
    };
  }, []);

  useCastOnPage({
    vimeoId: `${vodItem?.liveEventID}` || undefined,
    title: vodItem?.title,
    currentTime: 0,
  });

  const handleWatch = (item: IVodItemFragmentFragment) => () => {
    tracker.general.cTAClicked({
      type: 'banner',
      name: t('Watch') || '',
      section: 'watch',
      page: 'VOD single',
      blockType: 'highlight',
      blockName: item.title || '',
    });
    if (item) {
      let source: VideoPlayerScreenParams['source'] = 'vimeo';
      //   if (downloads) {
      //     let index = downloads.findIndex(data => data.id === item.id);
      //     if (index !== -1) {
      //       source = 'local';
      //     }
      //   }
      let time = findCurrentTime(item.id);
      //   if (castDevice) {
      //     handleSetContentCast({
      //       uri: async () => {
      //         const res = await store.dispatch(
      //           traceApi.endpoints.getVimeoVideo.initiate(item.liveEventID + ''),
      //         );
      //         if (res && res.data && res.data.hls) {
      //           return res.data.hls;
      //         }
      //         return '';
      //       },
      //     });
      //   } else {
      navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
        state: {
          params: {
            type: 'player',
            source,
            liveEventId: item.liveEventID ? item.liveEventID + '' : '',
            title: item.name || '',
            url: item.url || '',
            videoId: item.liveEventID,
            videoUrl: item.vimeoHlsUrl,
            time,
            paywallData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            id: item.id || '',
            previousScreen: 'content',
          },
        },
      });
    }
  };

  const openTrailer = (item: IVodItemFragmentFragment) => () => {
    if (item.trailer) {
      let time = findCurrentTime(item.id);
      // let source: VideoPlayerScreenParams['source'] = 'vimeo';
      //   if (downloads) {
      //     let index = downloads.findIndex(data => data.id === item.id);
      //     if (index !== -1) {
      //       source = 'local';
      //     }
      //   }
      //   if (castDevice) {
      //     handleSetContentCast({
      //       uri: async () => {
      //         const res = await store.dispatch(
      //           traceApi.endpoints.getVimeoVideo.initiate(item.liveEventID + ''),
      //         );
      //         if (res && res.data && res.data.hls) {
      //           return res.data.hls;
      //         }
      //         return '';
      //       },
      //     });
      //   } else {
      navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
        state: {
          params: {
            type: 'player',
            liveEventId: item.trailer,
            source: 'vimeo',
            // videoUrl: item.trailer,
            title: item.name || '',
            url: item.url || '',
            videoId: item.id,
            time,
            paywallData: {
              accessType: item.accessType,
              paywall: item.paywall,
            },
            id: item.id || '',
            previousScreen: 'content',
          },
        },
      });
    }
    // }
  };

  useEffect(() => {
    if (vodItem) {
      tracker.general.contentDetailPageViewed({
        content_id: vodItem.id,
        content_title: vodItem.title || '',
        content_type: 'VODItem',
        genre: vodItem.parent?.name || '',
        source: 'browse',
      });
    }
  }, [tracker.general, vodItem]);

  return (
    <PaywallChecker accessType={vodItem?.accessType} paywall={vodItem?.paywall}>
      <div>
        {loading ? (
          <SingleContentLoader />
        ) : (
          <div>
            <SingleHeader
              text={vodItem?.description || ''}
              listCategory={listCategory}
              variant="vod"
              image={
                vodItem && vodItem.thumbnail && vodItem.thumbnail.url
                  ? {uri: vodItem.thumbnail.url}
                  : undefined
              }
              title={vodItem?.title || ''}
              buttonTitle={t('Watch') || ''}
              seeMoreLabel={t('See more') + ''}
              seeLessLabel={t('See less') + ''}
              trailerLabel={t('Trailer') + ''}
              favoriLabel={t('Favoris') + ''}
              shareLabel={t('Share') + ''}
              onPressButton={vodItem && handleWatch(vodItem)}
              isTrailer={!!vodItem && !!vodItem.trailer}
              isLiked={!!bookmarkItem}
              onClickPlay={
                (!!vodItem && !!vodItem.trailer && openTrailer(vodItem)) ||
                undefined
              }
              onClickFav={(value: boolean) => {
                if (vodItem && vodItem.id && vodItem.url) {
                  updateBookMark(vodItem.id, vodItem.url, value, {
                    id: vodItem.id,
                    url: vodItem.url,
                    __typename: vodItem.__typename,
                    name: vodItem.title,
                    categoryName: vodItem.parent?.name,
                    raw: vodItem,
                  });
                }
              }}
              onClickShare={() => {
                if (vodItem && vodItem.url) {
                  shareContent.share({
                    __typename: vodItem.__typename,
                    url: vodItem.url,
                    message: vodItem.title,
                    id: vodItem.id,
                    category: vodItem.parent?.name,
                    cover:
                      vodItem.thumbnail && vodItem.thumbnail.url
                        ? vodItem.thumbnail.url
                        : '',
                    description:
                      vodItem.description || vodItem.shortDescription || '',
                    title: vodItem.title,
                    countryOfProduction: vodItem.productionCountry,
                  });
                  setIsOpenDialogShare(!isOpenDialogShare);
                }
              }}
              //   id={vodItem?.id || ''}
              //   liveEventID={vodItem?.liveEventID}
              //   isDownloaded={
              //     downloads?.findIndex(data => data.id === vodItem?.id) !== -1
              //   }
            />
            <View
              style={{
                marginTop: isMobile ? 26 : 56,
                width: calculateWidth(),
              }}>
              {!!vodItem && vodItem.id && <RelatedCategory id={vodItem.id} />}
            </View>
            <ShareDialog
              isOpen={isOpenDialogShare}
              onClose={() => setIsOpenDialogShare(false)}
            />
          </div>
        )}
      </div>
    </PaywallChecker>
  );
};

export default VodSinglePage;
