import {Form} from '@src/Api/Umbraco/interface/UmbracoTypes';
import UmbracoForm, {
  IUmbracoFormProps,
} from '@src/Umbraco/Components/UmbracoForm/UmbracoForm';
import Typography from 'design-system/src/Components/Typography/Typography';
import {View, Pressable} from 'react-native';
import React from 'react';
import FormHeader from './FormHeader/FormHeader';
import {useContentUrlByIdQuery} from '@src/Api/Graphql/gql/types.generated';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import {useDispatch} from 'react-redux';
import PageLoader from '@src/components/general/PageLoader/PageLoader';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {RouteTypename} from '@src/Navigation/type';
import {useIsListenMiniPlayer} from '@src/Store/selectors/useMember';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import useViewPort from 'design-system/src/Hooks/useViewPort';

interface IFormPageProps
  extends Pick<
    IUmbracoFormProps,
    | 'onSubmit'
    | 'onSuccess'
    | 'overrideSubmit'
    | 'additionnalFormData'
    | 'initialValue'
  > {
  form: Form;
  cover?: string;
  coverDesktop?: string;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  errors?: string[];
  thaksCloseTo?: string;
  disableSubmitTracking?: boolean;
}

const FormPage = (props: IFormPageProps) => {
  const {
    form,
    cover,
    coverDesktop,
    title,
    subtitle,
    onClose,
    onSuccess,
    errors,
    thaksCloseTo,
    disableSubmitTracking,
    ...rest
  } = props;
  const contentWidth = 487;
  const contentPadding = 16;
  const navigation = useAppNavigation();
  const dispatch = useDispatch();
  const {isMobile} = useBreakpoint();
  const gotoPageOnSubmit = form.gotoPageOnSubmit;
  const isMiniPlayer = useIsListenMiniPlayer();
  const {viewportHeight} = useViewPort();

  const {data: contentData, loading} = useContentUrlByIdQuery({
    variables: {
      id: gotoPageOnSubmit,
    },
    skip: !gotoPageOnSubmit,
  });

  const handleSuccess = () => {
    if (contentData && contentData.content) {
      const {id: _id, url, __typename} = contentData.content;
      new RouteFactory(
        {
          id: _id,
          url: url || '',
          __typename: __typename as RouteTypename,
        },
        navigation,
        dispatch,
      ).navigate({
        closeTo: thaksCloseTo,
      });
    } else {
      navigation.navigate(AppRoutesEnum.HOME);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        background: 'var(--background, #111216)',
        position: 'relative',
        overflow: isMobile ? 'scroll' : 'hidden',
        height: isMobile ? viewportHeight : '100%',
        margin: '0 auto',
        // marginTop: 40,
        minHeight: 'calc(100vh - 40px)',
        paddingBottom: 140,
      }}>
      {loading ? (
        <PageLoader />
      ) : (
        <UmbracoForm
          pageRootStyle={{
            width: '100%',
            maxWidth: 487,
            paddingHorizontal: contentPadding,
            marginVertical: '0',
            marginHorizontal: 'auto',
          }}
          disableSubmitTracking={disableSubmitTracking}
          form={form}
          hideTitle={true}
          renderHeader={({
            movePrev,
            currentStep,
            currentTitle,
            totalPage,
            isFinalPage,
          }) => {
            return (
              <View>
                <View
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: 'auto',
                  }}>
                  <FormHeader
                    cover={cover ? {uri: cover} : undefined}
                    coverDesktop={
                      coverDesktop ? {uri: coverDesktop} : undefined
                    }
                    // title={}
                  />
                </View>

                <View
                  style={{
                    // position: 'absolute',
                    left: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    // zIndex: 4,
                    width: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: contentWidth,
                    paddingHorizontal: contentPadding,
                    marginHorizontal: 'auto',
                    paddingTop: 111,
                  }}>
                  {/* <Pressable
                  onPress={() => {
                    if (currentStep > 0) {
                      movePrev();
                    } else {
                      navigation.goBack();
                    }
                  }}>
                  <Icon name="retour" />
                </Pressable> */}

                  <Typography
                    style={{marginBottom: 33, textAlign: 'center'}}
                    children={
                      !isFinalPage
                        ? `step ${currentStep + 1} of ${totalPage}`
                        : title || ''
                    }
                    variant="formTitle"
                  />
                  <Typography
                    children={subtitle || ''}
                    variant="body2"
                    style={{
                      textAlign: 'center',
                      marginBottom: isMobile ? 16 : 77,
                    }}
                  />
                  {/* <Pressable
                  onPress={() => {
                    // navigation.goBack();
                  }}>
                  <Icon name="close" />
                </Pressable> */}
                </View>
              </View>
            );
          }}
          onSuccess={onSuccess || handleSuccess}
          {...rest}
        />
      )}
      {errors && (
        <View style={{alignItems: 'center'}}>
          {errors.map(errorMessage => {
            return (
              <Typography
                variant="ui3"
                style={{marginTop: 10}}
                color={() => '#BE030A'}>
                {errorMessage}
              </Typography>
            );
          })}
        </View>
      )}
      {isMiniPlayer || (isMobile && <div style={{height: 100}} />)}
    </div>
  );
};

export default FormPage;
