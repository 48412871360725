import LearnRowCard from 'design-system/src/Components/Card/LearnCard/LearnRowCard/LearnRowCard.web';
import SearchLiveCardWeb from 'design-system/src/Components/Card/LiveCard/SearchLiveCard/SearchLiveCard.web';
import SearchCardWeb from 'design-system/src/Components/Card/SearchCard/SearchCard.web';
import React, {ReactNode, useCallback, useRef} from 'react';
import {RouteTypename} from '~Navigation/type';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import * as RNLocalize from 'react-native-localize';
import {
  IChannelFragmentFragment,
  ICollectionFragmentFragment,
  ILiveEventFragmentFragment,
  INewsFragmentFragment,
  IPaywallFragmentFragment,
  IPlaylistFragmentFragment,
  IPodcastFragmentFragment,
  IPodcastItemFragmentFragment,
  IRadioFragmentFragment,
  ISearchLearnQuery,
  ISearchListenQuery,
  ISearchWatchQuery,
  IVodItemFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import Metrics from 'design-system/src/Theme/Metrics';
import {Pressable, View} from 'react-native';
import usePaywal from './usePaywal';
import DateFactory from '~tools/DateFactory';
import useTracker from '~Services/Amplitude/hooks/useTracker';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  IAccessTypParam,
  IPaywallParam,
} from '~Umbraco/Components/PaywallChecker/PaywallChecker';
import {useMapCourseCateg} from '~Umbraco/Utils/content/mapCourseCategItems';
import {useAppDispatch} from '@src/Store/hooks';
import {useUpdateMemberSearch} from './useUpdateMemberSearch';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import Typography from 'design-system/src/Components/Typography/Typography.web';
import RowCard from 'design-system/src/Components/Card/RowCard/RowCard.web';
import PartnerCard from 'design-system/src/Components/Card/PartnerCard/PartnerCard';

interface IPageInfo {
  endCursor?: string;
  hasNextPage?: boolean;
}

interface IWatchPageInfo {
  vodPageInfo?: IPageInfo;
  channelPageInfo?: IPageInfo;
  collectionPageInfo?: IPageInfo;
  liveEventPageInfo?: IPageInfo;
  newsPageInfo?: IPageInfo;
}

interface IListenPageInfo {
  playlistPageInfo?: IPageInfo;
  podcastPageInfo?: IPageInfo;
  podcastItemPageInfo?: IPageInfo;
  radioPageInfo?: IPageInfo;
}

interface ILearnPageInfo {
  coursePageInfo: IPageInfo;
  jobPageInfo: IPageInfo;
  partnerPageInfo: IPageInfo;
  jobVacancyPageInfo: IPageInfo;
  jobHubPageInfo: IPageInfo;
}

export const initialWatchPageInfo: IWatchPageInfo = {
  vodPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  channelPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  collectionPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  liveEventPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  newsPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
};

export const initialListenPageInfo: IListenPageInfo = {
  playlistPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  podcastPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  podcastItemPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  radioPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
};

export const initialLearnPageInfo: ILearnPageInfo = {
  coursePageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  jobPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  jobVacancyPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  jobHubPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
  partnerPageInfo: {
    endCursor: undefined,
    hasNextPage: true,
  },
};

export const useMapSearchResult = () => {
  const navigation = useAppNavigation();

  const dispatch = useAppDispatch();

  const {isMobile} = useBreakpoint();

  const watchPageInfo = useRef(initialWatchPageInfo);
  const listenPageInfo = useRef(initialListenPageInfo);
  const learnPageInfo = useRef(initialLearnPageInfo);
  const {createMemberSearch} = useUpdateMemberSearch();
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();
  const tracker = useTracker();
  const {t} = useTranslation('translation');
  const {mapLearnItem} = useMapCourseCateg();

  const handleClickItem = (
    url: string,
    __typename: RouteTypename,
    title: string,
    id: string,
    index: number,
    raw: any,
    accessData?: {
      accessType?: IAccessTypParam;
      paywall?: IPaywallParam;
    },
    countryOfProduction?: Array<string | undefined>,
  ) => {
    tracker.general.searchResultClick({
      content_id: id,
      content_type: __typename,
      search_rank: index + 1,
      genre: raw.parent?.name || '',
      content_category: raw?.parent?.name || '',
      content_title: title,
      countryOfProduction,
    });
    if (accessData) {
      const canSee = canSeeContent(accessData.accessType);
      const canPreview = getPremiumPreviewSettings(
        accessData.paywall as IPaywallFragmentFragment,
      )?.canPreview;
      if (canSee || canPreview) {
        new RouteFactory(
          {url, __typename, id},
          navigation,
          dispatch,
          title,
        ).navigate();
      } else {
        navigation.navigate(AppRoutesEnum.PAYWALL, {
          state: {
            params: {
              headerTitle: title || '',
              paywall: accessData.paywall,
              id: 'default',
            },
          },
        });
      }
    } else {
      new RouteFactory(
        {url, __typename, id},
        navigation,
        dispatch,
        title,
      ).navigate();
    }
  };

  const handleWatch = (channel: IChannelFragmentFragment, index: number) => {
    tracker.general.searchResultClick({
      content_id: channel.id,
      content_type: channel.__typename,
      search_rank: index + 1,
      content_category: channel.parent?.name || '',
      content_title: channel.title || '',
    });
    const canSee = canSeeContent(channel.accessType);
    const canPreview = getPremiumPreviewSettings(
      channel.paywall as IPaywallFragmentFragment,
    )?.canPreview;
    if (canSee || canPreview) {
      navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
        state: {
          params: {
            id: channel.id,
            type: 'player',
            source: 'livestream',
            url: channel.url || '',
            title: channel.title || 'Channel',
            liveEventId: channel.liveEventID || '',
            paywallData: {
              accessType: channel.accessType,
              paywall: channel.paywall,
            },
            previousScreen: 'search',
          },
        },
      });
    } else {
      navigation.navigate(AppRoutesEnum.PAYWALL, {
        state: {
          params: {
            headerTitle: channel.title || 'Channel',
            paywall: channel.paywall,
            id: 'default',
          },
        },
      });
    }
  };

  const isInRestrictedCountries = (
    countriesRestriction?: (string | undefined)[],
  ) => {
    if (countriesRestriction) {
      for (let i = 0; i < countriesRestriction.length; i++) {
        const [, country] = (countriesRestriction[i] || '').split('|');
        if (
          countriesRestriction[i] &&
          country.trim().toLowerCase() === RNLocalize.getCountry().toLowerCase()
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const mapSearchLearnData = useCallback(
    (data: ISearchLearnQuery, keyWord: string, lastResult?: ReactNode[]) => {
      let resultBlocks: ReactNode[] = lastResult || [];

      if (data) {
        const {
          allCourse,
          allJobTraining,
          allPartner,
          allJobVacancy,
          allJobHub,
        } = data;
        if (allCourse?.edges?.length > 0 || allJobTraining?.edges?.length > 0) {
          createMemberSearch(keyWord);
        }
        // 1=============================
        if (
          allPartner &&
          allPartner.edges &&
          learnPageInfo.current.partnerPageInfo.hasNextPage
        ) {
          if (allPartner.pageInfo) {
            learnPageInfo.current = {
              ...learnPageInfo.current,
              partnerPageInfo: {
                hasNextPage: allPartner.pageInfo.hasNextPage,
                endCursor: allPartner.pageInfo.endCursor,
              },
            };
          }
          allPartner.edges.forEach((edge, index) => {
            if (edge && edge.node) {
              const item = edge.node;
              if (item) {
                let {title, description, id, __typename, url} = item;
                resultBlocks.push(
                  <Pressable
                    key={`partner-${index}`}
                    style={{
                      marginBottom: Metrics.verticalScale(12),
                    }}
                    onPress={() => {
                      handleClickItem(
                        url || '',
                        __typename,
                        title || '',
                        id,
                        index,
                        item,
                      );
                    }}>
                    <RowCard
                      left={
                        <PartnerCard
                          key={index}
                          partenerLogo={
                            item.logo?.url
                              ? {
                                  uri: item.logo.url,
                                }
                              : undefined
                          }
                        />
                      }
                      right={
                        <View>
                          <Typography
                            children={title || ''}
                            numberOfLines={1}
                            variant="h3"
                            style={{
                              flexShrink: 1,
                            }}
                          />
                          <Typography
                            numberOfLines={3}
                            children={description || ''}
                            variant="body3"
                            color={() => '#A0A0A0'}
                            style={{
                              marginTop: Metrics.verticalScale(18),
                            }}
                          />
                        </View>
                      }
                    />
                  </Pressable>,
                );
              }
            }
          });
        }
        // ===============================

        // 2=============================
        if (
          allJobVacancy &&
          allJobVacancy.edges &&
          learnPageInfo.current.jobVacancyPageInfo.hasNextPage
        ) {
          if (allJobVacancy.pageInfo) {
            learnPageInfo.current = {
              ...learnPageInfo.current,
              jobVacancyPageInfo: {
                hasNextPage: allJobVacancy.pageInfo.hasNextPage,
                endCursor: allJobVacancy.pageInfo.endCursor,
              },
            };
          }
          allJobVacancy.edges.forEach((edge, index) => {
            if (edge && edge.node) {
              const item = edge.node;
              if (item) {
                const {
                  title,
                  thumbnail,
                  shortDescription,
                  __typename,
                  url,
                  id,
                  link,
                } = item;
                resultBlocks.push(
                  <Pressable
                    key={`jobVac-${index}`}
                    style={{
                      marginBottom: Metrics.verticalScale(12),
                    }}
                    onPress={() => {
                      handleClickItem(
                        link || '',
                        __typename,
                        title || '',
                        id,
                        index,
                        item,
                      );
                    }}>
                    <RowCard
                      left={
                        <CustomFastImage
                          width={Metrics.horizontalScale(80)}
                          height={Metrics.horizontalScale(80)}
                          source={{
                            uri: thumbnail?.url,
                          }}
                          style={{
                            borderRadius: Metrics.horizontalScale(4),
                          }}
                        />
                      }
                      right={
                        <View>
                          <Typography
                            children={title || ''}
                            numberOfLines={1}
                            variant="h3"
                            style={{
                              flexShrink: 1,
                            }}
                          />
                          <Typography
                            numberOfLines={3}
                            children={shortDescription || ''}
                            variant="body3"
                            color={() => '#A0A0A0'}
                            style={{
                              marginTop: Metrics.verticalScale(18),
                            }}
                          />
                        </View>
                      }
                    />
                  </Pressable>,
                );
              }
            }
          });
        }
        // ===============================

        // 3 =============================
        if (
          allJobHub &&
          allJobHub.edges &&
          learnPageInfo.current.jobHubPageInfo.hasNextPage
        ) {
          if (allJobHub.pageInfo) {
            learnPageInfo.current = {
              ...learnPageInfo.current,
              jobHubPageInfo: {
                hasNextPage: allJobHub.pageInfo.hasNextPage,
                endCursor: allJobHub.pageInfo.endCursor,
              },
            };
          }
          allJobHub.edges.forEach((edge, index) => {
            if (edge && edge.node) {
              const item = edge.node;
              if (item) {
                let {
                  title,
                  id,
                  __typename,
                  url,
                  thumbnail,
                  longDescription,
                  link,
                } = item;
                resultBlocks.push(
                  <Pressable
                    key={`jobHub-${index}`}
                    style={{
                      marginBottom: Metrics.verticalScale(12),
                    }}
                    onPress={() => {
                      handleClickItem(
                        link || '',
                        __typename,
                        title || '',
                        id,
                        index,
                        item,
                      );
                    }}>
                    <RowCard
                      left={
                        <CustomFastImage
                          width={Metrics.horizontalScale(80)}
                          height={Metrics.horizontalScale(80)}
                          source={{
                            uri: thumbnail?.url,
                          }}
                          wrapperStyles={{
                            minWidth: Metrics.horizontalScale(80),
                          }}
                          style={{
                            borderRadius: Metrics.horizontalScale(4),
                          }}
                        />
                      }
                      right={
                        <View
                          style={{
                            flexShrink: 1,
                          }}>
                          <Typography
                            children={title || ''}
                            numberOfLines={1}
                            variant="h3"
                            style={{
                              flexShrink: 1,
                            }}
                          />
                          <Typography
                            numberOfLines={3}
                            children={longDescription || ''}
                            variant="body3"
                            color={() => '#A0A0A0'}
                            style={{
                              marginTop: Metrics.verticalScale(18),
                            }}
                          />
                        </View>
                      }
                    />
                  </Pressable>,
                );
              }
            }
          });
        }
        if (
          allCourse &&
          allCourse.edges &&
          learnPageInfo.current.coursePageInfo.hasNextPage
        ) {
          if (allCourse.pageInfo) {
            learnPageInfo.current = {
              ...learnPageInfo.current,
              coursePageInfo: {
                hasNextPage: allCourse.pageInfo.hasNextPage,
                endCursor: allCourse.pageInfo.endCursor,
              },
            };
          }
          allCourse.edges.forEach((edge, index) => {
            if (edge && edge.node && edge.node.__typename === 'Course') {
              const course = mapLearnItem(edge.node as any);
              if (course) {
                let {
                  title,
                  description,
                  cover,
                  duration,
                  id,
                  __typename,
                  url,
                  progress,
                  tag,
                  isDone: _isDone,
                  partenerLogo,
                } = course;
                resultBlocks.push(
                  <View
                    key={`course-${index}`}
                    style={{
                      marginBottom: Metrics.verticalScale(12),
                    }}>
                    <LearnRowCard
                      title={title}
                      description={description}
                      cover={cover}
                      duration={duration}
                      partenerLogo={partenerLogo}
                      onPress={() => {
                        handleClickItem(
                          url ?? '',
                          __typename,
                          title ?? '-',
                          id || '',
                          index,
                          allCourse.edges[index] || {},
                        );
                      }}
                      progress={progress}
                      tag={tag}
                      isDone={_isDone}
                      textDone={course?.textDone}
                    />
                  </View>,
                );
              }
            }
          });
        }

        if (
          allJobTraining &&
          allJobTraining.edges &&
          learnPageInfo.current.jobPageInfo.hasNextPage
        ) {
          if (allJobTraining.pageInfo) {
            learnPageInfo.current = {
              ...learnPageInfo.current,
              jobPageInfo: {
                hasNextPage: allJobTraining.pageInfo.hasNextPage,
                endCursor: allJobTraining.pageInfo.endCursor,
              },
            };
          }
          allJobTraining.edges.forEach((edge, index) => {
            if (edge && edge.node) {
              const job = mapLearnItem(edge.node);
              if (job) {
                let {
                  title,
                  description,
                  cover,
                  duration,
                  id,
                  __typename,
                  url,
                  progress,
                  tag,
                  partenerLogo,
                } = job;
                resultBlocks.push(
                  <View
                    key={`job-${index}`}
                    style={{
                      marginBottom: Metrics.verticalScale(12),
                    }}>
                    <LearnRowCard
                      title={title}
                      description={description}
                      cover={cover}
                      duration={duration}
                      partenerLogo={partenerLogo}
                      onPress={() => {
                        handleClickItem(
                          url ?? '',
                          __typename,
                          title ?? '-',
                          id || '',
                          index,
                          allCourse.edges[index] || {},
                        );
                      }}
                      progress={progress}
                      tag={tag}
                    />
                  </View>,
                );
              }
            }
          });
        }
      }

      return resultBlocks;
    },
    [learnPageInfo.current, mapLearnItem],
  );

  const mapSearchListenData = (
    data: ISearchListenQuery,
    keyWord: string,
    lastResult?: ReactNode[],
  ) => {
    let listenResultBlocks: ReactNode[] = [];

    if (lastResult) {
      listenResultBlocks = [...lastResult];
    }

    if (data) {
      const {allPlaylist, allPodcast, allPodcastItem, allRadio} = data;
      if (
        allPlaylist?.edges?.length > 0 ||
        allPodcast?.edges?.length > 0 ||
        allPodcastItem?.edges?.length > 0 ||
        allRadio?.edges?.length > 0
      ) {
        createMemberSearch(keyWord);
      }
      if (
        allPlaylist &&
        allPlaylist.edges &&
        listenPageInfo.current.playlistPageInfo?.hasNextPage
      ) {
        if (allPlaylist.pageInfo) {
          listenPageInfo.current = {
            ...listenPageInfo.current,
            playlistPageInfo: {
              hasNextPage: allPlaylist.pageInfo.hasNextPage,
              endCursor: allPlaylist.pageInfo.endCursor,
            },
          };
        }
        allPlaylist.edges.map((playlist: any, index: number) => {
          if (playlist && playlist.__typename === 'PlaylistEdge') {
            let {node} = playlist;
            if (node && node.__typename === 'Playlist') {
              let {
                title,
                subtitle,
                thumbnail,
                url,
                id,
                __typename,
                countriesRestriction,
              } = node as IPlaylistFragmentFragment;
              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                listenResultBlocks.push(
                  <SearchCardWeb
                    premiumText={t('premium')}
                    key={`playlist-${index}-${id}`}
                    title={title ?? '-'}
                    size={isMobile ? 'xsmall' : 'xsmall_w'}
                    fullWidth={!isMobile}
                    subtitle={subtitle ?? '-'}
                    cover={{
                      uri: thumbnail?.url,
                    }}
                    onPress={() => {
                      handleClickItem(
                        url ?? '',
                        __typename,
                        title ?? '-',
                        id,
                        index,
                        node,
                      );
                    }}
                  />,
                );
              }
            }
          }
        });
      }
      if (
        allPodcast &&
        allPodcast.edges &&
        listenPageInfo.current.podcastPageInfo?.hasNextPage
      ) {
        if (allPodcast.pageInfo) {
          listenPageInfo.current = {
            ...listenPageInfo.current,
            podcastPageInfo: {
              hasNextPage: allPodcast.pageInfo.hasNextPage,
              endCursor: allPodcast.pageInfo.endCursor,
            },
          };
        }
        allPodcast.edges.map((podcast: any, index: number) => {
          if (podcast && podcast.__typename === 'PodcastEdge') {
            let {node} = podcast;
            if (node && node.__typename === 'Podcast') {
              let {
                title,
                subtitle,
                thumbnail,
                url,
                id,
                __typename,
                countriesRestriction,
              } = node as IPodcastFragmentFragment;
              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                listenResultBlocks.push(
                  <SearchCardWeb
                    fullWidth={!isMobile}
                    premiumText={t('premium')}
                    key={`podcast-${index}-${id}`}
                    size={isMobile ? 'xsmall' : 'xsmall_w'}
                    title={title ?? '-'}
                    subtitle={subtitle ?? '-'}
                    cover={{
                      uri: thumbnail?.url,
                    }}
                    onPress={() => {
                      handleClickItem(
                        url ?? '',
                        __typename,
                        title ?? '-',
                        id,
                        index,
                        node,
                      );
                    }}
                  />,
                );
              }
            }
          }
        });
      }
      if (
        allPodcastItem &&
        allPodcastItem.edges &&
        listenPageInfo.current.podcastItemPageInfo?.hasNextPage
      ) {
        if (allPodcastItem.pageInfo) {
          listenPageInfo.current = {
            ...listenPageInfo.current,
            podcastItemPageInfo: {
              hasNextPage: allPodcastItem.pageInfo.hasNextPage,
              endCursor: allPodcastItem.pageInfo.endCursor,
            },
          };
        }
        allPodcastItem.edges.map((podcastItem: any, index: number) => {
          if (podcastItem && podcastItem.__typename === 'PodcastItemEdge') {
            let {node} = podcastItem;
            if (node && node.__typename === 'PodcastItem') {
              let {title, description, thumbnail, url, id, __typename} =
                node as IPodcastItemFragmentFragment;
              listenResultBlocks.push(
                <SearchCardWeb
                  fullWidth={!isMobile}
                  premiumText={t('premium')}
                  key={`podcastItem-${index}-${id}`}
                  size={isMobile ? 'xsmall' : 'xsmall_w'}
                  title={title ?? '-'}
                  subtitle={description ?? '-'}
                  cover={{
                    uri: thumbnail?.url,
                  }}
                  onPress={() => {
                    handleClickItem(
                      url ?? '',
                      __typename,
                      title ?? '-',
                      id,
                      index,
                      node,
                    );
                  }}
                />,
              );
            }
          }
        });
      }
      if (
        allRadio &&
        allRadio.edges &&
        listenPageInfo.current.radioPageInfo?.hasNextPage
      ) {
        if (allRadio.pageInfo) {
          listenPageInfo.current = {
            ...listenPageInfo.current,
            radioPageInfo: {
              hasNextPage: allRadio.pageInfo.hasNextPage,
              endCursor: allRadio.pageInfo.endCursor,
            },
          };
        }
        allRadio.edges.map((radio: any, index: number) => {
          if (radio && radio.__typename === 'RadioEdge') {
            let {node} = radio;
            if (node && node.__typename === 'Radio') {
              let {
                title,
                subtitle,
                thumbnail,
                url,
                id,
                __typename,
                countriesRestriction,
              } = node as IRadioFragmentFragment;
              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                listenResultBlocks.push(
                  <SearchCardWeb
                    fullWidth={!isMobile}
                    premiumText={t('premium')}
                    key={`radio-${index}-${id}`}
                    title={title ?? '-'}
                    size={isMobile ? 'xsmall' : 'xsmall_w'}
                    subtitle={subtitle ?? '-'}
                    cover={{
                      uri: thumbnail?.url,
                    }}
                    onPress={() => {
                      handleClickItem(
                        url ?? '',
                        __typename,
                        title ?? '-',
                        id,
                        index,
                        node,
                      );
                    }}
                  />,
                );
              }
            }
          }
        });
      }
    }
    return listenResultBlocks;
  };

  const hasSearchListenNextPage = () => {
    return (
      listenPageInfo.current.playlistPageInfo?.hasNextPage ||
      listenPageInfo.current.podcastPageInfo?.hasNextPage ||
      listenPageInfo.current.podcastItemPageInfo?.hasNextPage ||
      listenPageInfo.current.radioPageInfo?.hasNextPage
    );
  };

  const hasSearchWatchNextPage = () => {
    return (
      watchPageInfo.current.channelPageInfo?.hasNextPage ||
      watchPageInfo.current.collectionPageInfo?.hasNextPage ||
      watchPageInfo.current.liveEventPageInfo?.hasNextPage ||
      watchPageInfo.current.newsPageInfo?.hasNextPage ||
      watchPageInfo.current.vodPageInfo?.hasNextPage
    );
  };

  const hasSearchLearnNextPage = () => {
    return (
      learnPageInfo.current.coursePageInfo.hasNextPage ||
      learnPageInfo.current.jobPageInfo.hasNextPage
    );
  };

  const mapSearchWatchData = (
    data: ISearchWatchQuery,
    keyWord: string,
    lastResult?: ReactNode[],
  ) => {
    let watchResultBlocks: ReactNode[] = [];
    if (lastResult) {
      watchResultBlocks = [...lastResult];
    }

    if (data) {
      const {allChannel, allCollection, allLiveEvent, allNews, allVODItem} =
        data;
      if (
        allChannel?.edges?.length > 0 ||
        allCollection?.edges?.length > 0 ||
        allLiveEvent?.edges?.length > 0 ||
        allNews?.edges?.length > 0 ||
        allVODItem?.edges?.length > 0
      ) {
        createMemberSearch(keyWord);
      }
      if (
        allChannel &&
        allChannel.edges &&
        watchPageInfo.current.channelPageInfo?.hasNextPage
      ) {
        if (allChannel.pageInfo) {
          watchPageInfo.current = {
            ...watchPageInfo.current,
            channelPageInfo: {
              hasNextPage: allChannel.pageInfo.hasNextPage,
              endCursor: allChannel.pageInfo.endCursor,
            },
          };
        }
        allChannel.edges.map((channel: any, index: number) => {
          if (channel && channel.__typename === 'ChannelEdge') {
            let {node} = channel;
            if (node && node.__typename === 'Channel') {
              const {
                title,
                shortDescription,
                logo,
                thumbnail,
                id,
                countriesRestriction,
              } = node as IChannelFragmentFragment;
              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                watchResultBlocks.push(
                  <View
                    style={{marginBottom: Metrics.verticalScale(11)}}
                    key={`channel-${index}-${id}`}>
                    <SearchLiveCardWeb
                      onPress={() => {
                        handleWatch(node, index);
                      }}
                      premiumText={t('premium')}
                      title={title ?? '-'}
                      subtitle={shortDescription ?? '-'}
                      cover={{uri: thumbnail?.url}}
                      fullWidth={!isMobile}
                      aspectRatio={isMobile ? undefined : 254 / 142.5}
                      channelLogo={() => (
                        <CustomFastImage
                          source={{uri: logo?.url || ''}}
                          width={Metrics.horizontalScale(40)}
                          height={Metrics.verticalScale(28)}
                          backgroundFallbackColor="transparent"
                        />
                      )}
                      isFreemium={canSeeContent(node.accessType)}
                    />
                  </View>,
                );
              }
            }
          }
        });
      }
      if (
        allCollection &&
        allCollection.edges &&
        watchPageInfo.current.collectionPageInfo?.hasNextPage
      ) {
        if (allCollection.pageInfo) {
          watchPageInfo.current = {
            ...watchPageInfo.current,
            collectionPageInfo: {
              hasNextPage: allCollection.pageInfo.hasNextPage,
              endCursor: allCollection.pageInfo.endCursor,
            },
          };
        }
        allCollection.edges.map((collection: any, index: number) => {
          if (collection && collection.__typename === 'CollectionEdge') {
            let {node} = collection;
            if (node && node.__typename === 'Collection') {
              let {
                title,
                subtitle,
                thumbnail,
                url,
                id,
                __typename,
                countriesRestriction,
                accessType,
                countryOfProduction,
              } = node as ICollectionFragmentFragment;
              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                watchResultBlocks.push(
                  <SearchCardWeb
                    fullWidth={!isMobile}
                    premiumText={t('premium')}
                    key={`collection-${index}-${id}`}
                    title={title ?? '-'}
                    size={isMobile ? 'xsmall' : 'xsmall_w'}
                    subtitle={subtitle ?? '-'}
                    cover={{
                      uri: thumbnail?.url,
                    }}
                    onPress={() => {
                      handleClickItem(
                        url ?? '',
                        __typename,
                        title ?? '-',
                        id,
                        index,
                        node,
                        {
                          accessType,
                        },
                        countryOfProduction,
                      );
                    }}
                    isFreemium={canSeeContent(node.accessType)}
                  />,
                );
              }
            }
          }
        });
      }
      if (
        allLiveEvent &&
        allLiveEvent.edges &&
        watchPageInfo.current.liveEventPageInfo?.hasNextPage
      ) {
        if (allLiveEvent.pageInfo) {
          watchPageInfo.current = {
            ...watchPageInfo.current,
            liveEventPageInfo: {
              hasNextPage: allLiveEvent.pageInfo.hasNextPage,
              endCursor: allLiveEvent.pageInfo.endCursor,
            },
          };
        }
        allLiveEvent.edges.map((liveEvent: any, index: number) => {
          if (liveEvent && liveEvent.__typename === 'LiveEventEdge') {
            let {node} = liveEvent;
            if (node && node.__typename === 'LiveEvent') {
              let {
                title,
                subtitle,
                thumbnail,
                url,
                id,
                __typename,
                countriesRestriction,
              } = node as ILiveEventFragmentFragment;
              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                watchResultBlocks.push(
                  <SearchCardWeb
                    fullWidth={!isMobile}
                    premiumText={t('premium')}
                    key={`liveEvent-${index}-${id}`}
                    title={title ?? '-'}
                    size={isMobile ? 'xsmall' : 'xsmall_w'}
                    subtitle={subtitle ?? '-'}
                    cover={{
                      uri: thumbnail?.url,
                    }}
                    onPress={() => {
                      handleClickItem(
                        url ?? '',
                        __typename,
                        title ?? '-',
                        id,
                        index,
                        node,
                        {
                          accessType: node.accessType,
                          paywall: node.paywall,
                        },
                      );
                    }}
                    isFreemium={canSeeContent(node.accessType)}
                  />,
                );
              }
            }
          }
        });
      }
      if (
        allNews &&
        allNews.edges &&
        watchPageInfo.current.newsPageInfo?.hasNextPage
      ) {
        if (allNews.pageInfo) {
          watchPageInfo.current = {
            ...watchPageInfo.current,
            newsPageInfo: {
              hasNextPage: allNews.pageInfo.hasNextPage,
              endCursor: allNews.pageInfo.endCursor,
            },
          };
        }
        allNews.edges.map((news: any, index: number) => {
          if (news && news.__typename === 'NewsEdge') {
            let {node} = news;
            if (node && node.__typename === 'News') {
              const {
                title,
                thumbnail,
                shortDescription,
                pubDate,
                url,
                id,
                __typename,
                accessType,
                paywall,
                countriesRestriction,
              } = node as INewsFragmentFragment;
              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                watchResultBlocks.push(
                  <SearchCardWeb
                    fullWidth={!isMobile}
                    premiumText={t('premium')}
                    key={`news-${index}-${id}`}
                    title={title ?? '-'}
                    subtitle={shortDescription ?? '-'}
                    cover={
                      thumbnail
                        ? {
                            uri: thumbnail.url,
                          }
                        : undefined
                    }
                    size={'xsmall-news'}
                    extraContent={
                      pubDate
                        ? [
                            {
                              type: 'date',
                              date: new DateFactory(pubDate).format(),
                            },
                          ]
                        : undefined
                    }
                    onPress={() => {
                      handleClickItem(
                        url ?? '',
                        __typename,
                        title ?? '-',
                        id,
                        index,
                        node,
                      );
                    }}
                    accessData={{
                      accessType: accessType,
                      paywall: paywall,
                    }}
                  />,
                );
              }
            }
          }
        });
      }
      if (
        allVODItem &&
        allVODItem.edges &&
        watchPageInfo.current.vodPageInfo?.hasNextPage
      ) {
        if (allVODItem.pageInfo) {
          watchPageInfo.current = {
            ...watchPageInfo.current,
            vodPageInfo: {
              hasNextPage: allVODItem.pageInfo.hasNextPage,
              endCursor: allVODItem.pageInfo.endCursor,
            },
          };
        }
        allVODItem.edges.map((vodItem: any, index: number) => {
          if (vodItem && vodItem.__typename === 'VODItemEdge') {
            let {node} = vodItem;
            if (node && node.__typename === 'VODItem') {
              let {
                title,
                subtitle,
                thumbnail,
                url,
                id,
                __typename,
                countriesRestriction,
              } = node as IVodItemFragmentFragment;

              const parentAccessType =
                node.parent && node.parent.__typename === 'Collection'
                  ? node.accessType
                  : undefined;

              if (
                !countriesRestriction ||
                !isInRestrictedCountries(countriesRestriction)
              ) {
                watchResultBlocks.push(
                  <SearchCardWeb
                    fullWidth={!isMobile}
                    premiumText={t('premium')}
                    key={`vod-${index}-${id}`}
                    title={title ?? '-'}
                    subtitle={subtitle ?? '-'}
                    cover={{uri: thumbnail?.url}}
                    size={isMobile ? 'xsmall' : 'xsmall_w'}
                    onPress={() => {
                      handleClickItem(
                        url ?? '',
                        __typename,
                        title ?? '-',
                        id,
                        index,
                        node,
                        {
                          accessType: node.accessType,
                          paywall: node.paywall,
                        },
                      );
                    }}
                    isFreemium={
                      canSeeContent(node.accessType) &&
                      canSeeContent(parentAccessType)
                    }
                  />,
                );
              }
            }
          }
        });
      }
    }

    return watchResultBlocks;
  };

  return {
    watchPageInfo,
    listenPageInfo,
    learnPageInfo,
    mapSearchWatchData,
    mapSearchListenData,
    hasSearchWatchNextPage,
    hasSearchListenNextPage,
    hasSearchLearnNextPage,
    mapSearchLearnData,
    isMobile,
  };
};
