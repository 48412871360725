import {
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  Animated,
  // FlatList,
  Pressable,
  FlatListProps,
} from 'react-native';
import React, {useRef, useEffect, useCallback} from 'react';
import Metrics from 'design-system/src/Theme/Metrics';
// import {useLazyGetTvGuideXmlQuery} from '~Api/FreeApi/freeApi';
import {IChannelFragmentFragment, IPaywallFragmentFragment} from '~Api/Graphql/gql/types.generated';
import TVGuideElement from './TVGuideElement/TVGuideElement';
import TvGuideLeadingCard from 'design-system/src/Components/Card/LiveCard/TvGuideLeadingCard/TvGuideLeadingCard';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import LinearGradient from 'react-native-linear-gradient';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Typography from 'design-system/src/Components/Typography/Typography';
import usePaywal from '~Hooks/usePaywal';
import {ITvGuideItem} from '~Models/TvModel';
import {useAppSelector} from '@src/Store/hooks';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import useOnScreen from 'design-system/src/Hooks/useOnScreen';
import {useLazyChannelProgramQuery} from '@src/Api/TraceApi/traceApi';
import CustomSwipeable from 'design-system/src/Components/CustomSwipeable/CustomSwipeable';

interface ITvGuideRowProps {
  item: IChannelFragmentFragment;
  index?: number;
  style?: StyleProp<ViewStyle>;
  start?: Date;
}

const getItemLayout: FlatListProps<ITvGuideItem>['getItemLayout'] = (
  _,
  index,
) => ({
  length: Metrics.horizontalScale(170),
  offset: Metrics.horizontalScale(170) * index,
  index,
});

const keyExtractor: FlatListProps<ITvGuideItem>['keyExtractor'] = item =>
  `tv-row-${item.start}`;

const TvGuideRow = (props: ITvGuideRowProps) => {
  const {item} = props;
  const {t} = useTranslation('translation');
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();
  const AnimatedHeaderValue = useRef(new Animated.Value(0)).current;
  const {logo, name, id, epg} = item;
  const guide = useAppSelector(({channel}) => channel.guide[item.id] || []);

  const [fetchXmlFile, {isLoading}] = useLazyChannelProgramQuery();

  const navigation = useAppNavigation();

  const [rootRef, isOnScreen] = useOnScreen<HTMLDivElement>(undefined, {
    ignoreReverse: true,
  });

  useEffect(() => {
    if (epg && isOnScreen) {
      fetchXmlFile({channelId: id, url: epg}, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, epg, isOnScreen]);

  const animatedOpactiy = AnimatedHeaderValue.interpolate({
    inputRange: [0, 20],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const handleWatch = useCallback(
    (channel: IChannelFragmentFragment) => () => {
      const canSee = canSeeContent(channel.accessType);
      const canPreview = getPremiumPreviewSettings(
        channel.paywall as IPaywallFragmentFragment,
      )?.canPreview;
      if (canSee || canPreview) {
        navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
          state: {
            params: {
              type: 'player',
              source: 'livestream',
              url: channel.url || '',
              title: channel.title || 'Channel',
              liveEventId: channel.liveEventID || '',
              paywallData: {
                accessType: channel.accessType,
                paywall: channel.paywall,
              },
              id: channel.id,
              previousScreen: 'tv guide',
            },
          },
        });
      } else {
        navigation.navigate(AppRoutesEnum.PAYWALL, {
          state: {
            params: {
              headerTitle: channel.title || 'Channel',
              paywall: channel.paywall,
              id: 'default',
            },
          },
        });
      }
    },
    [canSeeContent, getPremiumPreviewSettings, navigation],
  );

  const renderItem = useCallback(
    ({item: el, index}: {item: ITvGuideItem; index: number}) => {
      return (
        <TVGuideElement
          onPress={handleWatch(item)}
          data={el}
          index={index}
          style={[
            index > 0 ? tvGuideRowStyle.item : undefined,
            {
              // @ts-ignore
              userSelect: 'none',
              WebkitUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
            },
          ]}
        />
      );
    },
    [handleWatch],
  );

  return (
    <div style={tvGuideRowStyle.root} ref={rootRef}>
      <View style={{zIndex: 3}}>
        <Pressable
          onPress={handleWatch(item)}
          style={{
            marginBottom: Metrics.verticalScale(8),
            marginRight: Metrics.verticalScale(9),
          }}>
          <TvGuideLeadingCard
            channelLogo={() => (
              <CustomFastImage
                source={{uri: logo?.url || ''}}
                width={Metrics.horizontalScale(68)}
                height={Metrics.verticalScale(52)}
                backgroundFallbackColor="transparent"
              />
            )}
            channel={name || ''}
          />
        </Pressable>
      </View>
      <View style={{flex: 1, overflow: 'hidden'}}>
        <CustomSwipeable>
          {/* <div style={{display: 'flex', flexDirection: 'row'}}> */}
          {guide.length === 0 ? (
            <div
              style={{display: 'flex', alignItems: 'center', height: '100%'}}>
              {isLoading ? (
                <CustomActivityIndicator size="small" />
              ) : isOnScreen ? (
                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: Metrics.horizontalScale(170),
                    backgroundColor: '#28292E',
                  }}>
                  <Typography
                    style={{
                      textAlign: 'center',
                    }}
                    variant="body2">
                    {t('Not available')}
                  </Typography>
                </View>
              ) : null}
            </div>
          ) : (
            guide.map((el, index) => renderItem({item: el, index}))
          )}
          {/* </div> */}
        </CustomSwipeable>
        <Animated.View
          style={{
            position: 'absolute',
            left: 0,
            opacity: animatedOpactiy,
          }}>
          <LinearGradient
            colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)']}
            useAngle={true}
            angle={270}
            locations={[0, 1]}
            style={{
              width: Metrics.horizontalScale(20),
              height: Metrics.horizontalScale(96),
            }}
          />
        </Animated.View>
      </View>
    </div>
  );
};

const tvGuideRowStyle = StyleSheet.create({
  root: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    display: 'flex',
  },
  listRoot: {
    height: Metrics.verticalScale(96),
    marginBottom: Metrics.verticalScale(8),
  },
  item: {
    marginLeft: Metrics.horizontalScale(8),
  },
});

export default React.memo(TvGuideRow);
