import React, {Suspense, useEffect, useMemo, useState} from 'react';
import {Animated, View} from 'react-native';
import useScrollAnimation from '~Hooks/useScrollAnimation';
import Metrics from 'design-system/src/Theme/Metrics';
import {
  ILiveEventFragmentFragment,
  IPaywallFragmentFragment,
  useLiveEventsPageLazyQuery,
} from '~Api/Graphql/gql/types.generated';
import DateFactory from '~tools/DateFactory';
import dayjs from 'dayjs';
// import Header from '~Components/Header/Header';
// import {StackHeaderProps} from '@react-navigation/stack';
import Typography from 'design-system/src/Components/Typography/Typography';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import usePaywal from '~Hooks/usePaywal';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import PaddingContainer from '@src/App/components/Containers/PaddingContainer/PaddingContainer';
import {toast} from 'react-toastify';
import useMemberNotify from '@src/Hooks/useMemberNotify';
import {useRegionUrl} from '@src/Store/selectors/useRegionUrl';
import {useMemberDatas} from '@src/Store/selectors/useMember';
import {useLocation} from '@reach/router';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';
import {ITabBarHeaderItem} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import {useAppDispatch} from '@src/Store/hooks';
import appSlice from '@src/Store/Slices/AppSlice';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import SuspenseAfterRequired from 'design-system/src/library/AfterRequired/SuspenseAfterRequired';

const LiveEventHeader = React.lazy(
  () =>
    import(
      'design-system/src/Widget/watch/LiveEvent/LiveEventHeader/LiveEventHeader.web'
    ),
);

const LiveEventCard = React.lazy(
  () =>
    import(
      'design-system/src/Widget/watch/LiveEvent/LiveEventCard/LiveEventCard.web'
    ),
);

const isCommingSoon = (item: ILiveEventFragmentFragment): boolean => {
  if (item.startDate) {
    return dayjs(item.startDate).isAfter(dayjs());
  }
  return true;
};

const LiveEventScreen = () => {
  const location = useLocation();
  const params = (location.state as any).params;

  const {url} = params || {};

  const navigation = useAppNavigation();

  const rangeEnd = 480;
  const {AnimatedHeaderValue} = useScrollAnimation({rangeEnd});
  const {t} = useTranslation('translation');
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();
  const {isNotified, toggleNotify, fetch, notifiedContent, isCreating} =
    useMemberNotify();
  const {page} = useRegionUrl();
  const memberDatas = useMemberDatas();
  const pageId: string | undefined = page.watch?.children.liveEvent?.id;

  const [fetchLiveEventsPage, {loading, data}] = useLiveEventsPageLazyQuery();

  const dispatch = useAppDispatch();

  const {isMobile} = useBreakpoint();

  const {
    liveEvents,
    commingSoonEvents,
    highlightedItem,
    isMainCommingSoon,
  }: {
    liveEvents: ILiveEventFragmentFragment[];
    commingSoonEvents: ILiveEventFragmentFragment[];
    highlightedItem?: ILiveEventFragmentFragment;
    isMainCommingSoon: boolean;
  } = useMemo(() => {
    const allLiveEvent: ILiveEventFragmentFragment[] = [];
    const allUpCommingEvent: ILiveEventFragmentFragment[] = [];
    let isMainComming: boolean = false;
    let highlightedItemInner: ILiveEventFragmentFragment | undefined;
    if (data && data.liveEventsPage && data.liveEventsPage.liveEventsContent) {
      data.liveEventsPage.liveEventsContent.forEach(item => {
        if (item && item.__typename === 'LiveEvent') {
          const upComming: boolean = isCommingSoon(item);
          if (upComming) {
            allUpCommingEvent.push(item);
          } else {
            allLiveEvent.push(item);
          }
          if (item.url === url) {
            highlightedItemInner = item;
            if (upComming) {
              isMainComming = true;
            }
          }
        }
      });
    }
    return {
      highlightedItem: highlightedItemInner,
      isMainCommingSoon: isMainComming,
      liveEvents: allLiveEvent,
      commingSoonEvents: allUpCommingEvent,
    };
  }, [data, url]);

  // state-------------
  const [isLive, setIsLive] = useState(!isMainCommingSoon);

  useEffect(() => {
    if (highlightedItem) {
      const comming = isCommingSoon(highlightedItem);
      setIsLive(!comming);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, highlightedItem]);

  let mainItem: ILiveEventFragmentFragment = isLive
    ? liveEvents[0]
    : commingSoonEvents[0];

  if (highlightedItem) {
    if (isMainCommingSoon && !isLive) {
      mainItem = highlightedItem;
    } else if (!isMainCommingSoon && isLive) {
      mainItem = highlightedItem;
    }
  }

  const list: ILiveEventFragmentFragment[] = isLive
    ? liveEvents
    : commingSoonEvents;
  // -------------------- effects --------------------

  useEffect(() => {
    const subMenu: ITabBarHeaderItem[] = [
      {
        title: t('Coming soon'),
        color: !isLive ? 'primary' : undefined,
        onClick: () => setIsLive(false),
      },
      {
        title: t('Live'),
        color: isLive ? 'primary' : undefined,
        onClick: () => setIsLive(true),
      },
    ];

    dispatch(appSlice.actions.setTopMenu(subMenu || null));

    return () => {
      dispatch(appSlice.actions.setTopMenu(null));
    };
  }, [isLive, navigation, t]);

  // useFocusEffect(
  //   useCallback(() => {
  //     // pause listen player
  //     fetch();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []),
  // );

  useEffect(() => {
    if (pageId) {
      fetchLiveEventsPage({
        variables: {
          id: pageId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  // ----- handlers --------------------------------
  const handleWatch = (item: ILiveEventFragmentFragment) => async () => {
    if (isLive) {
      const canSee = canSeeContent(item.accessType);
      const canPreview = getPremiumPreviewSettings(
        item.paywall as IPaywallFragmentFragment,
      )?.canPreview;

      if (canSee || canPreview) {
        navigation.navigate(AppRoutesEnum.VIDEO_PLAYER, {
          state: {
            params: {
              type: 'player',
              source: 'livestream',
              liveEventId: item.liveEventID || '',
              url: item.url || '',
              title: item.name || 'Unknown',
              isLive: true,
              accessType: item.accessType,
              // @ts-ignore
              paywall: item.paywall?.id || 'default',
              paywallData: {
                accessType: item.accessType,
                paywall: item.paywall,
              },
              id: item.id,
              previousScreen: 'live event',
            },
          },
        });
      } else {
        navigation.navigate(AppRoutesEnum.PAYWALL, {
          state: {
            params: {
              headerTitle: item.name || '',
              paywall: item.paywall,
              id: 'default',
            },
          },
        });
      }
    } else {
      try {
        const isItemNotified = notifiedContent.includes(item.id);
        const response = await toggleNotify(item);
        if (response) {
          if (isItemNotified) {
            toast.success(
              `Event notification removed\nNotification removed for ${item.title}`,
            );
          } else {
            toast.success(
              `Event notification created\nNotification created for ${item.title}`,
            );
          }
        } else {
          toast.error('Error notification');
        }
      } catch (e) {
        toast.error('Error notification');
      } finally {
        fetch();
      }
    }
  };

  const duration: number =
    mainItem && mainItem.startDate && mainItem.endDate
      ? Math.abs(dayjs(mainItem.startDate).diff(mainItem.endDate))
      : 0;
  const progressionDuration: number =
    mainItem && mainItem.startDate
      ? Math.abs(dayjs().diff(mainItem.endDate))
      : 0;

  return (
    <>
      <View style={{flex: 1, width: '100%', paddingBottom: isMobile ? 92 : 0}}>
        <Animated.ScrollView
          scrollEventThrottle={4}
          contentContainerStyle={{flexGrow: 1}}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {y: AnimatedHeaderValue},
                },
              },
            ],
            {useNativeDriver: false},
          )}>
          <SuspenseAfterRequired required={Boolean(pageId)}>
            <>
              <SuspenseAfterRequired required={Boolean(mainItem)}>
                <LiveEventHeader
                  title={mainItem.title || ''}
                  description={mainItem.description || ''}
                  startDate={new DateFactory(mainItem.startDate).dateTime()}
                  endDate={new DateFactory(mainItem.endDate).dateTime()}
                  // TODO: display video
                  image={{
                    uri:
                      (!!mainItem.cover &&
                        !!mainItem.cover[0] &&
                        !!mainItem.cover[0].content &&
                        mainItem.cover[0].content?.__typename ===
                          'HeroVideoCover' &&
                        mainItem.cover[0].content.image?.url) ||
                      '',
                  }}
                  releaseDate={new DateFactory(mainItem.startDate).format()}
                  onPressButton={handleWatch(mainItem)}
                  hideButton={!isLive && !memberDatas}
                  buttonProps={{
                    loading: isCreating,
                  }}
                  buttonTitle={''}
                  isNofified={notifiedContent.includes(mainItem.id)}
                  notifiedLabel={t('NOTIFIED') + ''}
                  toNotifyLabel={t('Notify me') + ''}
                  isLive={isLive}
                  liveLabel={t('Watch Now') + ''}
                  progress={Math.max(
                    0,
                    Math.min(progressionDuration / duration, 1),
                  )}
                />
              </SuspenseAfterRequired>

              {loading ? (
                <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
              ) : list.length ? (
                <div
                  style={{
                    padding: isMobile ? '0 16px' : '0 60px',
                    display: isMobile ? 'flex' : 'grid',
                    flexDirection: isMobile ? 'column' : undefined,
                    gridTemplateColumns: '1fr 1fr',
                    justifyItems: 'center',
                  }}>
                  {list.map((item, index) => {
                    const {thumbnail, title, description, startDate, endDate} =
                      item;
                    if (mainItem && mainItem.id === item.id) {
                      return null;
                    }
                    return (
                      <PaddingContainer
                        key={index}
                        bottom={Metrics.verticalScale(24)}
                        // edgeHoriz={Metrics.horizontalScale(isMobile ? 16 : 60)}
                        top={index === 0 ? 0 : Metrics.verticalScale(4)}>
                        <Suspense>
                          <LiveEventCard
                            cover={{uri: thumbnail?.url}}
                            onPressButton={handleWatch(item)}
                            title={title || ''}
                            description={description}
                            startDate={new DateFactory(startDate).dateTime()}
                            endDate={new DateFactory(endDate).dateTime()}
                            releaseDate={new DateFactory(startDate).format()}
                            isNofified={isNotified(item.id)}
                            notifiedLabel={t('NOTIFIED') + ''}
                            toNotifyLabel={t('Notify me') + ''}
                            liveLabel={t('Watch Now') + ''}
                            // TODO: isLive if live tab
                            isLive={isLive}
                            premiumText="Premium"
                          />
                        </Suspense>
                      </PaddingContainer>
                    );
                  })}
                </div>
              ) : (
                <View
                  style={{
                    flex: 1,
                    height: Metrics.window.height,
                    justifyContent: 'center',
                  }}>
                  <Typography variant="body2" style={{textAlign: 'center'}}>
                    {t('No available items')}
                  </Typography>
                </View>
              )}
            </>
          </SuspenseAfterRequired>
          {!pageId && <ContentEmpty title={t('Page error')} />}
        </Animated.ScrollView>
      </View>
    </>
  );
};

export default LiveEventScreen;
