import React, {createContext, useState, useEffect, useRef} from 'react';
import {onAuthStateChanged, User} from 'firebase/auth';
import {auth} from '@src/firebase';
import {useLazyGetMemberByUsernameQuery} from '@src/Api/TraceApi/traceApi';
import useFireBaseAuthentication from '@src/Hooks/useFirebaseAuthentication';
import {useAppDispatch, useAppSelector} from '@src/Store/hooks';
import useNavigation from '@src/Navigation/useNavigation';
import appAuthSlice from '@src/Store/Slices/AppSlice/auth.slice';
import InitTrackerUser from './InitTrackerUser/InitTrackerUser';

type AuthState = {
  isLoggedIn: boolean;
  isLoading: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  checkAuth: (user: User | null) => Promise<void>;
};

export const AuthContext = createContext<AuthState>({
  isLoggedIn: false,
  isLoading: true,
  setIsLoggedIn: () => {},
  checkAuth: () => Promise.resolve(),
});

const AuthProvider = ({children}: {children: React.ReactElement}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [fetchMemberByUsernameAndUpdateStore] =
    useLazyGetMemberByUsernameQuery();
  const {signOut} = useFireBaseAuthentication();
  const {authentificationType, socialNetworkSource, openAuthenticationModal} =
    useAppSelector(state => state.appAuth);
  const rootNavigation = useNavigation();
  const dispatch = useAppDispatch();

  // Create refs to store the latest values
  const authTypeRef = useRef(authentificationType);
  const socialSourceRef = useRef(socialNetworkSource);

  // Update refs whenever the values change
  useEffect(() => {
    authTypeRef.current = authentificationType;
    socialSourceRef.current = socialNetworkSource;
  }, [authentificationType, socialNetworkSource]);

  const checkAuth = async (user: User | null) => {
    const handleNotLoggedIn = () => {
      setIsLoggedIn(false);
      signOut();
    };

    const currentPath = window.location.pathname;

    try {
      if (
        authTypeRef.current === 'register' &&
        socialSourceRef.current !== 'email' &&
        user &&
        user.email
      ) {
        setIsLoggedIn(false);
      } else if (user && user.email) {
        const {data: memberData} = await fetchMemberByUsernameAndUpdateStore();
        if (
          memberData &&
          memberData.email &&
          memberData.email.toLowerCase() === user.email.toLowerCase()
        ) {
          // set logged in state
          setIsLoggedIn(true);

          const redirect = openAuthenticationModal?.redirect;
          // check open
          if (openAuthenticationModal?.open) {
            dispatch(
              appAuthSlice.actions.openAuthenticationModal({open: false}),
            );
          }

          // check redirect
          if (redirect) {
            await rootNavigation.navigate(redirect, {
              replace: true,
            });
          } else if (currentPath.includes('/tv-auth')) {
            await rootNavigation.navigate(currentPath, {
              replace: true,
            });
          } else {
            // await rootNavigation.navigate('/app', {
            //   replace: true,
            // });
          }
        } else {
          handleNotLoggedIn();
        }
      } else {
        handleNotLoggedIn();
      }
    } catch (error) {
      console.log('🚀 ~ useEffect ~ error:', error);
      handleNotLoggedIn();
    } finally {
      setIsLoading(false);
    }
  };

  // Implement check for user status here (e.g., check tokens)
  useEffect(() => {
    setIsLoggedIn(false);
    setIsLoading(true);

    onAuthStateChanged(auth, checkAuth);
  }, []);

  return (
    <AuthContext.Provider
      value={{isLoggedIn, isLoading, setIsLoggedIn, checkAuth}}>
      <InitTrackerUser />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
