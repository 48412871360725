import React, {ReactNode} from 'react';
import PaywallSectionPage from '~Umbraco/Pages/PaywallSectionPage/PaywallSectionPage';
import {ICollectionFragmentFragment, IPaywallFragmentFragment} from '~Api/Graphql/gql/types.generated';
import usePaywal from '~Hooks/usePaywal';

export type IPaywallParam = ICollectionFragmentFragment['paywall'];
export type IAccessTypParam = ICollectionFragmentFragment['accessType'];

interface IPaywallCheckerProps {
  children: ReactNode;
  accessType?: IAccessTypParam;
  paywallId?: string;
  paywall?: IPaywallParam;
  render?: (children: ReactNode) => ReactNode;
}

const PaywallChecker = (props: IPaywallCheckerProps) => {
  const {
    children,
    accessType,
    paywall,
    paywallId: defaultPaywallId,
    render,
  } = props;
  const {canSeeContent, getPremiumPreviewSettings} = usePaywal();

  const paywallId: string | undefined =
    defaultPaywallId ||
    (paywall && paywall.__typename === 'Paywall' ? paywall.id : undefined);

  const hasAccess: boolean = accessType ? canSeeContent(accessType) : true;
  const canPreview = getPremiumPreviewSettings(
    paywall as IPaywallFragmentFragment,
  )?.canPreview;

  return (
    <>
      {!hasAccess && !canPreview ? (
        render ? (
          render(<PaywallSectionPage id={paywallId} />)
        ) : (
          <PaywallSectionPage id={paywallId} />
        )
      ) : (
        children
      )}
    </>
  );
};

export default PaywallChecker;
