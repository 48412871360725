// import Hls, {MediaPlaylist} from 'hls.js/dist/';
import Hls from 'hls.js';
import type {MediaPlaylist} from 'hls.js/dist/hls.js.d.ts';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {IItemPlayerParameter} from '../VideoPlayer/PlayerHeader/PlayerParameters.web';
import {useTranslation} from 'gatsby-plugin-react-i18next';

export enum EQuality {
  AUTO = 'auto',
  HD = 'HD',
  STANDARD = 'Standard',
}

export const QualityPixels: Record<EQuality, number | 'auto'> = {
  [EQuality.AUTO]: -1,
  [EQuality.HD]: 1080,
  [EQuality.STANDARD]: 720,
};

export const useVideoPlayer = ({
  currentSource,
  initialTime,
  onVideoInitialized,
  memberVideoQuality,
  onTimeProgress,
  handleOnResolutionChange,
}: {
  currentSource: (
    | number
    | {
        uri?: string | undefined;
        headers?:
          | {
              [key: string]: string;
            }
          | undefined;
        type?: string | undefined;
      }
  ) & {
    description?: string;
  };
  initialTime?: number;
  onVideoInitialized?: (params: {
    textTrack?: string;
    originalQuality?: number | string;
    duration: number;
  }) => void;
  memberVideoQuality?: EQuality;
  onTimeProgress?: (currentTime: number) => void;
  handleOnResolutionChange?: (newResolution: string) => void;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);

  const {t} = useTranslation(['videoPlayer']);

  const [duration, setDuration] = useState<number>(0);
  const [audioTracks, setAudioTracks] = useState<IItemPlayerParameter[]>([]);
  const [audioTrack, setAudioTrack] = useState<number>(-1);
  const [subtitle, setSubtitle] = useState<number>(-1);
  const [subtitles, setSubtitles] = useState<IItemPlayerParameter[]>([]);
  const [loaded, setLoaded] = useState<number>(0);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0); // percentage of player in progress bar
  const [playedSeconds, setPlayedSeconds] = useState<number>(0); // true value of played
  const [isLoadedMetadata, setIsLoadedMetadata] = useState(false);
  const [videoQualities, setVideoQualities] = useState<IItemPlayerParameter[]>(
    [],
  );
  const [videoQuality, setVideoQuality] = useState<number>(-1);
  const currentUri = (currentSource as any)?.uri;

  const initPlayer = async () => {
    setIsLoadedMetadata(false);
    const isHlsSupported = Hls.isSupported();
    if (isHlsSupported && videoRef.current && currentUri) {
      const hls = new Hls({
        xhrSetup: function (xhr, url) {
          xhr.withCredentials = false; // if you need to send cookies
          // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
          // You can set custom headers here if needed
          // xhr.setRequestHeader('Custom-Header', 'value');
        },
      });
      hls.loadSource(currentUri);
      hls.attachMedia(videoRef.current);

      hlsRef.current = hls;

      hls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error(
                '🚀 ~fatal network error encountered, try to recover',
              );
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error(
                '🚀 ~fatal media error encountered, try to recover',
              );
              hls.recoverMediaError();
              break;
            default:
              console.error(
                '🚀 ~unrecoverable error encountered, destroying hls',
              );
              hls.destroy();
              break;
          }
        }
      });

      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (initialTime && videoRef.current) {
          videoRef.current.currentTime = initialTime;
          setPlayed(initialTime / (duration || 1)); // Avoid division by zero
          setPlayedSeconds(initialTime);
        }
        setDuration(videoRef.current?.duration || 0);

        const audioTracksData: IItemPlayerParameter[] = _.uniqBy(
          hls.allAudioTracks.map(
            (track: MediaPlaylist) =>
              ({
                label: track.name || '',
                value: track.id || '',
              }) as IItemPlayerParameter,
          ),
          'value',
        );

        if (audioTracksData.length) {
          if (audioTrack < 0) {
            setAudioTrack(0);
            hls.audioTrack = 0;
          } else if (audioTrack > 0) {
            hls.audioTrack = audioTrack;
          }
        }

        setAudioTracks(audioTracksData);

        const subtitlesData: IItemPlayerParameter[] = _.uniqBy(
          hls.allSubtitleTracks.map(
            (track: MediaPlaylist) =>
              ({
                label: track.name || '',
                value: track.id,
              }) as IItemPlayerParameter,
          ),
          'value',
        );
        setSubtitles([
          {
            label: t('disabled'),
            value: -1,
          },
          ...subtitlesData,
        ]);

        if (subtitle >= 0) {
          hls.subtitleTrack = subtitle;
        }

        const qualityLevels = hls.levels.map((level, index) => ({
          label: `${level.height}p`,
          value: index,
        }));

        setVideoQualities([
          {
            label: t('auto'),
            value: -1,
          },
          ...qualityLevels,
        ]);

        if (memberVideoQuality) {
          const qualityLevelFound = qualityLevels.find(
            level => level.label === `${QualityPixels[memberVideoQuality]}p`,
          );
          if (qualityLevelFound) {
            setVideoQuality(qualityLevelFound.value);
            hls.currentLevel = qualityLevelFound.value;
          } else {
            setVideoQuality(-1);
          }
        } else {
          setVideoQuality(-1);
        }
      });
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = currentUri;
    }
  };

  useEffect(() => {
    initPlayer();
  }, [currentUri]);

  useEffect(() => {
    if (hlsRef.current) {
      if (hlsRef.current.subtitleTrack !== subtitle) {
        hlsRef.current.subtitleTrack = subtitle;
      }
    }
  }, [subtitle]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const changeAudioTrack = (index: number) => {
    // -1 if hide
    setAudioTrack(index);
    if (hlsRef.current) {
      hlsRef.current.audioTrack = index;
    }
  };
  const changeSubtitle = (index: number) => {
    // -1 if hide
    setSubtitle(index);
    if (hlsRef.current) {
      hlsRef.current.subtitleTrack = index;
    }
  };

  const handlePlayPause = () => {
    if (!isLoadedMetadata) {
      return;
    }
    setPlaying(!playing);
    if (videoRef.current) {
      playing ? videoRef.current.pause() : videoRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current?.currentTime || 0;
    setPlayedSeconds(currentTime);
    setPlayed(currentTime / duration);
    onTimeProgress?.(currentTime);
  };

  const resetPlayer = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      handleTimeUpdate();
    }
  };

  const handleSliderChange = (value: number | number[]) => {
    const newValue = Array.isArray(value) ? value[0] : value;
    setPlayed(newValue);
    if (videoRef.current) {
      videoRef.current.currentTime = newValue * duration;
    }
  };

  const skip = (amount: number) => {
    if (videoRef.current) {
      let newTime = (videoRef.current.currentTime || 0) + amount;
      newTime = Math.max(0, Math.min(newTime, duration));
      videoRef.current.currentTime = newTime;
      handleTimeUpdate();
    }
  };

  const handleLoadedMetadata = () => {
    try {
      // videoRef.current?.play();
      setDuration(videoRef.current?.duration || 0);
      if (onVideoInitialized) {
        onVideoInitialized({
          textTrack:
            audioTracks.find(track => track.value === audioTrack)?.label || '',
          originalQuality:
            videoQualities.find(level => level.value === videoQuality)?.label ||
            '',
          duration: videoRef.current?.duration || 0,
        });
      }
      setIsLoadedMetadata(true);
    } catch (error) {
      console.error('load metadata error', error);
    }
  };

  const changeVideoQuality = (qualityIndex: number) => {
    if (hlsRef.current) {
      hlsRef.current.currentLevel = qualityIndex;
      setVideoQuality(qualityIndex);
      handleOnResolutionChange?.(
        videoQualities[qualityIndex]
          ? videoQualities[qualityIndex].label
          : 'auto',
      );
    }
  };

  return {
    videoRef,
    changeAudioTrack,
    changeSubtitle,
    setLoaded,
    loaded,
    duration,
    audioTracks,
    audioTrack,
    subtitle,
    subtitles,
    playbackRate,
    setPlaybackRate,
    setDuration,
    handlePlayPause,
    playing,
    resetPlayer,
    handleSliderChange,
    skip,
    played,
    playedSeconds,
    handleTimeUpdate,
    handleLoadedMetadata,
    isLoadedMetadata,
    setPlaying,
    videoQualities,
    videoQuality,
    changeVideoQuality,
  };
};
