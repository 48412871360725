import CardMessage from 'design-system/src/Components/Card/CardMessage/CardMessage';
import CarouselWithSpace, {
  CarouselWithSpaceData,
} from 'design-system/src/Components/CarouselWithSpace/CarouselWithSpace.web';
import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage';
import {GridViewWeb as GridView} from 'design-system/src/Components/GridView';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import React, {ReactNode, useMemo} from 'react';
import {View} from 'react-native';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
  IJobHubFragmentFragment,
  useJobBoardPageByUrlEndQuery,
} from '~Api/Graphql/gql/types.generated';
import {mapHeroSlide} from '~Umbraco/Utils/content/mapHeroSlide';
import useSectionPageUtils from '~Umbraco/Hooks/useSectionPageUtils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// import {OpportunityProps} from '../PartnerScreen/PartnerSingle/PartnerSingle';
import usePushUtils from '~Umbraco/Hooks/usePushUtils';
import {RouteFactory} from '~Umbraco/Utils/RouteFactory';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {ContentHeader} from './components/ContentHeader';
import useGetTopMenu from '~Umbraco/Hooks/useGetTopMenu';
import {ITabBarHeaderItem} from 'design-system/src/Components/Navigation/TabBarHeader/TabBarHeader';
import useAppNavigation from '@src/utils/useAppNavigation';
import useParams from '@src/Hooks/useParams';
import {useAppDispatch} from '@src/Store/hooks';
import {mapLinkTo} from '@src/Umbraco/Utils/element/mapButton';
import {AppRoutesEnum} from '@src/Constant/routes';
import {OpportunityProps} from '../PartnersPage/PartnerSingle/PartnerSingle';
import PartnersGrid from '@src/App/components/Common/AppGrid/PartnersGrid/PartnersGrid';
import {IPartenerSliderItem} from '@src/Umbraco/Components/PartnerSlider/PartnerSlider';
import AppCarousel from '@src/App/components/Common/AppCarousel/AppCarousel';
import {IItemAppCarouselProps} from '@src/App/components/Common/AppCarousel/ItemAppCarousel/ItemAppCarousel';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import DebugUmbracoId from 'design-system/src/Provider/DebugModeProvider/DebugUmbracoId/DebugUmbracoId';
// import {StackHeaderProps} from '@react-navigation/stack';
// import Header from '~Components/Header/Header';
// import {mapLinkTo} from '~Umbraco/Utils/element/mapButton';

export default function JobBoardsScreen() {
  // props: AppLearnStackScreenProps<'Job'>,
  const params = useParams();

  const {endUrl} = params || {};
  const navigation = useAppNavigation();
  const {handleButtonHeader} = useSectionPageUtils();
  const response = useJobBoardPageByUrlEndQuery({
    variables: {
      urlEnd: endUrl,
    },
    skip: !endUrl,
  });
  const jobBoardPage = response.data?.allJobBoardPage?.items?.[0];
  const loading = response.loading;
  const {t} = useTranslation(['learn']);
  const {renderPush} = usePushUtils();
  const dispatch = useAppDispatch();
  const {getTopMenu} = useGetTopMenu();
  const {isMobile} = useBreakpoint();

  const {
    renderPage,
    topMenu,
  }: {renderPage: ReactNode; topMenu: ITabBarHeaderItem[] | undefined} =
    useMemo(() => {
      if (jobBoardPage) {
        let outPutContent: ReactNode[] = [];
        const {
          heroSlider,
          // autoloop,
          // loopDelay,
          tipsSlides,
          jobHubs,
          activeOppotunities,
          jobVancancies,
          push,
          partners,
          topMenu,
          children,
        } = jobBoardPage;

        const pageBlocks: ReactNode[] = [];
        const _topMenu = getTopMenu(topMenu);

        if (tipsSlides && tipsSlides.length) {
          let carouselDatas: CarouselWithSpaceData[] = [];
          tipsSlides.forEach(item => {
            if (
              item &&
              item.content &&
              item.content.__typename === 'TextSlide1'
            ) {
              const {linkTo} = item.content;
              const link_To = mapLinkTo(linkTo);
              carouselDatas.push({
                title: item.content.title ?? '-',
                description: item.content.description ?? '-',
                overTitle: item.content.overtitle ?? '-',
                image:
                  item.content.cover &&
                  item.content.cover[0] &&
                  item.content.cover[0].content &&
                  item.content.cover[0].content.__typename ===
                    'HeroVideoCover' &&
                  item.content.cover[0].content.image &&
                  item.content.cover[0].content.image.url
                    ? item.content.cover[0].content.image.url
                    : '',
                onPress: () => {
                  if (link_To) {
                    const {url, id, __typename} = link_To;
                    new RouteFactory(
                      {
                        url,
                        id,
                        __typename,
                      },
                      navigation,
                      dispatch,
                    ).navigate();
                  }
                },
              });
            }
          });
          carouselDatas.length !== 0 &&
            pageBlocks.push(
              <View key="tips">
                <Typography
                  style={{
                    textAlign: 'center',
                    marginBottom: Metrics.verticalScale(21),
                    marginTop: Metrics.verticalScale(33),
                  }}
                  variant="h4">
                  {t('Tips')}
                </Typography>
                <CarouselWithSpace data={carouselDatas} radius={8} />
              </View>,
            );
        }

        if (jobHubs && jobHubs.length) {
          let jobHubData: IJobHubFragmentFragment[] = [];
          jobHubs.forEach(item => {
            if (item && item.__typename === 'JobHub') {
              jobHubData.push(item);
            }
          });
          jobHubData.length &&
            pageBlocks.push(
              <View key={'jobHub'}>
                <ContentHeader
                  title={t('The Job Hub')}
                  seeMore={t('See all')}
                  onMore={() => {
                    navigation.navigate(AppRoutesEnum.JOB_HUB);
                  }}
                />
                <GridView
                  data={jobHubData}
                  onItemPress={item => {
                    const {__typename, id, link} = item;
                    if (link) {
                      new RouteFactory(
                        {
                          url: link || '',
                          id,
                          __typename,
                        },
                        navigation,
                        dispatch,
                      ).navigate();
                    }
                  }}
                  yPadding={4}
                  renderItem={item => (
                    <View
                      style={{
                        width: Metrics.horizontalScale(109),
                        height: Metrics.verticalScale(72),
                      }}>
                      <CustomFastImage
                        width={Metrics.horizontalScale(109)}
                        height={Metrics.verticalScale(72)}
                        source={{
                          uri: item.thumbnail?.url,
                        }}
                        style={{
                          borderRadius: Metrics.horizontalScale(4),
                        }}
                      />
                    </View>
                  )}
                />
              </View>,
            );
        }

        let OpportunityCenter: string | undefined;
        let opportunityCenterUrl: string | undefined;
        if (children && children.items) {
          const findChild = children.items.find(
            item => item && item.__typename === 'OpportunityCenter',
          );
          if (findChild) {
            OpportunityCenter = findChild.id;
            opportunityCenterUrl = findChild.url;
          }
        }
        if (activeOppotunities && activeOppotunities.length) {
          let dataOpportunity: OpportunityProps[] = [];
          dayjs.extend(relativeTime);
          activeOppotunities.forEach(item => {
            if (item && item.__typename === 'Opportunity') {
              const {
                title,
                shortDescription,
                startDate,
                id,
                __typename,
                url,
                partners,
              } = item;
              dataOpportunity.push({
                title: title || '',
                cover: {
                  uri:
                    partners &&
                    partners[0] &&
                    partners[0].__typename === 'Partner'
                      ? partners[0].logo?.url
                      : undefined,
                },
                description: shortDescription || '',
                date: dayjs(startDate).fromNow(),
                id: id,
                url: url || '',
                __typename,
                partner:
                  partners &&
                  partners[0] &&
                  partners[0].__typename === 'Partner'
                    ? `${t('With')} #${partners[0].partnerName}`
                    : '',
              });
            }
          });
          dataOpportunity.length &&
            pageBlocks.push(
              <View
                key={'act-op'}
                style={{marginTop: Metrics.verticalScale(50)}}>
                <ContentHeader
                  title={t('Opportunities')}
                  seeMore={t('See all')}
                  noMargin={true}
                  onMore={
                    OpportunityCenter
                      ? () => {
                          navigation.navigate(
                            AppRoutesEnum.OPPORTUNITY_CENTER,
                            {
                              state: {
                                params: {
                                  id: OpportunityCenter,
                                  url: opportunityCenterUrl,
                                },
                              },
                            },
                          );
                        }
                      : undefined
                  }
                />
                <View
                  style={{
                    paddingHorizontal: Metrics.horizontalScale(24),
                  }}>
                  <View>
                    {dataOpportunity.map((d, index) => (
                      <CardMessage
                        onPress={() => {
                          navigation.navigate(
                            AppRoutesEnum.OPPORTUNITY_SINGLE,
                            {
                              state: {
                                params: {
                                  id: d?.id || '',
                                  type: 'url',
                                  url: d?.url || '',
                                  __typename: 'Opportunity',
                                },
                              },
                            },
                          );
                        }}
                        {...d}
                        key={`ps-${index}`}
                        coverType="square"
                      />
                    ))}
                  </View>
                </View>
              </View>,
            );
        }

        if (jobVancancies && jobVancancies.length) {
          let dataJobVacancies: OpportunityProps[] = [];
          jobVancancies.forEach(item => {
            if (item && item.__typename === 'JobVacancy') {
              const {
                title,
                shortDescription,
                thumbnail,
                id,
                __typename,
                url,
                partners,
                link,
              } = item;
              dataJobVacancies.push({
                title: title || '',
                cover: {
                  uri: thumbnail?.url,
                },
                description: shortDescription || '',
                id: id,
                url: url || '',
                __typename,
                partner:
                  partners &&
                  partners[0] &&
                  partners[0].__typename === 'Partner'
                    ? `${t('With')} #${partners[0].partnerName}`
                    : '',
                onPress: () => {
                  if (link) {
                    new RouteFactory(
                      {
                        url: link || '',
                        id,
                        __typename,
                      },
                      navigation,
                      dispatch,
                    ).navigate();
                  }
                },
              });
            }
          });
          dataJobVacancies.length &&
            pageBlocks.push(
              <View
                key={'job-vac'}
                style={{marginTop: Metrics.verticalScale(50)}}>
                <ContentHeader
                  title={t('Job vacancies')}
                  seeMore={t('See all')}
                  noMargin={true}
                  onMore={
                    OpportunityCenter
                      ? () => {
                          // TO DO
                          // navigation.navigate('JobVacancy');
                        }
                      : undefined
                  }
                />
                <View
                  style={{
                    paddingHorizontal: Metrics.horizontalScale(24),
                  }}>
                  <View>
                    {dataJobVacancies.map((d, index) => (
                      <CardMessage
                        onPress={d.onPress}
                        {...d}
                        key={`ps-${index}`}
                        coverType="square"
                      />
                    ))}
                  </View>
                </View>
              </View>,
            );
        }
        if (
          push &&
          push[0] &&
          push[0].content &&
          push[0].content.__typename === 'Push'
        ) {
          pageBlocks.push(
            <View key={'push'} style={{marginTop: Metrics.verticalScale(45)}}>
              {renderPush(push[0].content)}
            </View>,
          );
        }

        if (
          partners &&
          partners.length &&
          partners[0]?.__typename === 'Partner'
        ) {
          const partnerItems: IPartenerSliderItem[] = [];
          partners.forEach(item => {
            if (item && item.__typename === 'Partner') {
              partnerItems.push({
                partnerLogo: item.logo?.url || '',
                accessData: {
                  accessType: undefined,
                  paywall: undefined,
                },
                id: item.id || '',
                __typename: item.__typename || '',
                url: item.url || '',
              });
            }
          });
          pageBlocks.push(
            <View
              key={'partners'}
              style={{
                marginTop: Metrics.verticalScale(50),
                paddingHorizontal: Metrics.horizontalScale(24),
                paddingBottom: Metrics.verticalScale(40),
              }}>
              <PartnersGrid
                data={partnerItems}
                nbColumns={isMobile ? 3 : 6}
                title={t('Discover partners') || ''}
              />
            </View>,
          );
        }

        const heroSlidesList: IItemAppCarouselProps[] = [];
        heroSlider?.forEach(heroItem => {
          if (heroItem && heroItem.content) {
            if (heroItem.content.__typename === 'HeroSlide') {
              const mappedContent = mapHeroSlide(heroItem.content);

              heroSlidesList.push({
                ...mappedContent,
                buttonProps: {
                  onPress: () => handleButtonHeader(mappedContent),
                },
                backgroundUrl:
                  typeof mappedContent.image === 'object'
                    ? mappedContent.image.uri
                    : '',
                desktopBackgroundUrl:
                  typeof mappedContent.desktopImage === 'object'
                    ? mappedContent.desktopImage.uri
                    : '',
                tags: mappedContent.categorie,
                title: mappedContent.title,
                subtitle: mappedContent.description,
                buttonTitle: mappedContent.button?.title || '',
              });
            }
          }
        });
        heroSlidesList.length &&
          outPutContent.push(
            <AppCarousel
              key="heroslider"
              // autoLoop={autoloop} TO DO
              // interval={loopDelay} TO DO
              items={heroSlidesList}
              aspectRatio={[3 / 4, 21 / 9]}
              navigationPosition={isMobile ? 'bottom' : 'right'}
            />,
          );
        return {
          renderPage: [...outPutContent, ...pageBlocks],
          topMenu: _topMenu,
        };
      }
      return {
        renderPage: undefined,
        topMenu: undefined,
      };
    }, [jobBoardPage]);

  // TODO
  // useEffect(() => {
  //   if (topMenu) {
  //     navigation.setOptions({
  //       header: (headerProps: StackHeaderProps) => (
  //         <Header
  //           {...(headerProps as StackHeaderProps)}
  //           subMenu={
  //             topMenu && topMenu.length > 0
  //               ? {
  //                   items: topMenu,
  //                 }
  //               : undefined
  //           }
  //         />
  //       ),
  //     });
  //   }
  // }, [topMenu]);

  return (
    <div>
      <DebugUmbracoId umbracoId={jobBoardPage?.id} position="page" />
      {endUrl ? (
        loading ? (
          <CustomActivityIndicator style={{flex: 1, height: '100%'}} />
        ) : (
          renderPage
        )
      ) : (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ContentEmpty title={t('Section not found')} />
        </View>
      )}
    </div>
  );
}
