import useDefaultPaywall from '@src/Store/selectors/useDefaultPaywall';
import PaywallSectionPage from '@src/Umbraco/Pages/PaywallSectionPage/PaywallSectionPage';
import React from 'react';

const PaywallPage = (props: any) => {
  const defaultPaywall = useDefaultPaywall();
  const paywallId = props.id || defaultPaywall?.id;
  return <PaywallSectionPage id={paywallId} />;
};

export default PaywallPage;
