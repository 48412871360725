import {AppRoutesEnum} from '@src/Constant/routes';
import {pathToRegexp} from 'path-to-regexp';

const modalRoutes: AppRoutesEnum[] = [
  AppRoutesEnum.PAYWALL,
  AppRoutesEnum.TERMS_AND_CONDITION,
  AppRoutesEnum.BOOKMARKS,
  AppRoutesEnum.NEWS_SINGLE,
  AppRoutesEnum.PROFILE_ACHIEVEMENT,
  AppRoutesEnum.PROFILE_NOTIFICATION,
  AppRoutesEnum.PROFILE_OPPORTUNITIES,
  AppRoutesEnum.VOD_SINGLE,
  AppRoutesEnum.VOD_COLLECTION,
  AppRoutesEnum.PODCAST_SINGLE,
  AppRoutesEnum.LINKED_JOB,
  AppRoutesEnum.COURSE_UNIT,
  AppRoutesEnum.PARTNER_SINGLE,
  AppRoutesEnum.QUIZ_QUESTION,
  AppRoutesEnum.PERSONALITY,
  AppRoutesEnum.SINGLE_JOB,
  AppRoutesEnum.ENTREPRENEURIAL_HUB,
  AppRoutesEnum.OPPORTUNITY_SINGLE,
  AppRoutesEnum.EXAM,
  AppRoutesEnum.EXAM_RESULT_OVERVIEW,
  AppRoutesEnum.FORMS,
  AppRoutesEnum.QUIZZ_SURVEY_COLLECTION,
  AppRoutesEnum.QUIZZ_SURVEY,
  AppRoutesEnum.PLAYER_QUIZ_SURVEY,
  AppRoutesEnum.THANK_YOU,
  AppRoutesEnum.TUTORIAL,
];

const modalRoutesNotMobile: AppRoutesEnum[] = [
  AppRoutesEnum.TERMS_AND_CONDITION,
  AppRoutesEnum.BOOKMARKS,
  AppRoutesEnum.PAYWALL,
  AppRoutesEnum.PROFILE_ACHIEVEMENT,
  AppRoutesEnum.PROFILE_NOTIFICATION,
  // AppRoutesEnum.VOD_SINGLE,
  // AppRoutesEnum.VOD_COLLECTION,
  AppRoutesEnum.PODCAST_SINGLE,
  AppRoutesEnum.LINKED_JOB,
  // AppRoutesEnum.COURSE_UNIT,
  AppRoutesEnum.PARTNER_SINGLE,
  AppRoutesEnum.PERSONALITY,
  AppRoutesEnum.SINGLE_JOB,
  AppRoutesEnum.ENTREPRENEURIAL_HUB,
  AppRoutesEnum.OPPORTUNITY_SINGLE,
  AppRoutesEnum.FORMS,
  AppRoutesEnum.QUIZZ_SURVEY_COLLECTION,
  AppRoutesEnum.QUIZZ_SURVEY,
  AppRoutesEnum.PLAYER_QUIZ_SURVEY,
];

export const isModalRoute = (path: string, isMobile: boolean): boolean => {
  let res = true;
  if (!modalRoutes.length) {
    res = false;
  }

  const modalRouteNotMobileRegex = pathToRegexp(modalRoutesNotMobile, []);

  if (isMobile && modalRouteNotMobileRegex.test(path)) {
    res = false;
  } else {
    const modalRouteRegex = pathToRegexp(modalRoutes, []);
    res = modalRouteRegex.test(path);
  }

  return res;
};
