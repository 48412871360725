import useMember from '@src/Store/selectors/useMember';
import PaywallConfirmPage from '@src/Umbraco/Pages/PaywallSectionPage/PaywallConfirmPage/PaywallConfirmPage';
import React from 'react';

const ChoosePlanPage = () => {
  const member = useMember();
  if (!member) {
    return <div>No member found</div>;
  }

  return (
    <div>
      <PaywallConfirmPage onMore={true} />
    </div>
  );
};

export default ChoosePlanPage;
