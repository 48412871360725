import {View, ScrollView, Modal} from 'react-native';
import React, {useState} from 'react';
import Metrics from 'design-system/src/Theme/Metrics';
import CardBookmark from 'design-system/src/Components/Card/CardBookmark/CardBookmark.web';
import {MemberDataSource} from '~Store/Slices/Member.slice';
import LearnRowCardWeb from 'design-system/src/Components/Card/LearnCard/LearnRowCard/LearnRowCard.web';
import ListenRowCardWeb from 'design-system/src/Components/Card/ListenCard/ListenRowCard/ListenRowCard.web';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import MenuModal, {
  MenuModalProps,
} from 'design-system/src/Components/MenuModal/MenuModal.web';
import MainRefreshControl from '@src/App/components/Common/MainRefreshControl/MainRefreshControl';
import useMemberBookmarks from '@src/Hooks/useMemberBookmarks';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import {
  ICardBookmarkProps,
  useProfileBookmarksContent,
} from './ProfileBookmarksCotentLogic';
import styled from 'styled-components';
import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';

const ResponsiveWrapper = styled.div<{
  $rowGap: number;
}>`
  row-gap: ${({$rowGap}) => Metrics.verticalScale($rowGap)}px;
  column-gap: ${Metrics.horizontalScale(12)}px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media ${responsiveMap.mobile} {
    grid-template-columns: 1fr;
  }
  @media ${responsiveMap.md} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${responsiveMap.xxl} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const ProfileBookmarksContent = (props: {source?: MemberDataSource}) => {
  const {source = 'watch'} = props;
  const {t} = useTranslation('profile');
  const {data, loading, refresh} = useMemberBookmarks(source);
  const [currentSelect, setCurrentSelect] = useState<
    ICardBookmarkProps | undefined
  >();
  const {isMobile} = useBreakpoint();

  const list: ICardBookmarkProps[] = useProfileBookmarksContent(data, source);
  const dataMenuModal: MenuModalProps['content'] = [
    // {
    //   icon: 'share',
    //   label: t('Share'),
    //   value: 'share',
    //   onPress: () => {
    //     currentSelect &&
    //       currentSelect.url &&
    //       shareContent.share({
    //         __typename: currentSelect.__typename,
    //         url: currentSelect.url,
    //         message: currentSelect.title,
    //         id: currentSelect.id,
    //       });
    //   },
    // },
    {
      icon: 'delete',
      label: t('Delete'),
      value: 'delete',
      onPress: async () => {
        if (currentSelect) {
          try {
            // await deleteContent(currentSelect.id);
            // dispatch(
            //   memberSlice.actions.removeSourceItem({
            //     type: 'downloaded',
            //     id: currentSelect.id,
            //     source,
            //   }),
            // );
            // await removeBookMark(currentSelect.id);
            // await refresh();
            setCurrentSelect(undefined);
          } catch (e) {}
        }
      },
    },
  ];
  const handleOpenActions = (item: ICardBookmarkProps) => () => {
    setCurrentSelect(item);
  };

  return (
    <ScrollView
      style={{
        marginTop: Metrics.verticalScale(24),
        marginBottom: Metrics.verticalScale(isMobile ? 70 : 0),
      }}
      // contentContainerStyle={{flexGrow: 1}}
      refreshControl={
        <MainRefreshControl refreshing={loading} onRefresh={refresh} />
      }>
      {list.length ? (
        <ResponsiveWrapper $rowGap={isMobile ? 12 : 50}>
          {list.map((item, index) => {
            const {
              title,
              cover,
              duration,
              description,
              tag,
              onPress,
              isDone: _isDone,
            } = item;
            return (
              <View
                key={`ftc-${index}`}
                style={{marginBottom: Metrics.verticalScale(16)}}>
                {source === 'listen' ? (
                  <ListenRowCardWeb
                    title={title}
                    description={description}
                    cover={cover}
                    onPress={onPress}
                    onPressTrailingIcon={handleOpenActions(item)}
                    fullWidth
                  />
                ) : source === 'learn' ? (
                  <LearnRowCardWeb
                    title={title}
                    description={description}
                    cover={cover}
                    duration={duration}
                    onPress={onPress}
                    onPressTrailingIcon={handleOpenActions(item)}
                    tag={tag}
                    isDone={_isDone}
                    textDone={t('Done') as string}
                    fullWidth
                  />
                ) : (
                  <CardBookmark
                    onPressTrailingIc={handleOpenActions(item)}
                    {...item}
                    fullWidth
                    aspectRatio={
                      item.__typename === 'News'
                        ? 109 / 136
                        : isMobile
                          ? 109 / 61
                          : 247 / 137.5
                    }
                  />
                )}
              </View>
            );
          })}
        </ResponsiveWrapper>
      ) : (
        <ContentEmpty style={{width: '100%'}} title={t('No data')} />
      )}
      <MenuModal
        isOpen={!!currentSelect}
        title={currentSelect?.title || ''}
        content={dataMenuModal}
        onClose={() => {
          setCurrentSelect(undefined);
        }}
      />
    </ScrollView>
  );
};

export default ProfileBookmarksContent;
