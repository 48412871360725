import {IMediaCover} from 'design-system/src/Components/Card/CarouselCard/CarouselCard';
import {IHeroVideoCoverFragmentFragment} from '~Api/Graphql/gql/types.generated';
import {traceApi} from '~Api/TraceApi/traceApi';
import {store} from '~Store/store';
import checkVimeoUrl from '~Umbraco/Utils/CheckVimeoUrl';

export const mapHeroVideoCover = (
  cover: IHeroVideoCoverFragmentFragment,
  isMobile?: boolean,
): IMediaCover | undefined => {
  if (cover.vimeoHlsUrl && checkVimeoUrl(cover.vimeoHlsUrl)) {
    return {
      type: 'video',
      uri: {uri: cover.vimeoHlsUrl},
      poster: cover.image ? {uri: cover.image.url || ''} : undefined,
    };
  } else if (cover.vimeoVideoId) {
    return {
      type: 'delay',
      poster: cover.image ? {uri: cover.image.url || ''} : undefined,
      request: async () => {
        const response = await store.dispatch(
          traceApi.endpoints.getVimeoVideo.initiate(cover.vimeoVideoId + ''),
        );
        if (response && response.data) {
          return {
            type: 'video',
            uri: {uri: response.data.hls},
            poster: cover.image ? {uri: cover.image.url || ''} : undefined,
          };
        }
        return {
          type: 'image',
          uri: cover.image ? {uri: cover.image.url || ''} : {uri: ''},
        };
      },
    };
  } else if (cover.image) {
    if (isMobile) {
      return {
        type: 'image',
        uri: {uri: cover.image.url},
        isMobile,
      };
    }
    const desktopImage = cover.desktopImage?.url;
    return {
      type: 'image',
      uri: {uri: desktopImage || cover.image?.url || ''},
      isMobile,
      hasDesktop: !!desktopImage,
    };
  }
};
