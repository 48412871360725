import React, {useEffect} from 'react';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import PhoneNumberPickerWeb from './PhoneNumberPicker.web';
import {PhoneNumberPickerProps} from './PhoneNumberPickerProps';

import {PhoneNumberUtil, PhoneNumberFormat} from 'google-libphonenumber';

let instance: PhoneNumberUtil;

const preloadGoogleLibPhoneNumber = async function () {
  if (!instance) {
    console.log('preloadGoogleLibPhoneNumber PhoneNumberInput');
    const libphoneNumber = await import('google-libphonenumber');
    instance = libphoneNumber.PhoneNumberUtil.getInstance();
    return instance;
  }
  return instance;
};

export type PhoneNumberProps = PhoneNumberPickerProps;
interface PhoneNumberPickerControllerProps<
  TField extends FieldValues = FieldValues,
> extends PhoneNumberPickerProps {
  name: Path<TField>;
  rules?: UseControllerProps['rules'];
  defaultValue?: PathValue<TField, Path<TField>>;
  control: Control<TField, any>;
  isWeb?: boolean;
}

const PhoneNumberPickerController = <TField extends FieldValues = FieldValues>(
  props: PhoneNumberPickerControllerProps<TField>,
) => {
  const {control, defaultValue, name, rules, isWeb, ...rest} = props;

  useEffect(() => {
    preloadGoogleLibPhoneNumber();
  }, []);

  const {field} = useController<TField>({
    control,
    defaultValue,
    name,
    rules: {
      // ...rules,
      validate: value => {
        if (!instance) {
          return false;
        }
        if (!rules?.required) {
          return true;
        }
        try {
          const parsedNumber = instance.parse(value);
          const isValid = instance.isValidNumber(parsedNumber);
          return isValid || 'Invalid phone number';
        } catch (e: any) {
          console.log('Error validating phone number:', e.message);
          return 'Invalid phone number';
        }
      },
    },
  });

  const handleChange = (text: string) => {
    // @ts-ignore
    field.onChange(text);
  };

  return (
    <PhoneNumberPickerWeb
      value={field.value}
      onChangeFormattedText={handleChange}
      {...rest}
    />
  );
};

export default PhoneNumberPickerController;
