import {View} from 'react-native';
import React from 'react';
import PaywallHomePage from './PaywallHomePage/PaywallHomePage';
import useAppNavigation from '@src/utils/useAppNavigation';
import {AppRoutesEnum} from '@src/Constant/routes';

interface IPaywallSectionPagerops {
  id?: string;
}

const PaywallSectionPage = (props: IPaywallSectionPagerops) => {
  const {id} = props;
  const navigation = useAppNavigation();
  return (
    <View style={{flex: 1}}>
      <PaywallHomePage
        id={id}
        onSelectPlan={() => navigation.navigate(AppRoutesEnum.CHOOSE_PLAN)}
      />
    </View>
  );
};

export default PaywallSectionPage;
