import React, {useEffect, useRef, useState, useCallback} from 'react';
import {View, StyleSheet} from 'react-native';
import PlayButton from '../../../Components/Player/PlayButton/PlayButton';
import Metrics from '../../../Theme/Metrics';
import {UnitPlayerStyles} from './UnitPlayer.style';

import {useVideoPlayer} from '../Hooks/useVideoPlayer.web';
import CustomFastImage from '../../../Components/CustomFastImage/CustomFastImage';
import LinearGradient from 'react-native-linear-gradient';
import CustomActivityIndicator from '../../../Components/CustomActivityIndicator/CustomActivityIndicator';
import PlayerSlider from '../VideoPlayer/PlayerSlider/PlayerSlider.web';
import {parseHlsResponse} from '../Hooks/useVideoPlayer';
import {useChannel} from '../Hooks/useChannel.web';
import {IVideoSource} from '../VideoPlayer/VideoPlayer';
import useBreakpoint from '../../../WebComponent/Grid/hooks/useBreakpoint';
import screenfull from 'screenfull';

export interface UnitPlayerProps {
  onPlay?: (isPaused: boolean) => void;
  poster: string;
  source?: IVideoSource;
  adTagUrl?: string;
  paused?: boolean;
  forcePause?: boolean;
  loading?: boolean;
  width?: number;
  height?: number;
  onVideoEnd?: () => void;
  messageError?: string;
  audioOnly?: boolean;
  aspectRatio?: number;
}

const UnitPlayer = (props: UnitPlayerProps) => {
  const {
    onPlay,
    poster,
    source,
    onVideoEnd,
    audioOnly,
    aspectRatio = 3 / 4,
  } = props;
  const {isMobile} = useBreakpoint();

  const [isMouseIdle, setIsMouseIdle] = useState(false);

  const timeLoadStart = useRef<number>();

  const hoverTimeoutRef = useRef<HTMLDivElement | null>(null);

  const {currentSource, isLoading} = useChannel<IVideoSource>(
    source ? [source] : [],
    0,
  );

  const videoContainerRef = useRef<any | null>(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const {
    videoRef,
    loaded,
    setLoaded,
    duration,
    playbackRate,
    playing,
    handlePlayPause,
    handleSliderChange,
    played,
    playedSeconds,
    resetPlayer,
    handleTimeUpdate,
    handleLoadedMetadata,
    isLoadedMetadata,
    setPlaying,
  } = useVideoPlayer({currentSource});

  const isHideControls = !playing ? false : isMouseIdle;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  useEffect(() => {
    const timeoutRef: any = hoverTimeoutRef.current;
    // Clear the timeout when the component unmounts
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, []);

  const handleProgress = () => {
    const video = videoRef.current;
    if (video && video.buffered.length > 0) {
      const end = video.buffered.end(video.buffered.length - 1);
      // onTimeProgress && onTimeProgress(end);
      const percentage = (end / video.duration) * 100;
      setLoaded(percentage);
    }
    // handleOnCustomProgress
  };

  const handleMouseActivity = () => {
    setIsMouseIdle(false);
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current as any);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setIsMouseIdle(true);
    }, 1000) as any; // Set idle status after 5 seconds of inactivity
  };

  const handleLoad = useCallback(
    (data: any) => {
      const timeStart = timeLoadStart.current || 0;
      const loadTime = Math.round((Date.now() - timeStart) / 1000);
      // onLoad(data);
      if (typeof source === 'object' && source.uri) {
        fetch(source.uri as string)
          .then(async response => {
            let value = await response.text();
            let dataParsed = parseHlsResponse(value);
            if (dataParsed[0]) {
              // onVideoLoad && onVideoLoad(data, dataParsed[0].height, loadTime);
            } else {
              // onVideoLoad && onVideoLoad(data, undefined, loadTime);
            }
          })
          .catch(error => {
            console.log('error loadedData', error);
          });
      } else {
        // onVideoLoad && onVideoLoad(data, undefined, loadTime);
      }
      // onGetDuration && onGetDuration(data.duration);
    },
    [source],
  );

  // const controlsAnimatedStyles = {
  //   opacity: isHideControls ? 0 : 1,
  //   transition: 'opacity 0.5s',
  // };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled && videoContainerRef.current) {
      screenfull.toggle(videoContainerRef.current);
      setIsFullscreen(!isFullscreen);
    }
  };

  return (
    <View
      ref={videoContainerRef}
      testID="unit-player"
      style={[
        UnitPlayerStyles.root,
        {
          position: 'relative',
          width: '100%',
          height: 'auto',
          aspectRatio,
          borderRadius: Metrics.horizontalScale(4),
          overflow: 'hidden',
        },
      ]}>
      {/* {loadStart && (
        <CustomFastImage
          source={{uri: poster}}
          style={UnitPlayerStyles.fastImageStyle}
          width={'100%'}
          height={'100%'}
        />
      )} */}
      {/* {progress.value > 0 && (
        <CustomBlurView
          blurType="dark"
          blurAmount={20}
          style={[StyleSheet.absoluteFill]}
        />
      )} */}
      <video
        ref={videoRef}
        poster={poster}
        // controls
        onTimeUpdate={handleTimeUpdate}
        onPlay={() => {
          // onVideoPlay && onVideoPlay(true);
          setPlaying(true);
        }}
        onPause={() => {
          setPlaying(false);
        }}
        onLoadedMetadata={handleLoadedMetadata}
        style={{
          width: '100%',
          height: '100%',
          aspectRatio,
          // borderRadius: borderRadius,
          // backgroundColor: darkTheme.colors.backgroundBlack,
        }}
        onProgress={handleProgress}
        onLoadStart={() => {
          //    handleOnVideoLoad,
          timeLoadStart.current = Date.now();
        }}
        onEnded={() => {
          onVideoEnd && onVideoEnd();
          // onEnd();
        }}
        onLoad={handleLoad}
        x-webkit-airplay="allow"
      />
      {audioOnly && (
        <CustomFastImage
          source={{
            uri: poster,
          }}
          style={UnitPlayerStyles.videoView}
          width={'100%'}
          height={'100%'}
        />
      )}
      <View
        style={[
          StyleSheet.absoluteFill,
          {
            opacity: isHideControls ? 0 : 1,
          },
        ]}>
        <LinearGradient
          style={[
            StyleSheet.absoluteFill,
            {
              height: '100%',
            },
          ]}
          locations={[0.45, 0.67, 0.85]}
          colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.7)', '#111216']}
        />
      </View>
      <div
        style={{
          opacity: isHideControls ? 0 : 1,
          transition: 'opacity 0.5s',
          position: 'absolute',
          left: 0,
          width: '100%',
        }}>
        <LinearGradient
          style={[
            StyleSheet.absoluteFill,
            {
              height: '100%',
            },
          ]}
          locations={[0.45, 0.67, 0.85]}
          colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.7)', '#111216']}
        />
      </div>

      {/* <Pressable style={UnitPlayerStyles.container} onPress={handlePlayPause}>
        {isLoading ? (
          <CustomActivityIndicator
            size="large"
            style={UnitPlayerStyles.indicatorStyle}
          />
        ) : (
          <Animated.View style={controlsAnimatedStyles}>
            <PlayButton isPlaying={playing} onPress={handlePlayPause} />
          </Animated.View>
        )}
      </Pressable> */}

      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}>
        <div
          // onClick={handlePlayPause}
          ref={hoverTimeoutRef}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseActivity}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: isMobile ? '0 16px' : `0`,
            position: 'relative',
            cursor: isMouseIdle ? 'none' : 'default',
          }}>
          {!isHideControls && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 0,
                  flex: 1,
                  alignItems: 'center',
                }}>
                {isLoadedMetadata ? (
                  <PlayButton
                    isPlaying={playing}
                    onPress={handlePlayPause}
                    // style={videoControlPlayerStyles.playButton}
                  />
                ) : (
                  <CustomActivityIndicator size="large" />
                )}
              </div>
              <PlayerSlider
                duration={duration}
                onSliderChange={handleSliderChange}
                onResetPlayer={resetPlayer}
                played={played}
                playedSeconds={playedSeconds}
                loaded={loaded}
                isVerticale={true}
                isWithNext={false}
                sourceList={[]}
                toggleFullscreen={toggleFullscreen}
              />
            </>
          )}
        </div>
      </div>
    </View>
  );
};

export default UnitPlayer;
