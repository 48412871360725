import React, {
  ReactNode,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {View, Animated} from 'react-native';
import Carousel, {ICarouselInstance} from 'react-native-reanimated-carousel';
import Metrics from '../../../Theme/Metrics';
import {CustomFastImageProps} from '../../CustomFastImage/CustomFastImageProps';
import Typography from '../../Typography/Typography';
import CustomHeader from '../CustomHeader/CustomHeader';
import {CustomHeaderProps} from '../CustomHeader/CustomHeader.props';
import {customHeaderSize} from '../CustomHeader/CustomHeader.styles';
import TitleCategorie from '../TitleCategorie/TitleCategorie';
import TitleLogo from '../TitleLogo/TitleLogo';
import {carouselHeaderStyles} from './CarouselHeader.styles';
import AutoScaleImage from '../../AutoScaleImage/AutoScaleImage';
import {IMediaCover} from '../../Card/CarouselCard/CarouselCard';

export interface CarouselData<T = any> {
  cover?: IMediaCover;
  image: CustomFastImageProps['source'];
  desktopImage: CustomFastImageProps['source'];
  title: string;
  categorie?: string[] | string;
  button?: CustomHeaderProps['buttonProps'];
  data?: T;
  description?: string;
  hideButton?: boolean;
  partner?: {
    label: string;
    logo: Array<string>;
  };
  videoCenter?: number;
}

export interface CarouselHeaderProps<T> {
  data: CarouselData<T>[];
  tabBarData?: {title: string; route?: string}[];
  buttonTitle?: string;
  onPressPlayButton?: (dataItem: CarouselData<T>) => void;
  onPressTabBarItem?: (dataItem: {title: string; route?: string}) => void;

  isLogo?: boolean;
  tabBarHeaderPosition?: Animated.AnimatedInterpolation;
  interval?: number;
  autoLoop?: boolean;

  description?: string;
  imageHeight?: number;
  renderItem?: (item: CarouselData<T>, isActive: boolean) => ReactNode;
  hidePagination?: boolean;
  size?: keyof typeof customHeaderSize;

  animatedScroll?: Animated.Value;
  isActive?: boolean;
}

const CarouselHeader = <T extends any>(props: CarouselHeaderProps<T>) => {
  const {
    data,
    buttonTitle,
    onPressPlayButton,
    isLogo,
    interval = 5000,
    autoLoop = true,
    imageHeight,
    hidePagination = false,
    renderItem,
    size = 'medium',
    animatedScroll,
    isActive,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollRef = useRef<ICarouselInstance>(null);
  const window = Metrics.window;

  const handleItemClick = useCallback(
    (item: CarouselData<T>) => () => {
      onPressPlayButton && onPressPlayButton(item);
    },
    [onPressPlayButton],
  );

  const [paused, setPaused] = useState<boolean>(false);

  const sizeStyles = customHeaderSize[size];
  const h = imageHeight || sizeStyles.minHeight;
  const heighInterpolation = animatedScroll
    ? animatedScroll.interpolate({
        inputRange: [0, h],
        outputRange: [h, 0],
        extrapolate: 'clamp',
      })
    : h;

  return (
    <Animated.View
      style={{
        height: heighInterpolation,
      }}>
      {/* {animationEnabled ? renderAnimatedHeader() : null} */}
      <Carousel<CarouselData<T>>
        data={data}
        ref={scrollRef}
        autoPlay={autoLoop}
        autoPlayInterval={interval}
        pagingEnabled={true}
        autoFillData={false}
        vertical={false}
        width={window.width}
        height={imageHeight || sizeStyles.minHeight}
        panGestureHandlerProps={{
          activeOffsetX: [-10, 10],
        }}
        // style={animationEnabled ? {display: 'none'} : undefined}
        defaultIndex={selectedIndex}
        onSnapToItem={setSelectedIndex}
        renderItem={({item, index}) => {
          const {
            image,
            title,
            categorie,
            button,
            description,
            partner,
            cover,
            videoCenter,
          } = item;
          return (
            <View key={index}>
              {renderItem ? (
                renderItem(item, selectedIndex === index)
              ) : (
                <CustomHeader
                  videoCenter={videoCenter}
                  cover={cover}
                  isActive={index === selectedIndex && isActive && !paused}
                  hideButton={item.hideButton}
                  contentBottom={56}
                  variant={'animated'}
                  animatedScroll={animatedScroll}
                  image={image}
                  title={title}
                  buttonTitle={buttonTitle}
                  buttonProps={button}
                  imageHeight={imageHeight || sizeStyles.minHeight}
                  onPressButton={handleItemClick(item)}>
                  {!!categorie && <TitleCategorie listCategorie={categorie} />}
                  {/* TODO: translation */}
                  {isLogo && <TitleLogo text="Avec" />}
                  {partner && (
                    <View
                      style={{
                        flexDirection:
                          partner.logo.length > 1 ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Typography
                        variant="ui3"
                        color="gray"
                        style={{
                          marginRight: Metrics.horizontalScale(10),
                          position: 'relative',
                        }}>
                        {partner.label}
                      </Typography>
                      <View
                        style={{
                          flexDirection: 'row',
                          maxWidth: Metrics.horizontalScale(308),
                          flexWrap: 'wrap',
                        }}>
                        {partner.logo.map((logo, index) => (
                          <AutoScaleImage
                            key={`prt-${index}`}
                            uri={logo}
                            initWidth={60}
                            initHeight={16}
                            backgroundFallbackColor="transparent"
                            resizeMode="contain"
                          />
                        ))}
                      </View>
                    </View>
                  )}
                  {description && (
                    <Typography
                      variant="body3"
                      style={{
                        maxWidth: Metrics.horizontalScale(279),
                        textAlign: 'center',
                      }}>
                      {description}
                    </Typography>
                  )}
                </CustomHeader>
              )}
            </View>
          );
        }}
      />

      {!hidePagination && (
        <View style={carouselHeaderStyles.circleDiv}>
          {data.map((_, index) => (
            <View
              key={index}
              style={[
                carouselHeaderStyles.whiteCircle,
                {opacity: index === selectedIndex ? 1 : 0.5},
              ]}
            />
          ))}
        </View>
      )}
    </Animated.View>
  );
};

export default React.memo(CarouselHeader) as typeof CarouselHeader;
