import {useAppDispatch} from '@src/Store/hooks';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {useCallback} from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {toast} from 'react-toastify';
import {
  useLazyGetPromocodeQuery,
  useLazyPostPromocodeQuery,
} from '~Api/TraceApi/traceApi';
import memberSlice from '~Store/Slices/Member.slice';

export const usePromoCode = () => {
  // const {t} = useTranslation(['subscription']);
  const i18n = useI18next();
  const [fetchPromocode, {isLoading: loadingPromocode}] =
    useLazyGetPromocodeQuery();
  const [postPromocode, {isLoading: postPromocodeLoading}] =
    useLazyPostPromocodeQuery();
  const dispatch = useAppDispatch();

  const handleGetPromocode = useCallback(async () => {
    const response = await fetchPromocode();
    if (response.error) {
      dispatch(memberSlice.actions.setMemberPromocode(undefined));
    } else if (response.data) {
      dispatch(memberSlice.actions.setMemberPromocode(response.data));
    }
  }, [fetchPromocode]);

  const handlePostPromocode = useCallback(
    async (code: string, onSuccess: Function) => {
      const response = await postPromocode({code});
      if (response.data) {
        dispatch(memberSlice.actions.setMemberPromocode(response.data));
        onSuccess();
      } else if (response.error) {
        const err = response.error as any;
        if (err && err.data && err.data.message) {
          toast.error(
            err.data.message || i18n.t('subscription:Promo code error'),
            {
              toastId: 'promo-code-error',
            },
          );
        } else {
          toast.error(i18n.t('subscription:Promo code error'), {
            toastId: 'promo-code-error',
          });
        }
      }
    },
    [postPromocode, dispatch],
  );

  return {
    handleGetPromocode,
    handlePostPromocode,
    postPromocodeLoading,
    loadingPromocode,
  };
};
