import React, {useState} from 'react';
import {graphql, PageProps} from 'gatsby';
import DefaultLayout from '@src/layout/DefaultLayout/DefaultLayout';
import {IFaqItemFragmentFragment} from '@src/Api/Graphql/gql/types.generated';
import ProfileHelpdeskTopic from '@src/Navigation/Profile/ProfileHelpdeskTopic/ProfileHelpdeskTopic';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import ProfileHelpdeskAnswer from '@src/Navigation/Profile/ProfileHelpdeskAnswer/ProfileHelpdeskAnswer';
import {Pressable, View} from 'react-native';
import Icon from 'design-system/src/Components/Icon/Icon';

const HelpdeskCategTemplate = (props: PageProps) => {
  const helpdeskCateg = (props.data as any).umbraco
    .helpdeskCateg as Queries.HelpdeskCategFragmentFragment;

  const {faqItems, title} = helpdeskCateg;

  const [step, setStep] = useState(0);
  const [faqSelected, setFaqSelected] =
    useState<IFaqItemFragmentFragment | null>(null);
  const {isMobile} = useBreakpoint();

  return (
    <View
      style={{
        paddingHorizontal: isMobile ? '16px' : '60px',
        position: 'relative',
        paddingTop: '90px',
      }}>
      {step !== 0 && (
        <Pressable
          style={{
            position: 'absolute',
            top: '80px',
            left: isMobile ? '16px' : '60px',
            zIndex: 99999,
          }}
          onPress={() => {
            setStep(step - 1);
          }}>
          <Icon name="retour" size={'24'} />
        </Pressable>
      )}
      {step === 0 && (
        <ProfileHelpdeskTopic
          faqs={faqItems?.map((item: any) => item?.content) ?? []}
          title={title ?? ''}
          onClickOnTopic={faq => {
            setFaqSelected(faq);
            setStep(step + 1);
          }}
        />
      )}
      {step === 1 && faqSelected && <ProfileHelpdeskAnswer {...faqSelected} />}
    </View>
  );
};

HelpdeskCategTemplate.isModal = true;

export default HelpdeskCategTemplate;

export const query = graphql`
  query ($language: String!, $id: ID!, $lang: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    umbraco {
      helpdeskCateg(id: $id, culture: $lang) {
        ...HelpdeskCategFragment
      }
    }
  }
`;
