import {useEffect, useMemo, useRef, useCallback, useState} from 'react';
import {IVideoSource} from 'design-system/src/Widget/Player/VideoPlayer/VideoPlayer';
import {
  useLazyGetVimeoVideoQuery,
  useLazyChannelProgramQuery,
  useLazyGetVimeoEventQuery,
} from '~Api/TraceApi/traceApi';
import useTracker, {
  IPlayerPropertyName,
} from '~Services/Amplitude/hooks/useTracker';
import {
  IChannelFragmentFragment,
  ILiveEventFragmentFragment,
  IPaywallFragmentFragment,
  IVodItemFragmentFragment,
  useCollectionByIdLazyQuery,
  useGetContentByIdQuery,
} from '~Api/Graphql/gql/types.generated';
import {ITvGuideItem} from '~Models/TvModel';
import dayjs from 'dayjs';
import {useFindProgression} from '~Hooks/useFindProgression';
import usePaywal from '~Hooks/usePaywal';
import checkVimeoUrl from '~Umbraco/Utils/CheckVimeoUrl';
import {
  IAccessTypParam,
  IPaywallParam,
} from '~Umbraco/Components/PaywallChecker/PaywallChecker';
import useAppNavigation from '@src/utils/useAppNavigation';
import useMember from '@src/Store/selectors/useMember';
import {useRegions} from '@src/Store/selectors/useRegions';
import {useAppSelector} from '@src/Store/hooks';
import {useUpdateProgression} from '@src/Hooks/useUpdateProgression';
import {getCurrentProgram} from '../homeWatch/LiveTvScreen/LiveTvList/LiveTvItem/LiveTvItem';
import {useVideoPremiumPreview} from './useVideoPremiumPreview';
import useEvent from '@src/Hooks/useEvent';

export type IVideoSourceItem = IVideoSource & {
  accessData?: {
    accessType?: IAccessTypParam;
    paywall?: IPaywallParam;
  };
};

export interface IVideoSourceData {
  poster: string;
  title: string;
  sourceList: Array<IVideoSourceItem>;
}

export type VideoPlayerParams = {
  id: string;
  type: 'player';
  liveEventId: string | number;
  title: string;
  url: string;
  videoUrl?: string;
  videoId?: string;
  isLive?: boolean;
  time?: number;
  source: 'livestream' | 'vimeo' | 'local';
  accessType?: IAccessTypParam;
  paywall?: IPaywallParam;
  paywallData?: {
    accessType?: IAccessTypParam;
    paywall?: IPaywallParam;
  };
  list?: {
    id: string;
  };
  previousScreen?: string;
};
const useVideoPlayerLogic = (params: VideoPlayerParams) => {
  const {
    id,
    liveEventId,
    videoUrl,
    title,
    time,
    source = 'vimeo',
    list,
    previousScreen,
    paywallData,
  } = params || {};
  const isLive = source === 'livestream';
  const progressLiveRef = useRef<number>(0);
  const navigation = useAppNavigation();
  const skipNumber = useRef<number>(0);
  const backwardsNumber = useRef<number>(0);
  const member = useMember();
  const {findRegionsByDocumentUrl} = useRegions();
  const collectionId = list?.id;
  const [fetchCollection, {data: collectionData, loading}] =
    useCollectionByIdLazyQuery();
  const {canSeeContent, getPremiumPreviewSettings, defaultPaywall} =
    usePaywal();

  const [currentId, setCurrentId] = useState(id);
  const [originalInfo, setOriginalInfo] = useState<
    | {
        originalLanguage?: string;
        originalQuality?: string;
        contentTimestamp: Date;
        loadTime?: number;
      }
    | undefined
  >();

  const response = useGetContentByIdQuery({
    variables: {
      id: currentId,
    },
    skip: !currentId,
    errorPolicy: 'all',
  });

  const [fetchVimeo, {data: vimeoData, isLoading: isLoadingVimeo}] =
    useLazyGetVimeoVideoQuery();
  const [
    fetchVimeoEvent,
    {data: vimeoEventData, isLoading: isLoadingVimeoEvent},
  ] = useLazyGetVimeoEventQuery();
  const track = useTracker();
  const dataGuide = useAppSelector(state => state.channel.guide);
  const currentProgramRef = useRef<
    | (ITvGuideItem & {
        start: Date;
        stop: Date;
      })
    | undefined
  >();

  const data = useAppSelector(state => state.channel.guide[id] || []);
  const [fetchXmlFile] = useLazyChannelProgramQuery();

  const durationRef = useRef<number>();
  const startDateRef = useRef<Date>();
  // useAutoPauseListenPlayer();
  // const {localMedias, loading: dbLoading} = useLocalDb();

  const isLoading: boolean = isLoadingVimeoEvent || isLoadingVimeo || loading;

  const paywallSetting = useMemo(() => {
    return getPremiumPreviewSettings(
      paywallData?.paywall as IPaywallFragmentFragment,
    );
  }, [getPremiumPreviewSettings, paywallData]);

  const pauseCounter = useRef<number>(1);
  const {handleProgression} = useUpdateProgression();

  const {findCurrentTime} = useFindProgression();
  const [fetchVimeoVideo] = useLazyGetVimeoVideoQuery();

  const initialTime = useMemo(() => {
    return time || findCurrentTime(id);
  }, [time, findCurrentTime, id]);

  const [currentTime, setCurrentTime] = useState(initialTime);
  const [hideVideo, setHideVideo] = useState(false);

  const [paused, setPaused] = useState(true);
  const refCurrentDuration = useRef<number>(0);

  const refCurrentChannel = useRef(0);
  const contentRef = useRef<
    | IVodItemFragmentFragment
    | ILiveEventFragmentFragment
    | IChannelFragmentFragment
  >();

  // Effect

  useEffect(() => {
    if (currentTime !== initialTime) {
      setCurrentTime(initialTime || 0);
    }
  }, [initialTime]);

  const getContentProperties = useCallback((): IPlayerPropertyName => {
    if (contentRef.current) {
      const restrictedCountries =
        (contentRef.current as any).countriesRestriction &&
        Array.isArray((contentRef.current as any).countriesRestriction)
          ? (contentRef.current as any).countriesRestriction.map((v: any) => {
              const [country] = (v || '').split('|');
              return country ? country.trim() : '';
            })
          : '';
      const contentRegion: string =
        (member &&
          member.region &&
          findRegionsByDocumentUrl(member.region)?.name) ||
        '';
      if (contentRef.current.__typename === 'VODItem') {
        const {parent, interests, seasonNumber, musicGenre} =
          contentRef.current;
        const isEpisode = parent && parent.__typename === 'Collection';
        return {
          id: contentRef.current.id,
          title: contentRef.current.title || '',
          typename: contentRef.current.__typename,
          length: contentRef.current.length || '',
          category:
            contentRef.current.parent?.__typename === 'WatchCateg'
              ? contentRef.current.parent.name || ''
              : '',
          contentRegion: contentRegion,
          collectionTitle:
            parent && parent.__typename === 'Collection' ? parent.name : '',
          seasonTitle: isEpisode ? (seasonNumber || 1) + '' : '',
          episodeTitle: isEpisode ? contentRef.current.title : '',
          contentTimestamp: originalInfo?.contentTimestamp,
          interestName:
            interests &&
            interests[0] &&
            interests[0].__typename === 'ContentTag'
              ? interests[0].name
              : '',
          musicGenre: musicGenre ? musicGenre.map(e => e?.name).join(',') : '',
          originalLanguage: originalInfo?.originalLanguage,
          originalQuality: originalInfo?.originalQuality,
          watched: Math.round(
            refCurrentDuration.current
              ? (currentTime / refCurrentDuration.current) * 100
              : 0,
          ),
          duration: currentTime,
          source: previousScreen,
          loadTime: originalInfo?.loadTime || 0,
          restrictedCountries,
        };
      } else if (contentRef.current.__typename === 'LiveEvent') {
        let start = new Date(contentRef.current.startDate);
        let stop = new Date(contentRef.current.endDate);
        const duration = dayjs(stop).diff(start, 'minutes');
        const {regions, interests, musicGenre} = contentRef.current;
        return {
          id: contentRef.current.id,
          title: contentRef.current.title || '',
          typename: contentRef.current.__typename,
          length: duration,
          category: 'liveEvent',
          eventTime: contentRef.current.startDate,
          contentRegion: contentRegion,
          interestName:
            interests &&
            interests[0] &&
            interests[0].__typename === 'ContentTag'
              ? interests[0].name
              : '',
          musicGenre: musicGenre ? musicGenre.map(e => e?.name).join(',') : '',
          contentTimestamp: originalInfo?.contentTimestamp,
          originalLanguage: originalInfo?.originalLanguage,
          originalQuality: originalInfo?.originalQuality,
          source: previousScreen,
          loadTime: originalInfo?.loadTime || 0,
          restrictedCountries,
        };
      } else if (contentRef.current.__typename === 'Channel') {
        const {regions, interests, musicGenre} = contentRef.current;
        return {
          id: contentRef.current.id,
          title: contentRef.current.title || '',
          typename: contentRef.current.__typename,
          length: currentProgramRef.current?.duration || '',
          category: 'channel',
          contentRegion: contentRegion,
          interestName:
            interests &&
            interests[0] &&
            interests[0].__typename === 'ContentTag'
              ? interests[0].name
              : '',
          musicGenre: musicGenre ? musicGenre.map(e => e?.name).join(',') : '',
          originalLanguage: originalInfo?.originalLanguage,
          originalQuality: originalInfo?.originalQuality,
          contentTimestamp: originalInfo?.contentTimestamp,
          source: previousScreen,
          loadTime: originalInfo?.loadTime || 0,
          restrictedCountries,
        };
      }
    }
    return {
      id: '',
      title: '',
      typename: '',
      musicGenre: '',
      length: '',
      category: '',
      loadTime: 0,
      contentRegion: '',
    };
  }, [
    findRegionsByDocumentUrl,
    member,
    originalInfo?.contentTimestamp,
    originalInfo?.loadTime,
    originalInfo?.originalLanguage,
    originalInfo?.originalQuality,
    previousScreen,
  ]);

  const resetCurrentTimeState = useCallback(() => {
    refCurrentDuration.current = 0;
    setCurrentTime(0);
  }, []);

  useEffect(() => {
    if (
      response.data &&
      response.data.content &&
      response.data.content.__typename === 'Channel'
    ) {
      const {epg, id: channelID} = response.data.content;
      if (epg) {
        fetchXmlFile({channelId: channelID, url: epg}, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (
      response.data &&
      response.data.content &&
      response.data.content.__typename === 'Channel' &&
      data
    ) {
      const currentProgam = getCurrentProgram(data);

      if (currentProgam && currentProgam.start && currentProgam.stop) {
        const {start, stop} = currentProgam;
        startDateRef.current = start;
        const duration = dayjs(stop).diff(start, 'minutes');
        durationRef.current = duration;
      }
    }
  }, [data, response.data]);

  useEffect(() => {
    if (collectionId) {
      fetchCollection({
        variables: {
          id: collectionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  useEffect(() => {
    if (
      !response.loading &&
      response.data &&
      response.data.content &&
      originalInfo
    ) {
      let content = response.data.content;
      if (content.__typename === 'VODItem') {
        contentRef.current = content as IVodItemFragmentFragment;
        track.player.started(getContentProperties());
      } else if (content.__typename === 'LiveEvent') {
        contentRef.current = content as ILiveEventFragmentFragment;
        track.player.started(getContentProperties());
      } else if (content.__typename === 'Channel') {
        contentRef.current = content as IChannelFragmentFragment;
        let tvGuide = dataGuide[content.id] || [];
        currentProgramRef.current = getCurrentProgram(tvGuide);
        track.player.started(getContentProperties());
      }

      track.player.loadTime({
        ...getContentProperties(),
        duration: originalInfo.loadTime,
      });
    }
  }, [dataGuide, response, originalInfo]);

  useEffect(() => {
    if (source === 'livestream' && liveEventId) {
      fetchVimeoEvent(liveEventId + '');
    }
  }, [source, liveEventId]);

  useEffect(() => {
    if (source === 'vimeo') {
      if (!videoUrl || !checkVimeoUrl(videoUrl) || collectionId) {
        fetchVimeo(liveEventId + '');
      }
    }
  }, [collectionId, fetchVimeo, liveEventId, source, videoUrl]);

  useEffect(() => {
    if (hideVideo) {
      setHideVideo(false);
    }
    // if (!canSee) {
    //   navigation.setOptions({
    //     headerShown: true,
    //   });
    // }
    // enable landspace
    // setTimeout(() => {
    //   if (canSee) {
    //     Orientation.lockToLandscape();
    //   } else {
    //     Orientation.lockToPortrait();
    //   }
    // }, 100);
    // const removeSubscription = navigation.addListener('beforeRemove', () => {
    //   setHideVideo(true);
    //   Orientation.lockToPortrait();
    // });
    return () => {
      // removeSubscription();
      // Orientation.lockToPortrait();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (contentRef.current) {
        if (contentRef.current.__typename === 'VODItem') {
          track.player.ended(getContentProperties());
        } else if (contentRef.current.__typename === 'LiveEvent') {
          track.player.stopped(getContentProperties());
        } else if (contentRef.current.__typename === 'Channel') {
          track.player.stopped(getContentProperties());
        }
      }

      if (currentId) {
        if (refCurrentDuration.current > 0) {
          handleProgression(
            currentId,
            currentTime,
            'watch',
            'VODItem',
            refCurrentDuration.current,
          );
        }
      }

      if (!paused) {
        setPaused(true);
      }
    };
  }, [currentId]);

  const liveEvent = useMemo<IVideoSourceData | undefined>(() => {
    if (source === 'livestream' && !isLoadingVimeoEvent) {
      if (vimeoEventData?.m3u8_playback_url) {
        const liveEventUrl = vimeoEventData.m3u8_playback_url;

        return {
          // poster: liveEventData.live.thumbnailUrl || '',
          poster: '',
          title,
          sourceList: [
            {
              uri: liveEventUrl,
              description: title,
            },
          ],
        };
      }
    } else if (source === 'vimeo') {
      if (
        collectionId &&
        collectionData &&
        collectionData.collection &&
        collectionData.collection.children &&
        collectionData.collection.children.items
      ) {
        const _list: Array<IVideoSourceItem> = [];
        collectionData.collection.children.items.forEach(vodItem => {
          if (
            vodItem &&
            vodItem.__typename === 'VODItem' &&
            vodItem.liveEventID
          ) {
            // if (id === vodItem.id) {
            //   refCurrentChannel.current = _list.length;
            // }
            // let media = localMedias.find(
            //   element => element.id === vodItem.id,
            // );
            let uri: string | (() => Promise<string>);
            // if (media) {
            //   uri =
            //     Platform.OS === 'android'
            //       ? 'file://' + media.mediaPath
            //       : '' + media.mediaPath;
            // } else {
            // uri =
            //   !!vodItem.vimeoHlsUrl && checkVimeoUrl(vodItem.vimeoHlsUrl)
            //     ? vodItem.vimeoHlsUrl || ''
            //     : async () => {
            //         console.log('fetch-vimeo');
            //         const res = await fetchVimeoVideo(
            //           vodItem.liveEventID + '',
            //         );
            //         if (res && res.data && res.data.hls) {
            //           return res.data.hls;
            //         }
            //         return '';
            //       };
            // }
            _list.push({
              uri:
                !!vodItem.vimeoHlsUrl && checkVimeoUrl(vodItem.vimeoHlsUrl)
                  ? vodItem.vimeoHlsUrl || ''
                  : async () => {
                      const res = await fetchVimeoVideo(
                        vodItem.liveEventID + '',
                      );
                      if (res && res.data && res.data.hls) {
                        return res.data.hls;
                      }
                      return '';
                    },
              description: vodItem.title || '',
              id: vodItem.id,
              accessData: {
                accessType: vodItem.accessType,
                paywall: vodItem.paywall,
              },
              vimeoId: `${vodItem.liveEventID}` || '',
            });
          }
        });
        return {
          poster: vimeoData?.picture || '',
          title,
          sourceList: _list,
        };
      } else {
        if (videoUrl && checkVimeoUrl(videoUrl)) {
          return {
            poster: vimeoData?.picture || '',
            title,
            sourceList: [
              {
                uri: videoUrl,
                description: title,
                vimeoId: vimeoData?.id || '',
              },
            ],
          };
        } else if (vimeoData) {
          return {
            poster: vimeoData.picture,
            title,
            sourceList: [
              {
                uri: vimeoData.hls,
                description: title,
                vimeoId: vimeoData?.id || '',
                accessData: {
                  accessType: paywallData?.accessType,
                  paywall: paywallData?.paywall,
                },
              },
            ],
          };
        }
      }
    }
  }, [
    isLoadingVimeoEvent,
    source,
    vimeoEventData,
    title,
    videoUrl,
    collectionId,
    vimeoData,
    collectionData,
    id,
  ]);

  const currentIndex = useMemo(() => {
    const _index = refCurrentChannel.current || 0;
    if (liveEvent?.sourceList) {
      const currentItem = liveEvent?.sourceList[_index];

      if (currentItem && currentItem.id) {
        return _index;
      } else {
        return liveEvent.sourceList.findIndex(element => element.id === id);
      }
    }
    return 0;
  }, [liveEvent?.sourceList, id, refCurrentChannel.current]);

  const currentSource = liveEvent?.sourceList?.[currentIndex];

  const accessType =
    (currentSource && currentSource.accessData?.accessType) ||
    paywallData?.accessType;

  const canSee: boolean = accessType ? canSeeContent(accessType) : true;
  const canPreview = getPremiumPreviewSettings(
    paywallData?.paywall as IPaywallFragmentFragment,
  )?.canPreview;

  const {
    handleFetchPreview,
    handlePreviewProgress,
    displayPaywall,
    setDisplayPaywall,
    previewLoading,
  } = useVideoPremiumPreview({
    currentId,
    isLive,
    paywallSetting,
    canSee,
    canPreview,
    setCurrentTime,
    payWallId: (
      (paywallData?.paywall as IPaywallFragmentFragment) || defaultPaywall
    )?.id,
    progressLiveRef,
    liveEvent,
  });

  useEffect(() => {
    handleFetchPreview();
  }, [canSee, canPreview]);

  const timerUpdater = useEvent(() => {
    if (refCurrentDuration.current > 0 && !paused) {
      handleProgression(
        currentId,
        currentTime,
        'watch',
        'VODItem',
        refCurrentDuration.current,
      );
    }
    if (!paused) {
      handlePreviewProgress(currentTime);
    }
  });

  useEffect(() => {
    let intervalTimer: string | number | NodeJS.Timeout | undefined;
    if (currentId) {
      // Set an interval to call handleProgression every
      intervalTimer = setInterval(timerUpdater, 15000); // 15 seconds
    }

    return () => {
      if (intervalTimer) {
        clearInterval(intervalTimer);
      }
    };
  }, [currentId, timerUpdater]);

  // useEffect(() => {
  //   const findItem = liveEvent?.sourceList?.find(e => e.id === currentId);
  //   canSeeRef.current = findItem
  //     ? canSeeContent(findItem?.accessData?.accessType)
  //     : undefined;
  // }, [currentId, liveEvent]);

  const onNextEpisode = useEvent((index: number) => {
    if (liveEvent) {
      const currentIndex = liveEvent.sourceList.findIndex(
        elemet => elemet.id === currentId,
      );
      if (currentIndex >= 0 && liveEvent.sourceList[currentIndex].id) {
        handleProgression(
          liveEvent.sourceList[currentIndex].id as string,
          currentTime,
          'watch',
          'VODItem',
          refCurrentDuration.current,
        );
        handlePreviewProgress(currentTime);
        resetCurrentTimeState();
      }
      if (liveEvent.sourceList[index] && liveEvent.sourceList[index].id) {
        refCurrentChannel.current = index;
        let _canSee: boolean = true;
        const accessData = liveEvent.sourceList[index].accessData;
        if (accessData) {
          _canSee = canSeeContent(accessData.accessType);
        }
        if (_canSee) {
          let videoTime = findCurrentTime(
            liveEvent.sourceList[index].id as string,
          );
          setCurrentTime(videoTime);
          setOriginalInfo(undefined);
          setCurrentTime(videoTime);
          setCurrentId(liveEvent.sourceList[index].id as string);
        } else {
          // navigation.navigate(AppRoutesEnum.PAYWALL, {
          //   state: {
          //     params: {
          //       id: 'default',
          //       headerTitle: liveEvent.sourceList[index].description || '',
          //       // @ts-ignore
          //       paywall: accessData?.paywall?.id || 'default',
          //     },
          //   },
          // });
        }
      }
    }
  });

  // Handler
  const handleOnGetDuration = useEvent((duration: number) => {
    if (currentId) {
      refCurrentDuration.current = duration;
    }
  });

  const handleOnVideoLoad = useEvent(
    ({
      loadTime,
      originalQuality,
      textTrack,
    }: {
      textTrack?: string;
      originalQuality?: number | string;
      loadTime?: number;
    }) => {
      setPaused(false);
      setOriginalInfo({
        originalLanguage: textTrack || '',
        originalQuality: originalQuality ? originalQuality + '' : '',
        contentTimestamp: new Date(),
        loadTime,
      });
    },
  );

  const handleOnCustomProgress = useEvent(() => {
    return dayjs().diff(startDateRef.current, 'minutes');
  });

  const handleOnBack = useCallback(() => navigation.goBack(), [navigation]);

  const handleOnPlayEnd = useEvent(() => {
    if (contentRef.current) {
      if (contentRef.current.__typename === 'VODItem') {
        track.player.ended({
          ...getContentProperties(),
        });
      }
    }
    if (currentId) {
      handleProgression(
        currentId,
        currentTime,
        'watch',
        'VODItem',
        refCurrentDuration.current,
      );
      resetCurrentTimeState();
    }
  });

  const handleOnLanguageChange = useEvent((newLng: string) => {
    if (contentRef.current) {
      if (contentRef.current.__typename === 'VODItem') {
        track.player.changeLanguage({
          ...getContentProperties(),
          newLanguage: newLng,
        });
      }
    }
  });

  const handleOnResolutionChange = useEvent((newResolution: string) => {
    if (contentRef.current) {
      if (contentRef.current.__typename === 'VODItem') {
        track.player.changeResolution({
          ...getContentProperties(),
          newResolution: newResolution + '',
        });
      }
    }
  });

  const handleOnSpeedChange = useEvent((isAccelerated: boolean) => {
    if (contentRef.current) {
      if (contentRef.current.__typename === 'VODItem') {
        if (isAccelerated) {
          track.player.accelerated(getContentProperties());
        } else {
          track.player.slowed(getContentProperties());
        }
      }
    }
  });

  const handleOnSkipChange = useEvent((isSkip: boolean) => {
    if (contentRef.current) {
      if (contentRef.current.__typename === 'VODItem') {
        let properties: IPlayerPropertyName = {
          ...getContentProperties(),
          eventTime: currentTime + '',
        };
        if (isSkip) {
          const _skipNumber = (skipNumber.current || 0) + 1;
          skipNumber.current = _skipNumber;
          track.player.skipe({...properties, skipNumber: _skipNumber});
        } else {
          const _backwardsNumber = (backwardsNumber.current || 0) + 1;
          backwardsNumber.current = _backwardsNumber;
          track.player.rewind({
            ...properties,
            backwardsNumber: _backwardsNumber,
          });
        }
      }
    }
  });

  const handleOnVideoPlay = useEvent((isPaused: boolean) => {
    setPaused(isPaused);
    if (isPaused) {
      track.player.paused({
        ...getContentProperties(),
        pauseNumber: pauseCounter.current,
      });
      pauseCounter.current++;
    }
  });

  const handleOnTimeProgress = useEvent((_currentTime: number) => {
    setCurrentTime(_currentTime);
    if (!canSee && canPreview) {
      const {previewDurationTv, previewDurationVod} = paywallSetting;
      if (isLive && !progressLiveRef.current) {
        progressLiveRef.current = Date.now();
      }
      const _displayPaywall =
        (isLive &&
          (Date.now() - progressLiveRef.current) / 1000 >= previewDurationTv) ||
        (!isLive && currentTime >= previewDurationVod);
      if (isLive && progressLiveRef.current && !_displayPaywall) {
        setCurrentTime((Date.now() - progressLiveRef.current) / 1000);
      }

      if (_displayPaywall) {
        setDisplayPaywall(_displayPaywall);
        handlePreviewProgress(isLive ? previewDurationTv : previewDurationVod);
      }
    }
  });

  return {
    isLoading,
    isLive,
    source,
    paused: displayPaywall ? true : paused,
    displayPaywall,
    paywallSetting,
    liveEvent,
    currentProgramRef,
    refCurrentChannel,
    durationRef,
    currentTime,
    hideVideo,
    content: contentRef.current,
    previewLoading,
    handleOnGetDuration, // set duration
    handleOnVideoLoad,
    handleOnCustomProgress,
    handleOnBack,
    handleOnPlayEnd,
    handleOnVideoPlay, // call play pause
    handleOnTimeProgress,
    onNextEpisode,
    handleOnLanguageChange,
    handleOnResolutionChange,
    handleOnSpeedChange,
    handleOnSkipChange,
  };
};

export default useVideoPlayerLogic;
