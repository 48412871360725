import React, {useEffect, useMemo} from 'react';
import {
  useContentUrlByIdLazyQuery,
  useFormPageByIdQuery,
} from '@src/Api/Graphql/gql/types.generated';
import FormPage from '@src/Umbraco/Pages/FormPage/FormPage';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Form} from '@src/Api/Umbraco/interface/UmbracoTypes';
import ContentEmpty from 'design-system/src/Components/ContentEmpty/ContentEmpty';
import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import {RouteFactory} from '@src/Umbraco/Utils/RouteFactory';
import useParams from '@src/Hooks/useParams';
import useAppNavigation from '@src/utils/useAppNavigation';
import useContentIdByEndUrl from '@src/Umbraco/Hooks/useContentIdByEndUrl';

const AppFormPage = () => {
  const params = useParams();
  const endUrl = params.endUrl;
  const navigation = useAppNavigation();
  const dispatch = useDispatch();
  const {t} = useTranslation(['service']);
  const {id, loading: loadingContentId} = useContentIdByEndUrl({
    variables: {
      endUrl: endUrl,
      contentTypeAlias: 'formPage',
    },
  });

  const [fetchContent, {data: contentData}] = useContentUrlByIdLazyQuery();

  const {data, loading} = useFormPageByIdQuery({
    variables: {
      id: id,
    },
    errorPolicy: 'all',
    skip: !id,
  });
  const formPageData:
    | undefined
    | {
        form: Form;
        cover?: string;
        coverDesktop?: string;
        gotoPageOnSubmit?: string;
        title?: string;
        subtitle?: string;
      } = useMemo(() => {
    const formPage = data?.formPage;
    if (formPage) {
      const {form: _form, cover: _cover} = formPage;
      return {
        form: _form,
        cover:
          _cover &&
          _cover[0] &&
          _cover[0]?.content.__typename === 'HeroVideoCover'
            ? _cover[0]?.content.image?.url
            : undefined,
        coverDesktop:
          _cover &&
          _cover[0] &&
          _cover[0]?.content.__typename === 'HeroVideoCover'
            ? _cover[0]?.content.desktopImage?.url
            : undefined,
        gotoPageOnSubmit: (_form as Form)?.gotoPageOnSubmit,
        title: formPage.title,
        subtitle: formPage.subtitle,
      };
    }
  }, [data]);

  useEffect(() => {
    if (formPageData && formPageData.gotoPageOnSubmit) {
      fetchContent({
        variables: {
          id: formPageData.gotoPageOnSubmit,
        },
      });
    }
  }, [formPageData]);

  const handleSuccess = () => {
    if (contentData && contentData.content) {
      const {id: _id, url, __typename} = contentData.content;
      new RouteFactory(
        {
          id: _id,
          url: url || '',
          __typename,
        },
        navigation,
        dispatch,
      ).navigate();
    } else {
      navigation.goBack();
    }
  };

  if (loading || loadingContentId) {
    return (
      <CustomActivityIndicator
        style={{
          flex: 1,
        }}
      />
    );
  }

  if (!formPageData || !formPageData.form) {
    return <ContentEmpty title={t('Form not found')} />;
  }
  const {form, cover, coverDesktop, title, subtitle} = formPageData;
  return (
    <div>
      {form && (
        <FormPage
          form={form}
          cover={cover}
          coverDesktop={coverDesktop}
          title={title}
          subtitle={subtitle}
          onClose={() => navigation.goBack()}
          onSuccess={handleSuccess}
        />
      )}
    </div>
  );
};

export default AppFormPage;
